import { useLocation } from "react-router-dom";

import { NavSectionVertical } from "src/components/nav-section/vertical/NavSectionVertical";
import { Route } from "src/utils/routes";

import NavItem from "./NavItem";

export default function NavList({ item }: { item: Route }) {
  const { pathname } = useLocation();
  const { path, children } = item;

  return (
    <>
      <NavItem item={item} active={pathname === path} />

      {!!children && <NavSectionVertical data={children} />}
    </>
  );
}
