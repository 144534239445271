import Divider from "@mui/material/Divider";

import TestimonialElearning from "./reviews/TestimonialElearning";
import TrustBox from "./Trustbox";

const ReviewsSPC = () => {
  return (
    <>
      <TestimonialElearning testimonials={testimonials} />
      <Divider sx={{ mt: 4 }} style={{ borderColor: "transparent" }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TrustBox />
      </div>
      <Divider sx={{ mt: 2 }} style={{ borderColor: "transparent" }} />
    </>
  );
};

export default ReviewsSPC;

const testimonials = [
  {
    id: 1,
    name: "Charmarke",
    rating: 5,
    reviewTitle: "Best Pre-Reg Course Bar None",
    review:
      "Marvin & Umar have crafted an incredible resource for foundation trainees which I found indispensable during my pre-reg year.",
    link: "https://uk.trustpilot.com/reviews/677583bda617b9d404d90a49",
  },
  {
    id: 2,
    name: "Gulsum Meydan",
    rating: 5,
    reviewTitle: "Best support network for your training year, no other course can compare",
    review:
      "If there is one thing you invest in during your training year, let it be the combo course. I PASSED and did much better than I was expecting! I'm out of words to express my gratitude to Marvin, Umar and Georgina, they've gone above and beyond to support me this year and I never felt alone. The help from the Telegram group was unmatched and I've met some of the loveliest people. It's a surreal feeling opening your results to see you have passed, invest in this course to feel this too. I am so proud to call myself a Pharmacist.",
    link: "https://uk.trustpilot.com/reviews/64c376c451a3537d96e21e68",
  },
  {
    id: 3,
    name: "Niayesh Shirmohammdi",
    rating: 5,
    reviewTitle: "Best course ever",
    review:
      "Dear Marvin, Umar, and Georgina, I want to express my heartfelt gratitude to each of you. Your course has been instrumental in helping me successfully pass my exam with flying colors. It's incredible to believe that by solely relying on your comprehensive notes, I managed to achieve an outstanding score of above 80%. Your dedication to teaching and the quality of your materials are truly commendable. I couldn't have asked for better instructors and resources to guide me through this challenging journey. Once again, thank you from the bottom of my heart. Your impact on my academic success is immeasurable, and I will forever be grateful for your support.",
    link: "https://uk.trustpilot.com/reviews/64c37111ae7539122ebbdaa9",
  },
  {
    id: 4,
    name: "Osariemen Egharevba-Buckman",
    rating: 5,
    reviewTitle: "An amazing experience",
    review:
      "Pre-reg shortcuts was integral to my journey from graduating to qualification. The in-depth learning provided is amazing and the support provided is exceptional. Absolutely 10/10, would recommend for a smooth journey through your foundation training",
    link: "https://uk.trustpilot.com/reviews/64c38b3900a1d038b699549c",
  },
  {
    id: 5,
    name: "Wiedur Pandey",
    rating: 5,
    reviewTitle: "The Best Shortcut",
    review:
      "Honestly speaking, for me this course was a blessing as I was so lost in the begining of my trainee year. These guys know how to prepare trainees to pass GPhC exam, hence the name 'shortcut'. I dont recommend this but I studied only for 3 weeks with pre-reg shortcut notes and I smashed the exam first time. Also, the family culture and support is tremendous. Past papers, answers, motivation, you name it everything is available in their telegram group. I definitely recommend this course.",
    link: "https://uk.trustpilot.com/reviews/62e40e594c35e69ec51daf4a",
  },
  {
    id: 6,
    name: "Aisha Shakreen",
    rating: 5,
    reviewTitle: "THANK YOUU!!",
    review:
      "I cant thank Umar and Marvin enough as i passed my exam first time! I am so happy Alhamdulillah. All because of the support group, lectures and notes! Joined PreReg Shortcuts literally few weeks before exam after panicking so much regarding revision. It was the best decision as it gave a structure to my revision and helped me. So much resources/mind maps etc were shared that helps you understand complicated topics easily and remember them. I would always recommend this course to all pre regs so all can benefit!",
    link: "https://uk.trustpilot.com/reviews/60a38309f9f4870a94a58915",
  },
];
