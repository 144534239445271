import { RHFTextField } from "src/components/hook-form/RHFTextField";

interface EmailFieldProps {
  hasFocus: boolean;
  tabIndex: number;
  isDisabled?: boolean;
}

const EmailField = ({ hasFocus, tabIndex, isDisabled = false }: EmailFieldProps) => {
  return (
    <RHFTextField hasFocus={hasFocus} name="email" label="Email address" tabIndex={tabIndex} isDisabled={isDisabled} />
  );
};

export default EmailField;
