import React, { useContext, useEffect, useState } from "react";

import { errorToast } from "src/components_with_stories/toast";
import { AppContext } from "src/context/AppContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import ResourceModel from "src/models/ResourceModel";
import ResourceModelType from "src/models/ResourceModelType";
import ResourceRepository from "src/repository/resourceRepository";

export const AccessContext = React.createContext({
  resources: [] as ResourceModel[] | undefined,
  refreshResources: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getResourceModelByResourceId: (resourceId: number) => null as ResourceModel | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getResourceModelByResourceType: (resourceModelType: ResourceModelType) => null as ResourceModel | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAllResourceModelsByResourceType: (resourceModelType: ResourceModelType) => null as ResourceModel[] | null,
});

export const AccessContextProvider = (props: React.PropsWithChildren) => {
  const { isAuthenticated } = useContext(AppContext);

  const [resources, setResources] = useState<ResourceModel[] | undefined>(undefined);

  const resourceRepository = new ResourceRepository();
  const isLightMode = useIsLightMode();

  useEffect(() => {
    loadResources();
  }, [isAuthenticated]);

  const loadResources = () => {
    resourceRepository
      .getResources()
      .then((resources) => {
        setResources(resources);
      })
      .catch((error) => {
        console.error(error);
        errorToast("Failed to get resources.", isLightMode);
      });
  };

  const getResourceModelByResourceId = (resourceId: number) => {
    const model = resources?.find((resource) => resource.id === resourceId);
    if (!model) return null;
    return model;
  };

  const getResourceModelByResourceType = (resourceModelType: ResourceModelType) => {
    const model = resources?.find((resource) => resource.type === resourceModelType);
    if (!model) return null;
    return model;
  };

  const getAllResourceModelsByResourceType = (resourceModelType: ResourceModelType) => {
    const models = resources?.filter((resource) => resource.type === resourceModelType);
    if (!models) return null;
    if (models.length === 0) return null;
    return models;
  };

  // resourceModel will be null while they are loading (or if they are never found)
  // resourceModel will be ResourceModel when loaded
  // ResourceModel will have .access that is null if the user has no access, or the access object if they do

  return (
    <AccessContext.Provider
      value={{
        resources,
        refreshResources: loadResources,
        getResourceModelByResourceId,
        getResourceModelByResourceType,
        getAllResourceModelsByResourceType,
      }}
    >
      {props.children}
    </AccessContext.Provider>
  );
};
