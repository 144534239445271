import Card from "@mui/material/Card";
import React from "react";

export default function MyCard({
  customStyle,
  children,
}: {
  customStyle: React.CSSProperties;
  children: React.ReactNode;
}) {
  return (
    <Card
      sx={{
        "&:hover": {
          boxShadow: (theme) => theme.customShadows.z24,
        },
        p: 2.5,
        mt: 1,
        mb: 1,
      }}
      style={customStyle}
    >
      {children}
    </Card>
  );
}
