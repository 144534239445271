import CheckIcon from "@mui/icons-material/Check";

import MoneyBackGuaranteeButton from "src/pages/products/components/MoneyBackGuaranteeButton";

const GPHCBenefits = () => {
  return (
    <div style={{ maxWidth: "600px" }}>
      <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
        <li style={{ marginBottom: "12px", display: "flex", alignItems: "flex-start" }}>
          <CheckIcon style={{ color: "#ff6600", marginRight: "8px", fontSize: "20px" }} />
          <span>95% pass rate for previous GPhC exams</span>
        </li>
        <li style={{ marginBottom: "12px", display: "flex", alignItems: "flex-start" }}>
          <CheckIcon style={{ color: "#ff6600", marginRight: "8px", fontSize: "20px" }} />
          <span>Trusted by over 5500 Trainees with GPhC exam revision</span>
        </li>
        <li style={{ marginBottom: "12px", display: "flex", alignItems: "flex-start" }}>
          <CheckIcon style={{ color: "#ff6600", marginRight: "8px", fontSize: "20px" }} />
          <span>Instant access! Start your revision after checkout</span>
        </li>
        <li style={{ marginBottom: "12px", display: "flex", alignItems: "flex-start" }}>
          <CheckIcon style={{ color: "#ff6600", marginRight: "8px", fontSize: "20px" }} />
          <span>Regular updates included</span>
        </li>
        <li style={{ display: "flex", alignItems: "flex-start" }}>
          <CheckIcon style={{ color: "#ff6600", marginRight: "8px", fontSize: "20px" }} />
          <span>
            <MoneyBackGuaranteeButton title="Money back / free extension policy" renderAsButton={false} /> available on
            the Foundation Pharmacist Training Programme only
          </span>
        </li>
      </ul>
    </div>
  );
};

export { GPHCBenefits };
