import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { FAQSection } from "src/context/FAQContext";
import SupportContent from "src/newComponents/SupportContent";

export default function MiniFAQsSPC({ topics }: { topics: FAQSection[] | null | undefined }) {
  return (
    <>
      <Stack
        spacing={3}
        sx={{
          maxWidth: 480,
          mx: "auto",
          textAlign: "center",
          mb: { xs: 3, sm: 5 },
        }}
      >
        <Typography variant="h2">Frequently Asked Questions</Typography>
      </Stack>

      {topics?.map((topic) => {
        return (
          <Card
            key={topic.title}
            sx={{
              height: "fit-content",
              padding: {
                xs: "1rem 1rem 0 1rem",
              },
              maxWidth: "800px",
              margin: {
                xs: "2rem 0rem",
                sm: "auto auto 2.5rem auto",
              },
            }}
          >
            <Typography variant="h4" style={{ marginBottom: 2, color: "#007635" }}>
              {topic.title}
            </Typography>

            <Stack direction="row">
              <SupportContent contents={topic.content} searchTerm={""} />
            </Stack>
          </Card>
        );
      })}

      <Stack alignItems="center" sx={{ mt: { xs: 3, sm: 1 } }}>
        <Button variant="outlined" size="large" color="inherit" href="/faq/">
          Learn More
        </Button>
      </Stack>
    </>
  );
}
