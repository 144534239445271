import Stack from "@mui/material/Stack";

interface ResourceCostProps {
  cost: number;
  originalCost: number;
  paddingTop: boolean;
}

const ResourceCost = ({ cost, originalCost, paddingTop }: ResourceCostProps) => {
  const sx = {
    typography: "subtitle2",
    pt: paddingTop ? 1 : 0,
  };

  return (
    <Stack sx={sx} direction="row" spacing={1.5}>
      {originalCost !== cost && <s>£{originalCost}</s>}
      <span>£{cost}</span>
    </Stack>
  );
};

export default ResourceCost;
