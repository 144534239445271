import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import { RenderMarkdown } from "src/components_with_stories/qb_question/RenderMarkdown";
import TutorModel from "src/models/TutorModel";

const RenderTutor = ({ tutor }: { tutor: TutorModel }) => {
  const [shortBio, setShortBio] = useState(true);

  return (
    <>
      <Typography variant="h4" sx={{ mb: 1.5, mt: 1.75 }}>
        Tutor
      </Typography>

      <Stack direction="row" alignItems="center" gap={1}>
        <Avatar src={tutor.profile_image ?? undefined} />

        <Stack direction="column">
          <Typography variant="body2">{tutor.name}</Typography>
          <Typography variant="body2">{tutor.role}</Typography>
        </Stack>
      </Stack>

      <Stack>
        <RenderMarkdown text={tutor.bio} lines={shortBio ? { mobile: 4, other: 2 } : undefined} />

        {shortBio && (
          <Typography variant="body1">
            <span className="faqPageLink" onClick={() => setShortBio(false)} style={{ cursor: "pointer" }}>
              Learn more
            </span>
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default RenderTutor;
