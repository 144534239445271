import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useRef, Suspense } from "react";
import Carousel from "react-slick";

import { CarouselArrows } from "./CarouselArrows";
import { TestimonialItemContent } from "./TestimonialItem";

const Slider = React.lazy(() => import("react-slick"));

export default function TestimonialElearning({
  testimonials,
}: {
  testimonials: {
    id: number;
    name: string;
    rating: number;
    reviewTitle: string;
    review: string;
    link: string;
  }[];
}) {
  const carouselRef1 = useRef<Carousel>(null);

  const carouselContentSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    infinite: true,
    cssEase: "linear",
    autoplaySpeed: 6000,
    autoplay: true,
  };

  const handlePrev = () => {
    carouselRef1.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef1.current?.slickNext();
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        overflow: "hidden",
        py: { xs: 1, md: 2 },
      }}
    >
      <Container sx={{ position: "relative" }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid xs={12} md={10}>
            <Typography variant="h2" sx={{ mb: 5 }}>
              What Our Trainees Say
            </Typography>

            <CarouselArrows
              onNext={handleNext}
              onPrev={handlePrev}
              leftButtonProps={{
                sx: { display: { xs: "none", md: "inline-flex" } },
              }}
              rightButtonProps={{
                sx: { display: { xs: "none", md: "inline-flex" } },
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <Slider {...carouselContentSettings} ref={carouselRef1}>
                  {testimonials.map((testimonial) => (
                    <TestimonialItemContent key={testimonial.id} testimonial={testimonial} />
                  ))}
                </Slider>
              </Suspense>
            </CarouselArrows>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
