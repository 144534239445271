import axios from "axios";
import { useContext } from "react";

import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import { QuestionDto } from "src/repository/questionSetRepository";
import { Bank } from "src/utils/format_bank";

import QBQuiz, { QBQuizMetaData, Weighting } from "../components_with_stories/QBQuiz";
import { AppContext } from "../context/AppContext";
import { saveQuizAttemptEndpoint } from "../urls";

interface QuizProps {
  trial: boolean;
  bank: Bank;
  bankUrl:
    | "calculations"
    | "clinical-mixed"
    | "clinical-therapeutics"
    | "clinical-mep"
    | "clinical-otc"
    | "try-calculation"
    | "try-clinical";
}

const Quiz = ({ trial, bank, bankUrl }: QuizProps) => {
  const { isAuthenticated } = useContext(AppContext);
  const isLightMode = useIsLightMode();

  const saveQuizAttempt = async (quizAttempt: QBQuizMetaData) => {
    if (!isAuthenticated) return Promise.resolve(null);

    const {
      timeTakenInSeconds,
      duration: maxDurationInMinutes,
      numQuestions,
      numberOfQuestionsAnsweredCorrectly,
      selectedBank,
      selectedWeighting,
    } = quizAttempt;

    try {
      const response = await axios.post(saveQuizAttemptEndpoint, {
        bank: selectedBank,
        weighting: selectedWeighting,
        num_questions: numQuestions,
        duration_in_minutes: maxDurationInMinutes,

        num_questions_correct: numberOfQuestionsAnsweredCorrectly,
        time_taken_in_seconds: timeTakenInSeconds,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.resolve(null);
    }
  };

  const getQuestions = async ({
    bank,
    weighting,
    numQuestions,
  }: {
    bank: Bank;
    weighting: Weighting | null;
    numQuestions: number;
  }) => {
    try {
      const response = await axios.get<QuestionDto[]>(`/api/questions/get/`, {
        params: {
          bank: bank,
          num_questions: numQuestions,
          weighting: weighting,
        },
      });
      return Promise.resolve(response.data);
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const errorMessage = error?.response?.data?.error;
        errorToast(errorMessage, isLightMode);
      } else {
        errorToast("Error loading questions. Please try again later.", isLightMode);
      }

      return Promise.resolve(null);
    }
  };

  return (
    <QBQuiz getQuestions={getQuestions} saveQuizAttempt={saveQuizAttempt} trial={trial} bank={bank} bankUrl={bankUrl} />
  );
};

export default Quiz;
