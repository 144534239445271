import List from "@mui/material/List";

import { ChildRoute } from "src/utils/routes";

import NavListMobile from "./NavListMobile";

export function NavSectionVertical({ data }: { data: ChildRoute[] }) {
  return (
    <List disablePadding sx={{ px: 2 }}>
      {data.map((list) => (
        <NavListMobile key={list.title + list.path} data={list} />
      ))}
    </List>
  );
}
