import ListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";
import { styled, alpha } from "@mui/material/styles";

import { NAV } from "src/config-global";

type NavItemProps = ListItemButtonProps & {
  active?: boolean;
};

export const StyledNavItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "active",
})<NavItemProps>(({ active, theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: NAV.H_ITEM,
  // Active
  ...(active && {
    color: theme.palette.primary.main,
    ...theme.typography.subtitle2,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  }),
}));
