import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { FormProvider } from "src/components/hook-form/FormProvider";
import { errorToast, loadingToast, successToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import EmailField from "src/newComponents/form/EmailField";
import { requestPasswordResetEndpoint } from "src/urls";
import { TEN_MINUTES } from "src/utils/duration";

export default function AuthResetPasswordForm() {
  const isLightMode = useIsLightMode();

  const [emailSent, setEmailSent] = useState(false);

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Email is required.").email("Invalid email address."),
  });

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: { email: string }) => {
    loadingToast("Requesting password reset email...", isLightMode);
    setEmailSent(true);
    const email = data.email;
    axios
      .post(requestPasswordResetEndpoint, { email })
      .then(() => {
        reset();
        const message = "Password reset email sent. Please check your inbox and spam folders.";
        successToast(message, isLightMode, TEN_MINUTES);
        setEmailSent(true);
      })
      .catch((error) => {
        const message = "Something went wrong. Please contact us for help.";
        errorToast(message, isLightMode, TEN_MINUTES);

        setEmailSent(false);
        console.log(error);
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <EmailField hasFocus={true} tabIndex={1} isDisabled={emailSent} />

      <LoadingButton
        fullWidth
        size="large"
        color="inherit"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ mt: 2.5 }}
        disabled={emailSent}
      >
        {emailSent ? "Email Sent" : "Reset Password"}
      </LoadingButton>
    </FormProvider>
  );
}
