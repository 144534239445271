import Stack from "@mui/material/Stack";

import trainingPlanImage from "src/assets/training_plan.webp";
import ChapterResource from "src/custom_components/courses/ChapterResource";
import useResponsive from "src/hooks/useResponsive";
import ProgrammeResourceModel from "src/models/ProgrammeResourceModel";
import LearnMoreButtonWithModal from "src/newComponents/buttons/LearnMoreButtonWithModal";

interface TrainingPlanProps {
  trainingPlan: ProgrammeResourceModel;
}

const TrainingPlan = ({ trainingPlan }: TrainingPlanProps) => {
  const isSmDown = useResponsive("down", "sm");
  return (
    <div>
      <h2>GPhC Approved Training Plan</h2>

      <img
        src={trainingPlanImage}
        alt="GPhC Approved Training Plan"
        loading="eager"
        title="GPhC Approved Training Plan"
        style={{
          width: isSmDown ? "100%" : "300px",
          height: "auto",
        }}
      />
      <p style={{ marginTop: "1rem", marginBottom: 0 }}>
        Designed to help Trainee Pharmacists stay on track and meet interim learning outcomes enabling you to get the
        best from your Foundation Training Year journey.
      </p>
      <p style={{ marginBottom: 0 }}>
        Note: Only accessible if you are enrolled on the Foundation Pharmacist Training Programme.
      </p>
      <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ mb: { xs: 1 } }}>
        <ChapterResource
          resourceDescription="Training Plan"
          accessibleResourceUrl={trainingPlan.accessibleUrl}
          isProgrammeResource={true}
        />
        <div style={{ marginTop: "16px" }}>
          <LearnMoreButtonWithModal
            title="GPhC Approved Training Plan"
            content={trainingPlan.description}
            rightMargin={false}
          />
        </div>
      </Stack>
    </div>
  );
};

export default TrainingPlan;
