import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";

const CustomAvatar = ({
  id,
  onClick,
  name,
}: {
  id: string | undefined;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  name: string;
}) => {
  return (
    <IconButton color="inherit" aria-label="user profile" onClick={onClick}>
      <Avatar
        sx={{
          width: 32,
          height: 32,
          bgcolor: "rgba(250, 84, 28, 0.8)",
          pt: 0.15,
        }}
        aria-describedby={id}
      >
        <Typography variant="body1" color="white">
          {name[0]}
        </Typography>
      </Avatar>{" "}
    </IconButton>
  );
};

export default CustomAvatar;
