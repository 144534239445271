import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { alpha, SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import BasketItemModel from "src/models/BasketItemModel";
import TrustBox from "src/pages/landing/components/Trustbox";

import { PaymentIcons } from "./PaymentIcons";
import ProceedToPayment from "./ProceedToPayment";

export default function CartSummary({ items, isMobile }: { items: BasketItemModel[]; isMobile: boolean }) {
  const subTotal = items.reduce((acc, item) => acc + item.originalCost, 0);
  const total = items.reduce((acc, item) => acc + item.cost, 0);
  const discount = subTotal - total;

  const months = total >= 250 ? 12 : 3;
  const asLowAs = items.length > 0 ? Math.floor(total / months) : null;

  return (
    <Stack
      spacing={2}
      sx={{
        p: 5,
        borderRadius: 2,
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Summary
      </Typography>
      <Stack spacing={2}>
        <Row label="Subtotal" value={`£${subTotal.toFixed(2)}`} />
        <Row label="Discount" value={`-£${discount.toFixed(2)}`} />
      </Stack>
      <Divider sx={{ borderStyle: "dashed" }} style={{ marginBottom: "0.5rem", marginTop: "1.25rem" }} />
      <Row label="Total" value={`£${total.toFixed(2)}`} sx={{ typography: "h6" }} />

      {asLowAs && (
        <Typography variant="body2" style={{ marginBottom: "0.25rem", marginTop: "0.25rem" }}>
          Or as low as £{asLowAs} per month with Klarna (0% APR) over {months} months
        </Typography>
      )}

      <ProceedToPayment items={items} isMobile={isMobile} />

      <TrustBox size="small" />

      <PaymentIcons />

      <Typography variant="body2" style={{ marginTop: "8px", textAlign: "center" }}>
        <em>Have a discount code? Click 'Proceed to Payment' to enter and apply your code. </em>
      </Typography>
    </Stack>
  );
}

function Row({ label, value, sx }: { label: string; value: string; sx?: SxProps<Theme> }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ typography: "subtitle2", ...sx }}>
      <Box component="span" sx={{ typography: "body2" }}>
        {label}
      </Box>
      {value}
    </Stack>
  );
}
