export const lowWeightedQuestions = "Low";
export const mediumWeightedQuestions = "Medium";
export const highWeightedQuestions = "High";

const getWeightingName = (weighting: number | string | null | undefined) => {
  if (!weighting) return "";

  const weighting_lower = weighting.toString().toLowerCase();

  if (weighting_lower === "low") return lowWeightedQuestions;
  if (weighting_lower === "medium") return mediumWeightedQuestions;
  if (weighting_lower === "high") return highWeightedQuestions;

  if (weighting_lower === "3") return lowWeightedQuestions;
  if (weighting_lower === "2") return mediumWeightedQuestions;
  if (weighting_lower === "1") return highWeightedQuestions;

  return weighting;
};

export default getWeightingName;
