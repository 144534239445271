import ChapterModel from "src/models/ChapterModel";
import CourseLessonProgressModel from "src/models/CourseLessonProgressModel";
import CourseModel from "src/models/CourseModel";
import DesignatedSupervisorProgrammeModel from "src/models/DesignatedSupervisorProgrammeModel";

import Chapter from "./Chapter";

const Course = ({
  course,
  simplifiedView,
  courseLessonProgress,
  updateLessonProgress,
}: {
  course: CourseModel | DesignatedSupervisorProgrammeModel;
  simplifiedView: boolean;
  courseLessonProgress?: CourseLessonProgressModel[] | undefined;
  updateLessonProgress?: (lessonProgress: CourseLessonProgressModel) => void;
}) => {
  const numUniqueWeights = numUniqueWeightsInCourse(course);
  const showChapterWeight = numUniqueWeights > 1;

  return (
    <>
      {course.chapters.map((chapter, chapterIdx) => {
        const chapterProgress = getChapterProgress(chapter, courseLessonProgress);
        return (
          <Chapter
            key={chapter.id}
            chapter={chapter}
            chapterIdx={chapterIdx}
            showChapterWeight={showChapterWeight}
            simplifiedView={simplifiedView}
            chapterProgress={chapterProgress}
            updateLessonProgress={updateLessonProgress}
          />
        );
      })}
    </>
  );
};

export default Course;

const numUniqueWeightsInCourse = (course: CourseModel | DesignatedSupervisorProgrammeModel) => {
  const allWeightsInCourse = course.chapters.map((chapter) => chapter.weight);
  const weightsAsSet = new Set(allWeightsInCourse);
  return weightsAsSet.size;
};

const getChapterProgress = (chapter: ChapterModel, courseLessonProgress: CourseLessonProgressModel[] | undefined) => {
  if (!courseLessonProgress) return null;

  const lessonIds = chapter.lessons.map((lesson) => lesson.id);
  const totalLessonsInChapter = lessonIds.length;

  if (totalLessonsInChapter === 0) return null;

  const lessonProgresses = courseLessonProgress.filter((clp) => lessonIds.includes(clp.lesson));

  // user has not started the chapter
  if (lessonProgresses.length === 0) return 0;

  const progresses = lessonProgresses.map((clp) => {
    if (clp.watched) return 100;
    return clp.progress;
  });

  const totalProgress = progresses.reduce((acc, curr) => acc + curr, 0);
  const averageProgress = totalProgress / totalLessonsInChapter;
  return averageProgress;
};
