import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Label from "src/components/labels/Label";
import useActiveLink from "src/hooks/useActiveLink";
import { Route } from "src/utils/routes";

import { NavItemDesktop } from "./NavItemDesktop";
import { StyledMenu } from "./styles";

export default function NavListDesktop({ item }: { item: Route }) {
  const { pathname } = useLocation();

  const [openMenu, setOpenMenu] = useState(false);

  const { path, children } = item;

  const active = useActiveLink(path, false);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenMenu = () => {
    if (children) {
      setOpenMenu(true);
    }
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const width = 380;
  const halfWidth = width / 2;

  return (
    <div style={{ position: "relative" }}>
      <NavItemDesktop
        item={item}
        active={active}
        subItem={false}
        makeParentClickable={false}
        onMouseEnter={handleOpenMenu}
        onMouseLeave={handleCloseMenu}
      />

      {!!children && openMenu && (
        <div
          style={{
            position: "absolute",
            top: "54px",
            left: `calc(50% - ${halfWidth}px)`,
            width: `${width}px`,
          }}
          onMouseEnter={handleOpenMenu}
          onMouseLeave={handleCloseMenu}
        >
          <StyledMenu>
            <Stack alignItems="flex-start">
              {children.map((item) => (
                <div
                  key={item.title}
                  style={
                    item.isComingSoon || item.isNew
                      ? { display: "flex", alignItems: "baseline" }
                      : {
                          width: "100%",
                        }
                  }
                >
                  <div style={{ marginLeft: "2rem" }}>
                    <NavItemDesktop
                      item={item}
                      active={item.path === pathname}
                      subItem={true}
                      disabled={item.isComingSoon}
                    />
                  </div>
                  {item.isComingSoon && (
                    <Label color="info" sx={{ ml: 1.5 }}>
                      Coming Soon
                    </Label>
                  )}
                  {item.isNew && (
                    <Label color="success" sx={{ ml: 1.5 }}>
                      New
                    </Label>
                  )}
                </div>
              ))}
            </Stack>
          </StyledMenu>
        </div>
      )}
    </div>
  );
}
