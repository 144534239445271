import { Icon } from "@iconify/react";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";
import { forwardRef } from "react";

interface IconifyProps {
  icon: string;
  width?: number;
  sx?: SxProps<Theme>;
  [key: string]: any; // This allows for any other additional props
}

const Iconify = forwardRef<HTMLDivElement, IconifyProps>(({ icon, width = 20, sx, ...other }, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, flexShrink: 0, ...sx }} {...other} />
));

Iconify.displayName = "Iconify";

export { Iconify };
