import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Iconify } from "src/components/Iconify";

interface ZoomButtonProps {
  href: string;
  onClick?: () => void;
  buttonText: string;
}

const ZoomButton = ({ href, onClick, buttonText }: ZoomButtonProps) => {
  return (
    <Button size="small" variant="outlined" href={href} target="_blank" color="inherit" onClick={onClick ?? undefined}>
      <Iconify icon="logos:zoom" sx={{ mr: 1 }} style={{ width: "40px", height: "auto" }} />
      <Typography variant="body2">{buttonText}</Typography>
    </Button>
  );
};

export default ZoomButton;
