import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";

import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { CoursesContext } from "src/context/CoursesContext";
import { FAQContext } from "src/context/FAQContext";
import MiniFAQsSPC from "src/newComponents/MiniFAQsSPC";
import PageTemplate from "src/pages/PageTemplate";

import ElearningCourseItem from "./components/ElearningCourseItem";

const CoursesPage = () => {
  const { getContentForPage } = useContext(FAQContext);
  const miniFaqContent = getContentForPage("Exam Revision Courses");

  const { courses, isLoadingCourses } = useContext(CoursesContext);
  const popularCourses = courses.filter((course) => course.popularOrder < 100);

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/courses/" }]}>
        <title>Exam Revision Courses | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Explore comprehensive pharmacy training courses and resources. Choose from our best-selling GPhC registration exam courses and plans."
        />
        <meta
          name="keywords"
          content="Online Pharmacy Revision, Combo Course, MEP Course, OTC Course, First Aid Training, Vaccination Training, Question Bank, Career Zoom Workshops, GPhC Exam Revision, Online Learning"
        />
      </Helmet>
      {(isLoadingCourses || courses.length === 0) && <LoadingScreen />}
      <PageTemplate title="Exam Revision Courses">
        <Typography sx={{ margin: "1rem 0" }}>
          Our Exam Revision courses, are recorded lessons covering Calculations, BNF, MEP, OTC. These courses are
          suitable for Trainee Pharmacists attempting the GPhC exam & Pharmacy students studying for exams. Aligned to
          the GPhC framework, all courses are recorded giving you instant access after enrolment to be completed along
          with our workbooks.
        </Typography>
        <Divider sx={{ mt: 3 }} style={{ borderColor: "transparent" }} />

        <Stack spacing={4}>
          {popularCourses.map((course) => (
            <ElearningCourseItem key={course.id} course={course} />
          ))}
        </Stack>

        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <MiniFAQsSPC topics={miniFaqContent} />
      </PageTemplate>
    </>
  );
};

export default CoursesPage;
