import { IconifyIcon } from "@iconify/react";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack, { StackProps } from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React from "react";

import { Iconify } from "src/components/Iconify";

interface StyledIconButtonProps extends IconButtonProps {
  shape?: "circular" | "rounded";
  hasChild?: boolean;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "hasChild" && prop !== "shape",
})<StyledIconButtonProps>(({ shape, hasChild, theme }) => ({
  color: "inherit",
  transition: theme.transitions.create("all", {
    duration: theme.transitions.duration.shorter,
  }),
  ...(shape === "rounded" && {
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
  }),
  opacity: 0.48,
  "&:hover": {
    opacity: 1,
  },
  ...(hasChild && {
    zIndex: 9,
    top: "50%",
    position: "absolute",
    marginTop: theme.spacing(-2.5),
  }),
}));

type IconifyProps = IconifyIcon | string;

interface Props extends StackProps {
  shape?: "circular" | "rounded";
  filled?: boolean;
  children: React.ReactNode;
  icon?: IconifyProps;
  onNext?: VoidFunction;
  onPrev?: VoidFunction;
  leftButtonProps?: IconButtonProps;
  rightButtonProps?: IconButtonProps;
  iconSize?: number;
}

function CarouselArrows({
  shape = "circular",
  icon,
  onNext,
  onPrev,
  children,
  leftButtonProps,
  rightButtonProps,
  iconSize = 24,
}: Props) {
  return (
    <Stack>
      {onNext && (
        <StyledIconButton
          shape={shape}
          hasChild={!!children}
          onClick={onPrev}
          {...leftButtonProps}
          sx={{
            left: 16,
            ...leftButtonProps?.sx,
          }}
        >
          <LeftIcon icon={icon} size={iconSize} />
        </StyledIconButton>
      )}
      {children}
      {onPrev && (
        <StyledIconButton
          shape={shape}
          hasChild={!!children}
          onClick={onNext}
          {...rightButtonProps}
          sx={{
            right: 16,
            ...rightButtonProps?.sx,
          }}
        >
          <RightIcon icon={icon} size={iconSize} />
        </StyledIconButton>
      )}
    </Stack>
  );
}

type IconProps = {
  icon?: IconifyProps;
  size?: number;
};

function LeftIcon({ icon = "carbon:chevron-right", size = 24 }: IconProps) {
  return <Iconify icon={icon} width={size} sx={{ transform: " scaleX(-1)" }} />;
}

function RightIcon({ icon = "carbon:chevron-right", size = 24 }: IconProps) {
  return <Iconify icon={icon} width={size} />;
}

export { CarouselArrows };
