import axios from "axios";

import { QUIZ_FINISHED_FEEDBACK_PAGE } from "src/components_with_stories/QuizStates";
import { dismissAllToasts, errorToast, successToast } from "src/components_with_stories/toast";
import QuestionSetAttemptModel from "src/models/QuestionSetAttemptModel";
import QuestionSetModel from "src/models/QuestionSetModel";
import DateService, { DateType } from "src/utils/dateService";

export interface QuestionDto {
  id: number;
  bank: string;
  title?: string;
  text?: string;
  why?: string;
  resource_image?: string;
  visible: boolean;
  is_calculation: boolean;

  set_order?: number; // only for set question
  question_set?: number; // only for set question
  is_free?: boolean; // only for BANK question

  // calculation
  correct_answer?: string; // calculation answer
  answer_unit?: string; // calculation unit

  // clinical
  correct_answer_number?: number; // clinical answer
  weighting?: number; // clinical question weighting
  answers?: {
    text: string;
    answer_number: number;
  }[];
}

export interface AttemptedQuestionDto {
  answerId?: number | string; // id if multiple choice (clin), string if free text (calc)
  currentQuestionNumber: number;
  isCalculation: boolean;
  isFlagged: boolean;
  totalNumberOfQuestions: number;
  question: {
    actualAnswerId?: number | string; // id if multiple choice (clin), string if free text (calc)
    actualAnswerReason?: string | null;
    answerUnit?: string | null;
    possibleAnswers?: {
      answerId: number;
      answerText: string;
    }[];
    questionId: number;
    questionSet?: number;
    questionText?: string;
    questionTitle?: string | null;
    resourceImageUrl?: string | null;
    visible: boolean;
  };
}

interface SavedQuestionSetAttemptDto {
  question_set: number;
  start: string;
  end: string;
  duration: number;
  extra_time: number;
  attempt_data: AttemptedQuestionDto[];
}

interface UnsavedQuestionSetAttemptDto {
  question_set_id: number;
  start: string;
  end: string;
  extra_time: number;
  attempt_data: string;
}

class QuestionSetRepostiory {
  async startQuestionSet(questionSetId: number, attemptDuration: number) {
    try {
      const response = await axios.post<QuestionDto[]>(`/api/user_start_question_set/`, {
        question_set_id: questionSetId,
        attempt_duration: attemptDuration,
      });

      const mockPaperQuestions = response.data;
      return mockPaperQuestions;
    } catch (error) {
      console.error(error);
      throw new Error("Failed to start mock paper.");
    }
  }

  generateRequestData(
    questionSet: QuestionSetModel,
    start: DateType,
    end: DateType,
    totalDuration: number,
    attemptedQuestions: AttemptedQuestionDto[]
  ) {
    const setDuration = questionSet.duration;
    const extraTime = totalDuration - setDuration;
    const attempt: UnsavedQuestionSetAttemptDto = {
      question_set_id: questionSet.questionSetId,
      start: DateService.formatForAPI(start),
      end: DateService.formatForAPI(end),
      extra_time: extraTime,
      attempt_data: JSON.stringify(attemptedQuestions),
    };
    return attempt;
  }

  async saveAttempt(requestData: UnsavedQuestionSetAttemptDto) {
    return await axios.post(`/api/user_finish_question_set/`, requestData);
  }

  async attemptToSaveMockPaperAttemptAgain(isLightMode: boolean) {
    const requestData = localStorage.getItem("mp_failedToSaveAttempt");
    if (requestData) {
      console.log("Found a mock paper attempt that failed to save. Retrying...");
      const data: UnsavedQuestionSetAttemptDto = JSON.parse(requestData);
      this.saveAttempt(data)
        .then(() => {
          dismissAllToasts();
          console.log("Attempt saved successfully");
          localStorage.removeItem("mp_failedToSaveAttempt");
          successToast("Mock Paper attempt saved successfully.", isLightMode);
        })
        .catch(() => {
          errorToast(
            "Error saving Mock Paper attempt. Please refresh the page. If the issue persists, contact support.",
            isLightMode
          );
        });
    } else {
      console.log("No failed mock paper attempts found. Skipping retry...");
    }
  }

  async getAttempt(questionSetId: number, questionSetDuration: number): Promise<QuestionSetAttemptModel> {
    try {
      const response = await axios.post<SavedQuestionSetAttemptDto>(`/api/user_get_question_set_attempt/`, {
        question_set_id: questionSetId,
      });
      const dto = response.data;

      const { start, end, extra_time, attempt_data: attemptQuestions } = dto;
      const metadata = {
        startTime: start,
        duration: questionSetDuration + extra_time,
        numberOfQuestionsAnsweredCorrectly: attemptQuestions.filter((q) => q.answerId === q.question.actualAnswerId)
          .length,
        timeTakenInSeconds: DateService.parseServerDate(end).diff(DateService.parseServerDate(start), "second"),
      };

      // as we don't know the timezone, assume it expired yesterday
      // we don't surface this anyway
      const expiredYesterday = DateService.parseServerDate(end).add(-1, "day").toISOString();

      const attempt: QuestionSetAttemptModel = {
        quizMetaData: metadata,
        quizState: QUIZ_FINISHED_FEEDBACK_PAGE,
        questions: attemptQuestions,
        hideTimer: false,
        quizExpiry: expiredYesterday,
      };

      return attempt;
    } catch (error) {
      console.error(error);
      throw new Error("Failed to get attempt.");
    }
  }
}

export default QuestionSetRepostiory;
