import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ProductsPageButton from "src/newComponents/buttons/ProductsPageButton";
import NumbersSPC from "src/newComponents/NumbersSPC";

import TrustBox from "./Trustbox";

const LandingPageTopSPC = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        mt: { md: 4, lg: 0 },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "2rem", sm: "2.2rem", md: "2.4rem" },
          maxWidth: { xs: "95%", sm: "90%", md: "80%" },
        }}
        style={{ textAlign: "center" }}
      >
        Start your
        <Box component="span" sx={{ color: "#009d47" }}>
          {` Foundation Training Programme `}
        </Box>
        with{" "}
        <Box component="span" sx={{ color: "primary.main" }}>
          {`Pre-Reg Shortcuts `}
        </Box>
      </Typography>

      <Typography sx={{ color: "text.secondary", mt: 2, mb: 4, textAlign: "center" }}>
        Enrol on our interactive Pharmacy Foundation Year Programme and succeed in your GPhC Registration Assessment
      </Typography>

      <Stack spacing={2} justifyContent="center" direction="row">
        <Button color="inherit" size="large" variant="outlined" href="/about/">
          About Us
        </Button>

        <ProductsPageButton />
      </Stack>

      <Divider sx={{ borderStyle: "dashed", mt: 3, mb: 2 }} style={{ borderColor: "transparent" }} />

      <NumbersSPC isLandingPage={true} />

      <Divider sx={{ borderStyle: "dashed", mt: 4, mb: 2 }} style={{ borderColor: "transparent" }} />

      <TrustBox />
    </Stack>
  );
};

export default LandingPageTopSPC;
