import QuestionSetAttemptModel from "src/models/QuestionSetAttemptModel";
import DateService from "src/utils/dateService";

import { QUIZ_NOT_STARTED } from "../QuizStates";

export const getMPQuizDefaultState = (questionSetAttempt: QuestionSetAttemptModel | null, initialDuration: number) => {
  const attemptIsDefined = questionSetAttempt !== null && questionSetAttempt !== undefined;

  if (attemptIsDefined) {
    const attemptHasHideTimer = questionSetAttempt.hideTimer !== undefined;
    const attemptHasQuestions = questionSetAttempt.questions !== undefined;
    const attemptHasQuizExpiry = questionSetAttempt.quizExpiry !== undefined;
    const attemptHasQuizMetaData = questionSetAttempt.quizMetaData !== undefined;
    const attemptHasQuizState = questionSetAttempt.quizState !== undefined;

    const attemptIsValid =
      attemptHasHideTimer &&
      attemptHasQuestions &&
      attemptHasQuizExpiry &&
      attemptHasQuizMetaData &&
      attemptHasQuizState;

    const quizMetaData = getMetaData(questionSetAttempt);

    if (attemptIsValid && quizMetaData) {
      return {
        hideTimer: questionSetAttempt.hideTimer,
        questions: questionSetAttempt.questions,
        quizExpiry: new Date(questionSetAttempt.quizExpiry),
        quizMetaData: quizMetaData,
        quizState: questionSetAttempt.quizState,
        isViewingAttempt: true,
      };
    }
  }
  return {
    hideTimer: false,
    questions: null,
    quizExpiry: null,
    quizMetaData: {
      duration: initialDuration,
      startTime: null,
    },
    quizState: QUIZ_NOT_STARTED,
    isViewingAttempt: false,
  };
};

const getMetaData = (questionSetAttempt: QuestionSetAttemptModel) => {
  const metadata = questionSetAttempt.quizMetaData;
  const startTime = metadata.startTime;
  if (!startTime) {
    return null;
  }
  return {
    ...metadata,
    startTime: DateService.parseServerDate(startTime),
  };
};
