import { useLocalStorage } from "@uidotdev/usehooks";
import React, { useContext } from "react";

import imagePlaceholder from "src/assets/placeholder.jpeg";
import { confirmToast, errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import BasketItemModel from "src/models/BasketItemModel";
import ResourceModel from "src/models/ResourceModel";
import BasketRepostiory from "src/repository/basketRepository";

import { AccessContext } from "./AccessContext";
import { AppContext } from "./AppContext";

export const BASKET_ITEMS_KEY = "basketItems";
export const BASKET_ID_KEY = "basketId";

interface BasketContextType {
  items: BasketItemModel[];
  basketId: string | null;
  isResourceInBasket: (resourceModel: ResourceModel) => boolean;
  addItemToBasket: (name: string, description: string, image: string, resourceModel: ResourceModel) => Promise<boolean>;
  removeItemFromBasketOnConfirm: (stripePriceId: string, resourceId: number, onConfirmAndCancel: () => void) => void;
  forceRemoveItemsFromBasket: (itemsToRemove: BasketItemModel[]) => void;
  checkoutSuccess: () => void;
}

export const BasketContext = React.createContext({} as BasketContextType);

export const BasketContextProvider = (props: React.PropsWithChildren) => {
  const { userId } = useContext(AppContext);
  const { refreshResources } = useContext(AccessContext);

  const [items, setItems] = useLocalStorage<BasketItemModel[]>(BASKET_ITEMS_KEY, []);
  const [basketId, setBasketId] = useLocalStorage<string | null>(BASKET_ID_KEY, null);

  const isLightMode = useIsLightMode();
  const basketRepository = new BasketRepostiory(basketId);

  const addItemToBasket = async (name: string, description: string, image: string, resourceModel: ResourceModel) => {
    try {
      const newItem = createBasketItem(name, description, image, resourceModel);
      const isAlreadyInBasket = isResourceInBasket(resourceModel);

      if (isAlreadyInBasket) {
        errorToast("Item already in basket.", isLightMode);
        return false;
      }

      const [id, basketItemId] = await basketRepository.handleAddToBasket(userId, resourceModel.id);

      setBasketId(id);
      setItems((prevItems) => {
        return [...prevItems, { ...newItem, basketItemId: basketItemId }];
      });

      return true;
    } catch (e) {
      console.error(e);
      errorToast("Error adding item to basket.", isLightMode);
    }

    return false;
  };

  const _removeItemFromBasket = async (stripePriceId: string, resourceId: number) => {
    const item = items.find((item) => item.stripePriceId === stripePriceId);

    if (!item) {
      errorToast("Error removing item from basket. Item not found.", isLightMode);
      return false;
    }

    if (!item.basketItemId) {
      errorToast("Error removing item from basket. Basket item id not found.", isLightMode);
      return false;
    }

    try {
      await basketRepository.removeItemFromBasket(resourceId, item.basketItemId);
    } catch (e) {
      console.error(e);
      errorToast("Error removing item from basket.", isLightMode);
      return false;
    }

    setItems((prevItems) => {
      return prevItems.filter((item) => item.stripePriceId !== stripePriceId);
    });

    return true;
  };

  const forceRemoveItemsFromBasket = async (itemsToRemove: BasketItemModel[]) => {
    const itemsExistInBasket = itemsToRemove.every((itemToRemove) => {
      return items.some((item) => item.stripePriceId === itemToRemove.stripePriceId);
    });
    if (!itemsExistInBasket) {
      errorToast("Error removing items from basket. Items not found.", isLightMode);
      return;
    }
    try {
      await basketRepository.removeItemsFromBasket(itemsToRemove);

      setItems((prevItems) => {
        return prevItems.filter(
          (item) => !itemsToRemove.some((itemToRemove) => item.stripePriceId === itemToRemove.stripePriceId)
        );
      });
    } catch (e) {
      console.error(e);
      errorToast("Error removing item from basket.", isLightMode);
    }
  };

  const removeItemFromBasketOnConfirm = (stripePriceId: string, resourceId: number, onConfirmAndCancel: () => void) => {
    const item = items.find((item) => item.stripePriceId === stripePriceId);

    if (!item) {
      errorToast("Error removing item from basket. Item not found.", isLightMode);
      return;
    }

    confirmToast({
      message: (
        <p style={{ marginTop: 0 }}>
          Are you sure you want to remove <strong>{item.name}</strong> from your basket?
        </p>
      ),
      isLightMode: isLightMode,
      onConfirm: () => {
        _removeItemFromBasket(stripePriceId, resourceId).then(() => {
          onConfirmAndCancel();
        });
      },
      confirmText: "Remove",
      onCancel: onConfirmAndCancel,
    });
  };

  const isResourceInBasket = (resourceModel: ResourceModel) => {
    return items.some((item) => item.stripePriceId === resourceModel.stripePriceId);
  };

  const checkoutSuccess = () => {
    setBasketId(null);
    setItems([]);
    refreshResources();
  };

  return (
    <BasketContext.Provider
      value={{
        items,
        basketId,
        isResourceInBasket,
        addItemToBasket,
        removeItemFromBasketOnConfirm,
        forceRemoveItemsFromBasket,
        checkoutSuccess,
      }}
    >
      {props.children}
    </BasketContext.Provider>
  );
};

const createBasketItem = (name: string, description: string, image: string, resourceModel: ResourceModel) => {
  const newItem: BasketItemModel = {
    name,
    description,
    image: image ?? imagePlaceholder,
    stripePriceId: resourceModel.stripePriceId,
    resourceId: resourceModel.id,
    cost: resourceModel.cost,
    originalCost: resourceModel.originalCost,
    resourceModelType: resourceModel.type,
    includedInPremium: resourceModel.isIncludedInPremium,
  };

  return newItem;
};
