import { ComponentType, Suspense, ReactElement } from "react";

import LoadingScreen from "src/components/loading-screen/LoadingScreen";

const lazyLoadPage = <T extends object = Record<string, never>>(Component: ComponentType<T>) => {
  const LazyComponent = (props?: T): ReactElement => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...(props as T)} />
    </Suspense>
  );

  LazyComponent.displayName = `LazyLoaded(${Component.displayName || Component.name || "Component"})`;

  return LazyComponent;
};

lazyLoadPage.displayName = "LazyLoadPage";

export { lazyLoadPage };
