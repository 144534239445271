import { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { AppContext } from "src/context/AppContext";
import CourseLessonProgressModel from "src/models/CourseLessonProgressModel";
import CourseModel from "src/models/CourseModel";
import CourseRepository from "src/repository/courseRepository";

import ElearningCourseView from "./components/ElearningCourseView";

const CoursePage = () => {
  const { courseSlug } = useParams();
  const { isAuthenticated } = useContext(AppContext);

  const [course, setCourse] = useState<CourseModel>();
  const [courseLessonProgress, setCourseLessonProgress] = useState<CourseLessonProgressModel[]>();
  const [isLoading, setIsLoading] = useState(true);

  const courseRepository = new CourseRepository();

  useEffect(() => {
    if (courseSlug) {
      courseRepository
        .getCourse(courseSlug)
        .then((course) => setCourse(course))
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (course && course.id && courseLessonProgress === undefined) {
      courseRepository.getCourseLessonProgress(course.id).then(setCourseLessonProgress);
    }
  }, [course, isAuthenticated]);

  const updateLessonProgress = (lessonProgress: CourseLessonProgressModel) => {
    if (!courseLessonProgress) return;

    const lessonExists = courseLessonProgress.some((clp) => clp.lesson === lessonProgress.lesson);

    if (lessonExists) {
      const updatedCourseLessonProgress = courseLessonProgress.map((clp) => {
        if (clp.watched) return clp; // don't update watched lessons
        if (clp.lesson === lessonProgress.lesson) {
          return { ...clp, progress: lessonProgress.progress, watched: lessonProgress.watched };
        }
        return clp;
      });
      setCourseLessonProgress(updatedCourseLessonProgress);
    } else {
      const updatedCourseLessonProgress: CourseLessonProgressModel[] = [...courseLessonProgress, lessonProgress];
      setCourseLessonProgress(updatedCourseLessonProgress);
    }
  };

  const canonicalUrl = "/courses/" + courseSlug;

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: canonicalUrl }]}>
        <title>{course ? course.title : courseSlug} | Pre-Reg Shortcuts</title>
        {course && (
          <meta
            name="description"
            content={`${course.seoDescription.slice(0, 150)}${course.seoDescription.length > 150 ? "..." : ""}`}
          />
        )}
        <meta
          name="keywords"
          content="Registration assessment outcomes, Therapeutic areas (high medium and low), High risk drugs, paediatrics, calculations"
        />
      </Helmet>
      {isLoading && <LoadingScreen />}
      {course && (
        <ElearningCourseView
          course={course}
          courseLessonProgress={courseLessonProgress}
          updateLessonProgress={updateLessonProgress}
        />
      )}
    </>
  );
};

export default CoursePage;
