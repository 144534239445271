import { useTheme } from "@mui/material/styles";
import { merge } from "lodash-es";
import React, { Suspense } from "react";

import useResponsive from "src/hooks/useResponsive";

import "./markdown.css";

const MarkdownRenderer = React.lazy(() => import("./MarkdownRenderer"));

const RenderMarkdown = ({
  text,
  lines,
  asSecondaryBody2,
  compact,
}: {
  text: string | null | undefined;
  lines?: {
    mobile: number;
    other: number;
  };
  asSecondaryBody2?: boolean;
  compact?: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useResponsive("down", "sm");

  const linesToRender = isMobile ? lines?.mobile : lines?.other;

  const primaryTextColor = theme.palette.text.primary;
  const secondaryTextColor = theme.palette.text.secondary;

  const bodyCSSProperties = theme.typography.body1;
  const body2CSSProperties = theme.typography.body2;

  const style = merge(
    {
      color: asSecondaryBody2 ? secondaryTextColor : primaryTextColor,
      margin: 0,
    },
    asSecondaryBody2 ? body2CSSProperties : bodyCSSProperties
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div style={style} className={`markdown-container ${compact ? "compact" : ""}`}>
        <MarkdownRenderer text={text} lines={linesToRender} />
      </div>
    </Suspense>
  );
};

export { RenderMarkdown };
