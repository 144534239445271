import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import { Iconify } from "src/components/Iconify";
import { getLinkLockDetails } from "src/utils/requirement/requirement";

interface ChapterResourceProps {
  resourceDescription: string;
  accessibleResourceUrl: string;
  isProgrammeResource: boolean;
}

const ChapterResource = ({ resourceDescription, accessibleResourceUrl, isProgrammeResource }: ChapterResourceProps) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleCloseNotification = (event: any, reason: string) => {
    if (reason === "clickaway") return;
    setIsAlertOpen(false);
  };

  const { isLocked, link, lockReason } = getLinkLockDetails(accessibleResourceUrl);

  return (
    <div>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={"To access resources on this course, " + lockReason}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={() => handleCloseNotification(undefined, "close")}
          >
            <CloseIcon />
          </IconButton>
        }
      />

      <Button
        size="small"
        variant="outlined"
        href={isLocked || link === null ? "" : link}
        target="_blank"
        color="inherit"
        onClick={() => {
          isLocked && setIsAlertOpen(true);
        }}
        sx={{ mt: 1.5 }}
        disabled={isProgrammeResource && isLocked}
      >
        <Iconify icon="ion:open-outline" sx={{ mr: 0.8 }} style={{ width: "20px", height: "auto" }} />
        <Typography variant="body2">Download {resourceDescription}</Typography>
      </Button>
    </div>
  );
};

export default ChapterResource;
