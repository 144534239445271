import axios from "axios";
import Cookies from "js-cookie";
import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAxiosAuthInterceptor } from "src/hooks/useAxiosAuthInterceptor";

import { AppContext } from "./context/AppContext";
import { userDetailsEndpoint } from "./urls";

// when set to True, only the developer can access the UI
// all other users, including superusers will be redirected
// to the maintenance page
export const MAINTENANCE_MODE = false;

const authenticateUsingStoredSessionCookie = (
  updateUserDetails: (
    id: number,
    emailAddress: string,
    name: string,
    phone: string,
    isSuperuser: boolean,
    loggedInViaSessionCookies: boolean
  ) => void,
  setSessionCookieExists: (exists: boolean) => void
) => {
  axios
    .get(userDetailsEndpoint)
    .then((response) => {
      const id = response.data.id;
      const email = response.data.email;
      const name = response.data.name;
      const phone = response.data.phone;
      const isSuperuser = response.data.is_superuser;
      updateUserDetails(id, email, name, phone, isSuperuser, true);
      console.log("Authenticated with cookies");
    })
    .catch(() => {
      console.log("Error occurred authenticating with cookies");
      Cookies.remove("csrftoken");
      setSessionCookieExists(false);
    });
};

const AuthWrapper = ({ children }: React.PropsWithChildren): React.ReactElement => {
  const { isAuthenticated, updateUserDetails, emailAddress } = useContext(AppContext);

  useAxiosAuthInterceptor();

  const csrfCookie = Cookies.get("csrftoken");
  const csrfCookieExists = csrfCookie ? csrfCookie.length > 0 : false;
  const location = useLocation();
  const navigate = useNavigate();

  // Assume that if the csrfcookie exists, the session cookie exists
  // Allowed as the authentication will fail without the session cookie
  // This is done because we cannot access the session cookie (prevent XSS)
  const [sessionCookieExists, setSessionCookieExists] = useState(csrfCookieExists);

  if (!isAuthenticated && sessionCookieExists && csrfCookieExists) {
    // if we're on the verify page, we're expecting user to provide OTP token to verify
    // so don't attempt to authenticate using cookies
    if (!location.pathname.includes("verify")) {
      authenticateUsingStoredSessionCookie(updateUserDetails, setSessionCookieExists);
    }
  }

  if (MAINTENANCE_MODE) {
    if (!isAuthenticated) {
      if (!location.pathname.includes("maintenance")) navigate("/maintenance/");
    } else {
      if (emailAddress !== "mohammadmajid581@gmail.com") {
        if (!location.pathname.includes("maintenance")) navigate("/maintenance/");
      }
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AuthWrapper;
