import FormControl from "@mui/material/FormControl";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import { useFormContext, Controller } from "react-hook-form";

import useIsLightMode from "src/hooks/useIsLightMode";

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, "control" | "label" | "onChange"> {
  name: string;
  label: string;
  options: { label: string; value: number }[];
  disabled: boolean;
  value: number[];
  onChange: (value: number) => void;
}

export function RHFMultiCheckbox({ name, label, options, onChange, value, ...other }: RHFMultiCheckboxProps) {
  const isLightMode = useIsLightMode();
  const { control } = useFormContext();

  const getSelected = (selectedItems: undefined | number[], item: number) => {
    if (selectedItems === undefined) {
      // initially nothing was selected, select it
      onChange && onChange(item);
      return [item];
    } else if (selectedItems.includes(item)) {
      // item was already selected, so keep it selected
      onChange && onChange(item);
      return [];
    }

    // item was not selected, so select it
    onChange && onChange(item);
    return [item];
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          {label && (
            <FormLabel
              sx={{
                typography: "body1",
                fontWeight: "bold",
                color: isLightMode ? "black" : "white",
              }}
            >
              {label}
            </FormLabel>
          )}

          <FormGroup
            sx={{
              "& .MuiFormControlLabel-root": {
                "&:not(:last-of-type)": {
                  mb: 0,
                },
              },
            }}
          >
            {options.map((option) => {
              return (
                <FormControlLabel
                  key={option.value}
                  {...other}
                  control={
                    <Radio
                      checked={value ? value.includes(option.value) : false}
                      onChange={() => field.onChange(getSelected(field.value, option.value))}
                    />
                  }
                  label={option.label}
                />
              );
            })}
          </FormGroup>

          {!!error && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : ""}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
