import { groupBy } from "lodash-es";

import ResourceModel from "src/models/ResourceModel";
import ResourceModelType from "src/models/ResourceModelType";
import DateService from "src/utils/dateService";

interface RenderResourceAccessesProps {
  resources: ResourceModel[];
}

const RenderResourceAccesses = ({ resources }: RenderResourceAccessesProps) => {
  const filterResources = (resources: ResourceModel[], type: ResourceModelType) => {
    return resources.filter((resource) => resource.type === type && resource.access?.userHasValidAccess);
  };

  const courses = filterResources(resources, ResourceModelType.course);
  const workshops = filterResources(resources, ResourceModelType.workshop);
  const mockPapers = filterResources(resources, ResourceModelType.mockPaper);
  const questionBank = filterResources(resources, ResourceModelType.questionBank);
  const foundationTrainingProgramme = filterResources(resources, ResourceModelType.foundationTrainingProgramme);
  const designatedSupervisorProgramme = filterResources(resources, ResourceModelType.designatedSupervisorProgramme);

  const coursesGroupedByAccessEnds = groupBy(courses, (resource) =>
    resource.access?.accessEnds ? DateService.formatEight(resource.access.accessEnds) : undefined
  );

  const hasNoResources =
    courses.length === 0 &&
    workshops.length === 0 &&
    mockPapers.length === 0 &&
    questionBank.length === 0 &&
    foundationTrainingProgramme.length === 0 &&
    designatedSupervisorProgramme.length === 0;

  return (
    <>
      {hasNoResources && <p>No remaining accesses.</p>}
      <RenderResourcesAccess title="Designated Supervisor Programme" resources={designatedSupervisorProgramme} />
      <RenderResourcesAccess title="Foundation Training Programme" resources={foundationTrainingProgramme} />
      <RenderCoursesGroupedByAccessEnds coursesGroupedByAccessEnds={coursesGroupedByAccessEnds} />
      <RenderResourcesAccess title="Workshops" resources={workshops} />
      <RenderResourcesAccess title="Mock Papers" resources={mockPapers} />
      <RenderResourcesAccess title="Question Bank" resources={questionBank} />
    </>
  );
};

export default RenderResourceAccesses;

const RenderCoursesGroupedByAccessEnds = ({
  coursesGroupedByAccessEnds,
}: {
  coursesGroupedByAccessEnds: Record<string, ResourceModel[]>;
}) => {
  return (
    <div>
      {Object.keys(coursesGroupedByAccessEnds).map((accessEnds, idx) => {
        const courses = coursesGroupedByAccessEnds[accessEnds];
        return (
          <div key={idx}>
            <strong>Courses Expiring on {accessEnds}:</strong>
            {courses.map((course, idx) => {
              return (
                <p key={idx} style={{ margin: 0 }}>
                  {`${idx + 1}. ${course.title}`}
                </p>
              );
            })}
            <br />
          </div>
        );
      })}
    </div>
  );
};

const RenderResourcesAccess = ({ title, resources }: { title: string; resources: ResourceModel[] }) => {
  if (resources.length === 0) {
    return null;
  }

  return (
    <div>
      <strong>{title}:</strong>
      <br />
      {resources.map((resource, idx) => {
        return (
          <div key={idx}>
            <span>{resource.title}: </span> <RenderSingleAccess resource={resource} />
          </div>
        );
      })}
      <br />
    </div>
  );
};

const RenderSingleAccess = ({ resource }: { resource: ResourceModel }) => {
  const access = resource.access;

  if (!access) {
    return <span>No access</span>;
  }

  if (access.accessEnds === null) {
    return <span>Access ends 2 days after you start</span>;
  }

  return <span>Access ends on {DateService.formatEight(access.accessEnds)}</span>;
};
