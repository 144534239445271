export const getActualAnswer = (
  isCalculation: boolean,
  answerUnit: string | null | undefined,
  actualAnswerId: string | number | undefined,
  possibleAnswers: { answerId: number; answerText: string }[] | undefined | null
) => {
  if (!actualAnswerId) throw new Error("actualAnswerId is required");

  if (isCalculation) {
    if (!answerUnit) throw new Error("answerUnit is required for calculation answers");
    if (answerUnit === "£") {
      return "£" + actualAnswerId.toString();
    }
    return actualAnswerId.toString() + answerUnit.toString();
  }

  if (!possibleAnswers) throw new Error("possibleAnswers is required for multiple choice answers");

  const foundAnswer = possibleAnswers.find((possibleAnswer) => possibleAnswer.answerId === actualAnswerId);
  if (!foundAnswer) throw new Error("actualAnswerId not found in possibleAnswers");

  return foundAnswer.answerText;
};
