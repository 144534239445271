import Link from "@mui/material/Link";
import { ListItemButtonProps } from "@mui/material/ListItemButton";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Iconify } from "src/components/Iconify";
import { ChildRoute, Route } from "src/utils/routes";

import { StyledNavItem } from "./styles";

type NavItemProps = ListItemButtonProps & {
  item: Route | ChildRoute;
  active: boolean;
  subItem: boolean;
  makeParentClickable?: boolean;
};

export const NavItemDesktop = forwardRef<HTMLDivElement, NavItemProps>((obj, ref) => {
  const { item, makeParentClickable, active, subItem, ...other } = obj;
  const { title, path } = item;

  const buttonPadding = subItem ? "0.75rem 0" : "1.25rem 0";

  const hasChildren = !!Object.prototype.hasOwnProperty.call(item, "children");

  const renderContent = (
    <StyledNavItem ref={ref} disableRipple subItem={subItem} active={active} {...other} sx={{ padding: buttonPadding }}>
      {title}

      {hasChildren && <Iconify width={16} icon="carbon:chevron-down" sx={{ ml: 1 }} />}
    </StyledNavItem>
  );

  // navbar item has children and attribute
  // makeParentClickable is true
  // so render it, and make it clickable
  if (hasChildren && makeParentClickable) {
    return (
      <Link component={RouterLink} to={path} color="inherit" underline="none">
        {renderContent}
      </Link>
    );
  }

  if (hasChildren) {
    return renderContent;
  }

  // Default
  return (
    <Link component={RouterLink} to={path} color="inherit" underline="none">
      {renderContent}
    </Link>
  );
});

NavItemDesktop.displayName = "NavItemDesktop";
