import { LinkProps } from "@mui/material/Link";
import Typography, { TypographyProps } from "@mui/material/Typography";
import React, { forwardRef } from "react";

import { useTypography } from "./useTypography";

type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline";

type IProps = TypographyProps & LinkProps;

interface TextMaxLineProps extends IProps {
  line?: number;
  persistent?: boolean;
  children: React.ReactNode;
  variant?: Variant;
}

const TextMaxLine = forwardRef<HTMLAnchorElement, TextMaxLineProps>(
  ({ variant = "body1", line = 2, persistent = false, children, sx, ...other }, ref) => {
    const { lineHeight } = useTypography(variant);

    const styles = {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: line,
      WebkitBoxOrient: "vertical",
      ...(persistent && {
        height: lineHeight * line,
      }),
      ...sx,
    } as const;

    return (
      <Typography ref={ref} variant={variant} sx={{ ...styles }} {...other}>
        {children}
      </Typography>
    );
  }
);

TextMaxLine.displayName = "TextMaxLine";

export default TextMaxLine;
