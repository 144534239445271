import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Image } from "src/components/Image";
import useResponsive from "src/hooks/useResponsive";

const AddOnItem = ({
  addOn,
}: {
  addOn: {
    title: string;
    cost: number;
    originalCost: number;
    checkoutDescription: string;
    image: string;
  };
}) => {
  const { title, cost, originalCost, checkoutDescription, image } = addOn;
  const isMobile = useResponsive("down", "md");

  return (
    <div
      style={{
        marginBottom: isMobile ? "2rem" : 0,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          pb: 1,
          minWidth: isMobile ? 300 : 500,
        }}
      >
        <Stack direction="row" alignItems="center" flexGrow={1} spacing={1}>
          <Image
            src={image}
            sx={{
              width: 80,
              height: 80,
              flexShrink: 0,
              borderRadius: 1.5,
              bgcolor: "background.neutral",
            }}
          />
          <Stack spacing={0.5} style={{ padding: "0 2rem 0 1rem" }}>
            <Typography variant="subtitle2">{title}</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {checkoutDescription}
            </Typography>
          </Stack>{" "}
        </Stack>
        {!isMobile && <Cost originalCost={originalCost} cost={cost} />}
      </Stack>
      {isMobile && <Cost originalCost={originalCost} cost={cost} />}
    </div>
  );
};

export default AddOnItem;

const Cost = ({ originalCost, cost }: { originalCost: number; cost: number }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="end" style={{ marginBottom: "1rem" }}>
      <Stack sx={{ typography: "subtitle2", pt: 1 }} direction="row" spacing={1.5}>
        {originalCost !== cost && <s>£{originalCost}</s>}
        <span>£{cost}</span>
      </Stack>
    </Stack>
  );
};
