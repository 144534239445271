import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useContext } from "react";

import imagePlaceholder from "src/assets/placeholder.jpeg";
import { RenderMarkdown } from "src/components_with_stories/qb_question/RenderMarkdown";
import { AccessContext } from "src/context/AccessContext";
import MockPaperModel from "src/models/MockPaperModel";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import RenderCost from "src/newComponents/RenderCost";

import QuestionSet from "./QuestionSet";

export default function MockPaper({ mockPaper }: { mockPaper: MockPaperModel }) {
  const { getResourceModelByResourceId } = useContext(AccessContext);
  const resourceModel = getResourceModelByResourceId(mockPaper.resourceId);

  const renderAccess = () => (
    <>
      <p style={{ marginTop: "0.25rem" }}>
        <strong>Thank you for your purchase. This mock paper is now available for you to attempt.</strong>
      </p>
      {mockPaper.questionSets.length > 1 && (
        <>
          <strong>Read these instructions carefully before you begin:</strong>
          <ol>
            <li>
              Start When Ready: Select 'Access' when you're ready to begin. Follow the on screen instructions to start.
            </li>
            <li>
              No Do-Overs: We can't reset the mock paper once you've started, so be careful. You can't stop, save,
              restart, change browsers or devices.
            </li>
            <li>One Attempt Only: You only get one chance to finish the mock paper.</li>
            <li>
              Be Prepared: Choose a quiet place with no interruptions, just like in the real exam. Have your calculator,
              pen, paper, and water nearby.
            </li>
            <li>
              Device Requirement: For the best experience access via using Chrome or Firefox using a Desktop or Laptop
              only.
            </li>
            <li>
              Review Time: After you complete the mock, you'll have 48 hours to look back at your answers and see the
              explanations. Use this time to learn from your mistakes.
            </li>
            <li>
              Any issues? Don't select "Access." Instead, view our FAQ section below or email us at{" "}
              <a href="mailto:info@preregshortcuts.com" className="faqPageLink" target="_blank" rel="noreferrer">
                info@preregshortcuts.com
              </a>
              .
            </li>
            <li>Sharing our mock paper is strictly prohibited.</li>
          </ol>
        </>
      )}
      <Grid container spacing={3}>
        {mockPaper.questionSets.map((questionSet) => (
          <Grid item xs={12} sm={6} key={questionSet.questionSetId}>
            <QuestionSet questionSet={questionSet} />
          </Grid>
        ))}
      </Grid>
    </>
  );

  return (
    <Card sx={{ "&:hover": { boxShadow: (theme) => theme.customShadows.z24 }, p: 2.5 }}>
      <div>
        <h3 style={{ marginBottom: "8px", fontSize: "1.75rem", marginTop: 0 }}>{mockPaper.title}</h3>
      </div>

      {resourceModel && (
        <div style={{ width: "150px", marginLeft: "1.25rem", marginBottom: "2.5rem" }}>
          <RenderCost resourceModel={resourceModel} />
        </div>
      )}

      <div style={{ margin: "2rem 0" }}>
        <img
          src={resourceModel?.image ?? imagePlaceholder}
          style={{
            maxWidth: "240px",
            maxHeight: "240px",
          }}
          alt={mockPaper.title}
          title={mockPaper.title}
          loading="eager"
          width={"100%"}
          height={"100%"}
        />
      </div>

      {resourceModel && <AddResourceToBasket resourceModel={resourceModel} renderAccess={renderAccess} />}
      <Typography variant="h4" sx={{ mt: 3.5 }}>
        Details
      </Typography>

      <RenderMarkdown text={mockPaper.description} />
    </Card>
  );
}
