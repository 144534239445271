// Add the Object.hasOwn polyfill
if (!Object.hasOwn) {
  Object.hasOwn = function (obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  };
}

import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./index.css";
import "./setup-axios-headers";
import "./setup-dayjs";
import { prismicClient, PrismicProvider } from "./setup-prismic";
import "./setup-sentry.ts";

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <PrismicProvider client={prismicClient}>
          <App />
        </PrismicProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
