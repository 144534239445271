import Grid from "@mui/material/Grid";

import WorkshopModel from "src/models/WorkshopModel";

import Workshop from "./Workshop";

export default function Workshops({ workshops }: { workshops: WorkshopModel[] }) {
  return (
    <Grid
      container
      spacing={3}
      sx={{
        mt: 2,
      }}
    >
      {workshops.map((workshop, idx) => (
        <Grid item xs={12} sm={6} key={idx}>
          <Workshop workshop={workshop} />
        </Grid>
      ))}
    </Grid>
  );
}
