import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Iconify } from "src/components/Iconify";
import Logo from "src/components/logo/Logo";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { Route } from "src/utils/routes";

import NavList from "./NavList";
import { getLoginPageUrl } from "../../header/Header";

export function NavMobile({
  leftRoutes,
  rightRoutes,
  isAuthenticated,
}: {
  leftRoutes: Route[];
  rightRoutes: Route[];
  isAuthenticated: boolean;
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ ml: 1, color: "inherit" }}>
        <Iconify icon="quill:hamburger" width={26} />
      </IconButton>

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 2,
            width: "80%", // width of the drawer
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <Stack spacing={1.5} direction="row" style={{ margin: "0 1.5rem 0.75rem 1rem" }}>
            {!isAuthenticated && (
              <Button
                fullWidth
                variant="outlined"
                color="inherit"
                onClick={() => {
                  const url = getLoginPageUrl(window.location.pathname);
                  navigate(url);
                }}
              >
                Login
              </Button>
            )}
            <Button fullWidth variant="contained" color="inherit" href={"/products/"}>
              Enrol Now
            </Button>
          </Stack>

          <List component="nav" disablePadding>
            {leftRoutes.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
            <hr
              style={{
                borderTop: "1px solid lightgrey",
                margin: "10px 0px 5px 0px",
              }}
            />
            {rightRoutes.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>

          <hr
            style={{
              borderTop: "1px solid lightgrey",
              margin: "5px 0px 0px 0px",
            }}
          />
        </Scrollbar>
      </Drawer>
    </>
  );
}
