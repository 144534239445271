import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { FormProvider } from "src/components/hook-form/FormProvider";
import { RHFTextField } from "src/components/hook-form/RHFTextField";
import { errorToast } from "src/components_with_stories/toast";
import { AppContext } from "src/context/AppContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import { twoFactorAuthEndpoint, userDetailsEndpoint } from "src/urls";

export default function TwoFactorAuthForm() {
  const isLightMode = useIsLightMode();
  const [isDisabled, setIsDisabled] = useState(false);

  const { updateUserDetails } = useContext(AppContext);
  const navigate = useNavigate();

  const search = window.location.href.split("?")[1];
  const queryParameters = new URLSearchParams(search);
  const nextPage = queryParameters.get("next");
  const Schema = Yup.object().shape({
    otp: Yup.string()
      .label("Verification code")
      .required()
      .matches(/^[0-9]+$/, "Enter the 6 digit code.")
      .min(6, "Enter the 6 digit code.")
      .max(6, "Enter the 6 digit code."),
  });

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      otp: "",
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text");
    const numbersOnly = pastedText.replace(/[^0-9]/g, "");
    setValue("otp", numbersOnly, { shouldValidate: true });
  };

  const onSubmit = async (data: { otp: string }) => {
    axios
      .post(twoFactorAuthEndpoint, { code: data.otp })
      .then(() => {
        reset();

        axios
          .get(userDetailsEndpoint)
          .then((response) => {
            const id = response.data.id;
            const email = response.data.email;
            const name = response.data.name;
            const phone = response.data.phone;
            const isSuperuser = response.data.is_superuser;
            updateUserDetails(id, email, name, phone, isSuperuser, false);

            if (nextPage) {
              navigate(`/${nextPage}`);
            } else {
              navigate("/");
            }
          })
          .catch((error) => {
            errorToast(
              "Failed to authenticate 3/3, please follow all the steps in the support guide on the login page and try again.",
              isLightMode
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
            console.log(error);
          });
      })
      .catch((error) => {
        if (error.response?.data?.error) {
          const message = error.response.data.error;
          const disabledForSeconds = error.response.data?.remaining;
          if (disabledForSeconds) {
            setIsDisabled(true);
            setTimeout(() => {
              setIsDisabled(false);
            }, disabledForSeconds * 1000);
          }
          errorToast(message, isLightMode);
          return;
        }
        const message =
          "Failed to authenticate 2/3, please follow all the steps in the support guide on the login page and try again.";
        errorToast(message, isLightMode);
        console.log(error);
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} styles={{ marginTop: 0 }}>
      <RHFTextField
        hasFocus={false}
        name="otp"
        label="Enter Verification Code"
        tabIndex={1}
        onPaste={handlePaste}
        type="number"
        onKeyDown={(e) => {
          const invalidChars = ["e", "E", "+", "-", "."];
          if (invalidChars.includes(e.key)) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          e.preventDefault();
          const value = e.target.value.toString();
          if (value.length >= 6) {
            const firstSix = value.slice(0, 6);
            setValue("otp", firstSix);
          } else {
            setValue("otp", value);
          }
        }}
      />

      <LoadingButton
        fullWidth
        size="large"
        color="inherit"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        disabled={isSubmitting || isDisabled}
        sx={{ mt: 2.5 }}
        tabIndex={2}
      >
        Verify
      </LoadingButton>
    </FormProvider>
  );
}
