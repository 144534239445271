import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext } from "react";

import { AccessContext } from "src/context/AccessContext";
import FeaturedProduct from "src/pages/courses/components/FeaturedProduct";

const LandingPageFeaturedCourses = () => {
  const { resources } = useContext(AccessContext);

  const featuredResources = resources?.filter((resource) => resource.featuredProductOrder);
  const sortedFeaturedResources = featuredResources?.sort((a, b) => {
    // the filtering above ensures that the featuredProductOrder is not undefined
    // the || 0 is just a fallback in case it is undefined
    const aOrder = a.featuredProductOrder || 0;
    const bOrder = b.featuredProductOrder || 0;
    return aOrder - bOrder;
  });

  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={{ md: "flex-end" }}
        sx={{
          textAlign: { xs: "center", md: "unset" },
        }}
      >
        <Stack spacing={3} flexGrow={1}>
          <Typography variant="h2" textAlign={"center"}>
            Featured Products
          </Typography>
          <Typography sx={{ color: "text.secondary" }} textAlign={"center"}>
            View our all in one Foundation Pharmacist Training Programme and exam revision courses suitable for Trainees
            & Pharmacy students.
          </Typography>
        </Stack>
      </Stack>

      {sortedFeaturedResources && (
        <Grid container spacing={4} sx={{ mt: 1 }}>
          {sortedFeaturedResources.map((resourceModel) => {
            return (
              <Grid item key={resourceModel.id} xs={12} sm={6} md={4}>
                <FeaturedProduct resourceModel={resourceModel} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default LandingPageFeaturedCourses;
