import LearnMoreButtonWithModal from "src/newComponents/buttons/LearnMoreButtonWithModal";

const MoneyBackGuaranteeButton = ({
  renderAsButton = true,
  title = "Full Money Back Guarantee",
}: {
  renderAsButton?: boolean;
  title?: string;
}) => {
  return (
    <LearnMoreButtonWithModal
      title={title}
      renderAs={renderAsButton ? "button" : "span"}
      content={
        <>
          <p style={{ marginTop: 0 }}>Every year we have an outstanding pass rate, 95% in the most recent GPhC exam.</p>
          <p>
            We're that confident you'll pass the GPhC exam on our all in one programme, if you don't, we have a full
            money back guarantee / free extension. Applicable to the all in one programme only.
          </p>
          <p>
            Eligibility: You must show there is an unsuccessful exam attempt in June / November 2025 GPhC exam. You can
            then request a free extension to the next GPhC exam or a refund (see policy below).
          </p>
          <p>The refund policy applies as follows, unsuccessful in: </p>
          <ul>
            <li>Calculations paper part 1 only: £350 refund</li>
            <li>Clinical paper part 2 only: £350 refund </li>
            <li>Unsuccessful in both parts of the GPhC exam: full £700 refund </li>
          </ul>

          <p>We will give you a full refund, minus our VAT cost £199 (which we are not refunded after you enrol).</p>
          <p>All refunds or free extensions must be requested within 2 weeks of receiving your GPhC exam results.</p>
          <p style={{ marginBottom: 0 }}>
            For more information visit our{" "}
            <a href="/terms-and-conditions/" className="faqPageLink" target="_blank" rel="noreferrer">
              T&C's
            </a>{" "}
            or contact our support team.
          </p>
        </>
      }
      buttonText="Full Money Back Guarantee"
      contained={true}
      size="medium"
    />
  );
};

export default MoneyBackGuaranteeButton;
