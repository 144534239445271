import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Iconify } from "src/components/Iconify";

export function TestimonialItemContent({
  testimonial,
}: {
  testimonial: {
    id: number;
    name: string;
    rating: number;
    reviewTitle: string;
    review: string;
    link: string;
  };
}) {
  return (
    <Stack alignItems="center">
      <Iconify icon="carbon:quotes" sx={{ width: 42, height: 42, opacity: 0.48, color: "primary.main" }} />

      <Typography variant="h6" sx={{ mt: 2 }}>
        {testimonial.reviewTitle}
      </Typography>

      <Typography
        sx={{
          mt: 2,
          lineHeight: 1.75,
          fontSize: { xs: 16, md: 16 },
          fontFamily: (theme) => theme.typography.h1.fontFamily,
        }}
      >
        {testimonial.review.slice(0, 220)}
        <>
          ...{" "}
          <a href={testimonial.link} className="faqPageLink" target="_blank" rel="noreferrer">
            read more
          </a>
        </>
      </Typography>
      <Stack key={testimonial.id} spacing={0.5} sx={{ mt: 3 }}>
        <Typography variant="h6">{testimonial.name}</Typography>
      </Stack>
    </Stack>
  );
}
