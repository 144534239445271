import { OurCoursel } from "src/components/OurCarousel";
import PreviewLinkButton from "src/newComponents/buttons/PreviewLinkButton";
import MyCard from "src/pages/products/MyCard";

import { ProductSection } from "../FoundationTrainingProgrammePage";

export const Tab = ({ section }: { section: ProductSection }) => {
  const { title, images, button } = section;

  const elements = images.map((image, idx) => <img key={idx} src={image} alt={title} title={title} loading="eager" />);

  return (
    <MyCard customStyle={{ padding: "0" }}>
      <OurCoursel elements={elements} autoPlay={false} />

      <div style={{ padding: "1rem" }}>
        <h3 style={{ textAlign: "center", marginTop: 0 }}>{title}</h3>
        {button && (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
            <PreviewLinkButton link={button.link} label={button.label} openInSameTab={true} />
          </div>
        )}
      </div>
    </MyCard>
  );
};
