import Button from "@mui/material/Button";
import { merge } from "lodash-es";
import React, { useState } from "react";

import MoreInformationModal from "../MoreInformationModal";

interface LearnMoreButtonWithModalProps {
  title: string;
  content: React.ReactNode;
  rightMargin?: boolean;
  buttonText?: string;
  contained?: boolean;
  size?: "small" | "medium";
  renderAs?: "button" | "span";
}

const LearnMoreButtonWithModal = ({
  title,
  content,
  rightMargin,
  buttonText = "Learn More",
  contained = false,
  size = "small",
  renderAs = "button",
}: LearnMoreButtonWithModalProps) => {
  const [open, setOpen] = useState(false);

  const style = merge(
    renderAs === "span" ? { cursor: "pointer", textDecoration: "underline" } : {},
    rightMargin ? { marginRight: "1rem" } : {}
  );
  const Component = renderAs === "button" ? Button : "span";

  return (
    <>
      <Component
        variant={contained ? "contained" : "outlined"}
        color="secondary"
        style={style}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}
        size={size}
        className={renderAs === "span" ? "faqPageLink" : ""}
      >
        {buttonText}
      </Component>
      {open && <MoreInformationModal title={title} content={content} handleClose={() => setOpen(false)} />}
    </>
  );
};

export default LearnMoreButtonWithModal;
