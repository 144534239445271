import BasketItemModel from "src/models/BasketItemModel";
import ResourceModelType from "src/models/ResourceModelType";

export const getConflictingItems = (items: BasketItemModel[]) => {
  const containsFoundationTrainingProgramme = items.some(
    (item) => item.resourceModelType === ResourceModelType.foundationTrainingProgramme
  );
  if (!containsFoundationTrainingProgramme) {
    return [];
  }

  const itemsWithoutProgramme = items.filter(
    (item) => item.resourceModelType !== ResourceModelType.foundationTrainingProgramme
  );

  const conflictingItems = itemsWithoutProgramme.filter((item) => item.includedInPremium);
  return conflictingItems;
};
