import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useContext } from "react";

import imagePlaceholder from "src/assets/placeholder.jpeg";
import { RenderMarkdown } from "src/components_with_stories/qb_question/RenderMarkdown";
import { AccessContext } from "src/context/AccessContext";
import WorkshopModel from "src/models/WorkshopModel";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import RenderCost from "src/newComponents/RenderCost";
import RenderTutor from "src/newComponents/RenderTutor";
import ZoomButton from "src/newComponents/ZoomButton";
import DateService, { DateType } from "src/utils/dateService";

const Workshop = ({ workshop }: { workshop: WorkshopModel }) => {
  const { workshopId, tutorObj, title, description, start, end, zoomLink } = workshop;

  const { getResourceModelByResourceId } = useContext(AccessContext);
  const resourceModel = getResourceModelByResourceId(workshop.resourceId);

  const [date, time] = renderStartAndEndDate(start, end);

  const renderAccess = () => (
    <>
      <p style={{ marginTop: "0.25rem" }}>
        <strong>Thank you for your purchase, click the Zoom button below to register for the workshop.</strong>
      </p>
      <ZoomButton href={zoomLink} buttonText={"Register For Workshop"} />
    </>
  );

  return (
    <Card
      key={workshopId}
      sx={{
        "&:hover": {
          boxShadow: (theme) => theme.customShadows.z24,
        },
        p: 2.5,
      }}
    >
      <div>
        <h3 style={{ marginBottom: "8px", fontSize: "1.75rem", marginTop: 0 }}>{title}</h3>

        {resourceModel && (
          <div style={{ width: "150px", marginLeft: "1rem" }}>
            <RenderCost resourceModel={resourceModel} />
          </div>
        )}
      </div>

      <div style={{ marginTop: "2rem" }}>
        <img
          src={resourceModel?.image ?? imagePlaceholder}
          style={{
            maxWidth: "240px",
            maxHeight: "240px",
          }}
          alt={title}
          title={title}
          loading="eager"
          width={"100%"}
          height={"100%"}
        />
      </div>

      <Divider sx={{ mt: 3 }} style={{ borderColor: "transparent" }} />

      {resourceModel && <AddResourceToBasket resourceModel={resourceModel} renderAccess={renderAccess} />}

      <Divider sx={{ mt: 1 }} style={{ borderColor: "transparent" }} />

      <Typography variant="h4" sx={{ mt: 2.5 }}>
        Details
      </Typography>
      <p>
        <strong>Date</strong>: {date}
        <br />
        <strong>Time</strong>: {time}
      </p>
      <RenderMarkdown text={description} />

      <Divider sx={{ mt: 2.5 }} style={{ borderColor: "transparent" }} />
      <RenderTutor tutor={tutorObj} />
    </Card>
  );
};

export default Workshop;

const renderStartAndEndDate = (start: DateType, end: DateType) => {
  const startDay = DateService.formatEight(start);
  const endDay = DateService.formatEight(end);
  const startTime = DateService.formatThree(start);
  const endTime = DateService.formatThree(end);

  const sameDay = startDay === endDay;
  if (sameDay) {
    return [startDay, `${startTime} - ${endTime}`];
  }
  return [`${startDay} ${startTime}`, `${endDay} ${endTime}`];
};
