export type Bank = "Calculations" | "Clinical Mixed" | "Clinical OTC" | "Clinical MEP" | "Clinical Therapeutics";

export const calculationsBank: Bank = "Calculations";
export const clinicalMixedBank: Bank = "Clinical Mixed";
export const clinicalOtcBank: Bank = "Clinical OTC";
export const clinicalMepBank: Bank = "Clinical MEP";
export const clinicalTherapeuticsBank: Bank = "Clinical Therapeutics";

const getBankName = (bank: string) => {
  const bank_lower_case = bank.replace(" ", "").toLowerCase();
  if (bank_lower_case === "calculations") return calculationsBank;
  if (bank_lower_case === "clinicalmixed") return clinicalMixedBank;
  if (bank_lower_case === "clinicalotc") return clinicalOtcBank;
  if (bank_lower_case === "clinicalmep") return clinicalMepBank;
  if (bank_lower_case === "clinicaltherapeutics") return clinicalTherapeuticsBank;
  return bank;
};

export default getBankName;
