import Typography from "@mui/material/Typography";
import React, { useState } from "react";

import CourseLessonProgressModel from "src/models/CourseLessonProgressModel";
import LessonModel from "src/models/LessonModel";

import LessonItem from "./LessonItem";
import LessonsDialog from "./LessonsDialog";

interface LessonListProps {
  lessons: LessonModel[];
  simplifiedView: boolean;
  updateLessonProgress?: (lessonProgress: CourseLessonProgressModel) => void;
}

export default function LessonList({ lessons, simplifiedView, updateLessonProgress }: LessonListProps) {
  const [selectedLesson, setSelectedLesson] = useState<LessonModel | null>(null);
  const [isDescExpanded, setIsDescExpanded] = useState<false | number>(false);

  const isPlaying = selectedLesson !== null;

  const handleExpanded = (lessonId: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setIsDescExpanded(isExpanded ? lessonId : false);
  };

  return (
    <>
      {!simplifiedView && (
        <Typography variant="h6" sx={{ mb: 1 }}>
          Lessons
        </Typography>
      )}

      {lessons?.map((lesson) => (
        <LessonItem
          key={lesson.id}
          lesson={lesson}
          selected={isPlaying && selectedLesson && selectedLesson.id === lesson.id}
          expanded={isDescExpanded === lesson.id}
          onExpanded={handleExpanded(lesson.id)}
          onOpen={() => setSelectedLesson(lesson)}
          simplifiedView={simplifiedView}
        />
      ))}

      {selectedLesson && (
        <LessonsDialog
          open={selectedLesson !== null}
          onClose={() => setSelectedLesson(null)}
          selectedLesson={selectedLesson}
          updateLessonProgress={updateLessonProgress}
        />
      )}
    </>
  );
}
