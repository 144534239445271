import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Image } from "src/components/Image";
import { RenderMarkdown } from "src/components_with_stories/qb_question/RenderMarkdown";
import useResponsive from "src/hooks/useResponsive";
import ResourceModel from "src/models/ResourceModel";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import ResourceCost from "src/newComponents/ResourceCost";
import { resourceCanBePurchased } from "src/repository/resourceRepository";

const ProductItem = ({ resourceModel }: { resourceModel: ResourceModel }) => {
  const isMobile = useResponsive("down", "md");

  return (
    <div
      style={{
        marginBottom: isMobile ? "2rem" : 0,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          pb: 1,
          minWidth: isMobile ? 300 : 500,
        }}
      >
        <Stack direction="row" alignItems="center" flexGrow={1} spacing={1}>
          <Image
            src={resourceModel.image}
            sx={{
              width: 80,
              height: 80,
              flexShrink: 0,
              borderRadius: 1.5,
              bgcolor: "background.neutral",
            }}
          />

          <Stack spacing={0.5} sx={{ p: 2 }}>
            <Typography variant="subtitle2">{resourceModel.title}</Typography>

            <RenderMarkdown text={resourceModel.checkoutDescription} asSecondaryBody2={true} compact={true} />
          </Stack>
        </Stack>{" "}
        {!isMobile && <Cost resourceModel={resourceModel} />}
      </Stack>
      {isMobile && <Cost resourceModel={resourceModel} />}
    </div>
  );
};

export default ProductItem;

const Cost = ({ resourceModel }: { resourceModel: ResourceModel }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="end" style={{ marginBottom: "1rem" }}>
      {resourceCanBePurchased(resourceModel) && (
        <ResourceCost cost={resourceModel.cost} originalCost={resourceModel.originalCost} paddingTop={true} />
      )}
      <AddResourceToBasket resourceModel={resourceModel} showAccessButton={true} />
    </Stack>
  );
};
