import Link, { LinkProps as MUILinkProps } from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

type StyledLinkProps = Omit<MUILinkProps, "href" | "component"> & Pick<RouterLinkProps, "to" | "replace" | "state">;

export const StyledLink = styled((props: StyledLinkProps) => <Link component={RouterLink} {...props} />)(({
  theme,
}) => {
  return {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  };
});
