import klarnaLogo from "src/assets/klarna.png";
import { NewTabLink } from "src/components/NewTabLink";
import EnrolNowButton from "src/newComponents/buttons/EnrolNowButton";

const HowItWorksContent = () => {
  return (
    <div>
      <h3>Who can benefit from Pre-Reg Shortcuts?</h3>

      <p>Everyone in Pharmacy including:</p>
      <ul>
        <li>Trainee Pharmacists: GPhC exam revision</li>
        <li>Pharmacy students: University exam revision</li>
        <li>Pharmacists: Refresher course</li>
      </ul>

      <p>
        At Pre Reg Shortcuts, all the content we offer is mapped to the GPhC framework and up to date. If you're looking
        for interactive content to make your exam revision easier, you're in in the perfect place!
      </p>

      <p>
        Pre-Reg Shortcuts offers all the popular courses and features you need on our user friendly platform, such as{" "}
        <NewTabLink href="/courses/">Exam Revision Courses</NewTabLink>,{" "}
        <NewTabLink href="/question-bank/">Question Bank</NewTabLink>,{" "}
        <NewTabLink href="/mock-paper/">Mock Paper</NewTabLink> and <NewTabLink href="/products/">more</NewTabLink>.
      </p>

      <h3>Which programme / exam revision courses should I choose?</h3>

      <p>
        Interested in joining and not sure what's suitable for you? Follow our guide below or contact our support team
        to discuss.
      </p>

      <p>
        Trainee Pharmacists: If you're looking for the complete programme, with everything you need, then our{" "}
        <NewTabLink href="/foundation-training-programme/">Foundation Pharmacist Training Programme</NewTabLink> is for
        you.
      </p>

      <p>
        Alternatively if you'd like to join specific courses only, designed for study in your own time, then you can
        enrol separately and select your courses under Exam Revision Courses & Workshops{" "}
        <NewTabLink href="/products/">here</NewTabLink>.
      </p>

      <p>
        Pharmacy students and Pharmacists: View our Exam Revision Courses & Careers Workshops{" "}
        <NewTabLink href="/products/">here</NewTabLink>.
      </p>

      <h3>Monthly instalment options</h3>

      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <img src={klarnaLogo} alt="Klarna" title="Klarna" style={{ width: "70px", height: "39px" }} loading="eager" />
        <p>
          We now offer monthly instalment options with no deposit and no interest, so you can access your purchase
          straight away and split your payments over 12 months.{" "}
          <NewTabLink href="https://www.klarna.com/uk/terms-and-conditions/">Klarna T&C's</NewTabLink> apply, for more
          information contact our support team.
        </p>
      </div>

      <h3>Enrolment process</h3>

      <p>
        Interested in enrolling with Pre Reg Shortcuts? Create your account and enrol on our programme / exam revision
        courses follow the steps below:
      </p>

      <p>
        <strong>Step 1:</strong> Browse our entire range of products <NewTabLink href="/products/">here</NewTabLink>,
        you can view our Foundation Pharmacist Training Programme and Exam Revision Courses.
      </p>

      <p>
        <strong>Step 2:</strong> Add your selected programme / courses to basket, then select proceed to payment and
        follow the on screen instructions to create your login details.
      </p>
      <p>
        <strong>Step 3:</strong> Complete purchase and follow the email instructions to create your password, then login
        to your account.
      </p>
      <p>
        <strong>Step 4:</strong> After you login in, visit 'Programme' on our platform, and your purchases will unlock
        in your account automatically. Access duration to your account lasts for 365 days from the day you enrol.
      </p>

      <p>
        Reminder: You can now enrol with preregshortcuts with monthly instalment options with no deposit and no
        interest, full access to your account straight away after enrolment, and split your payments over 12 months.
        Select Klarna at the checkout to view instalment options available to you.{" "}
        <NewTabLink href="https://www.klarna.com/uk/terms-and-conditions/">Klarna T&C's</NewTabLink> apply, for more
        info contact our support team
      </p>

      <EnrolNowButton />

      <h3>Telegram support group (Lifetime Access)</h3>
      <p>
        Available to any Trainees who enrol on our{" "}
        <NewTabLink href="/foundation-training-programme/">Foundation Pharmacist Training Programme</NewTabLink> only,
        you will receive email instructions on how to join after you enrol.
      </p>

      <h3>Current discounts available</h3>
      <p>
        £200 discount on the Foundation Pharmacist Training Programme until the 30th September 2024! This discount is
        automatically applied at the checkout.
      </p>

      <h3>Technical issues</h3>
      <p>
        Our platform is created with top of the range technology. However, if you experience any issues. Follow our{" "}
        <NewTabLink href="https://drive.google.com/file/d/1ScHxbGhPLUDvq5MqbGgBZkmFFvtrDOr6/view?usp=sharing">
          Support Guide
        </NewTabLink>{" "}
        and follow the steps to regain access to your account straight away.
      </p>
      <p>If you've followed all the steps and you can't gain access contact our support team!</p>
    </div>
  );
};

export default HowItWorksContent;
