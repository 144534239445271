import useActiveLink from "src/hooks/useActiveLink";
import { ChildRoute } from "src/utils/routes";

import NavItemMobile from "./NavItemMobile";

export default function NavListMobile({ data }: { data: ChildRoute }) {
  const active = useActiveLink(data.path);

  return <NavItemMobile item={data} active={active} />;
}
