import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { confirmToast } from "src/components_with_stories/toast";
import { BasketContext } from "src/context/BasketContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import useResponsive from "src/hooks/useResponsive";

import PageTemplate from "../PageTemplate";
import CartItemsDesktop from "./components/CartItemsDesktop";
import CartItemsMobile from "./components/CartItemsMobile";
import CartSummary from "./components/CartSummary";
import { GPHCBenefits } from "./components/GPHCBenefits";
import { getConflictingItems } from "./utils/getConflictingItems";
import ReviewsSPC from "../landing/components/ReviewsSPC";

const CheckoutPage = () => {
  const { items, forceRemoveItemsFromBasket, removeItemFromBasketOnConfirm } = useContext(BasketContext);

  const isMobile = useResponsive("down", "md");
  const isLightMode = useIsLightMode();

  const basketHasItems = items.length > 0;

  useEffect(() => {
    const conflictingItems = getConflictingItems(items);

    if (conflictingItems.length > 0) {
      const message = (
        <>
          <p style={{ marginTop: 0 }}>
            The following items are already included in the Foundation Training Programme. Would you like to remove them
            from your basket?
          </p>
          <ul>
            {conflictingItems.map((item) => (
              <li key={item.stripePriceId}>{item.name}</li>
            ))}
          </ul>
        </>
      );

      confirmToast({
        message: message,
        isLightMode: isLightMode,
        onConfirm: () => {
          forceRemoveItemsFromBasket(conflictingItems);
        },
        confirmText: "Remove",
      });
    }
  }, []);

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/checkout/" }]}>
        <title>Checkout | Pre-Reg Shortcuts</title>
        <meta name="description" content="Secure checkout page. Complete your purchase with confidence using Stripe." />
        <meta name="robots" content="noindex" />
      </Helmet>
      <PageTemplate title="Checkout">
        {!basketHasItems && (
          <div style={{ margin: "2rem 0" }}>
            It looks like your basket is empty. Check out{" "}
            <a href="/products/" className="faqPageLink">
              our products
            </a>
            .
          </div>
        )}

        {basketHasItems && (
          <Grid container spacing={{ xs: 5, md: 8 }}>
            <Grid item xs={12} md={8}>
              {isMobile ? (
                <CartItemsMobile items={items} removeItemFromBasketOnConfirm={removeItemFromBasketOnConfirm} />
              ) : (
                <CartItemsDesktop items={items} removeItemFromBasketOnConfirm={removeItemFromBasketOnConfirm} />
              )}
              <div style={{ marginTop: "2rem" }}>
                <GPHCBenefits />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <CartSummary items={items} isMobile={isMobile} />
            </Grid>
          </Grid>
        )}

        <Divider sx={{ mt: 6 }} style={{ borderColor: "transparent" }} />
        <ReviewsSPC />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
      </PageTemplate>
    </>
  );
};

export default CheckoutPage;
