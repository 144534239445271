import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { Link as RouterLink } from "react-router-dom";

import placeholderImage from "src/assets/placeholder.jpeg";
import { Image } from "src/components/Image";
import TextMaxLine from "src/components/text-max-line/TextMaxLine";
import { RenderMarkdown } from "src/components_with_stories/qb_question/RenderMarkdown";
import ResourceModel from "src/models/ResourceModel";
import { resourceModelTypeToRouteMap } from "src/models/ResourceModelType";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import ResourceCost from "src/newComponents/ResourceCost";
import { resourceCanBePurchased } from "src/repository/resourceRepository";

export default function FeaturedProduct({ resourceModel }: { resourceModel: ResourceModel }) {
  const title = resourceModel.title;

  const page = `/${resourceModelTypeToRouteMap[resourceModel.type]}/`;

  return (
    <Link component={RouterLink} to={page} color="inherit" sx={{ "&:hover": { textDecoration: "none" } }}>
      <Card
        sx={{
          display: { sm: "flex" },
          "&:hover": { boxShadow: (theme) => theme.customShadows.z24 },
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexShrink: { sm: 0 } }}>
          <Image
            alt={title}
            src={resourceModel?.image ?? placeholderImage}
            sx={{ height: 1, objectFit: "cover", width: { sm: 1 } }}
            loading="eager"
          />
        </Box>

        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack spacing={{ xs: 3, sm: 2.5 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
              <TextMaxLine variant="h6" line={1}>
                {title}
              </TextMaxLine>
            </Stack>

            <RenderMarkdown text={resourceModel.checkoutDescription} asSecondaryBody2={true} compact={true} />
          </Stack>

          {resourceCanBePurchased(resourceModel) && (
            <>
              <Divider sx={{ borderStyle: "dashed", display: "block" }} />
              <Stack
                direction={{ xs: "column", md: "row" }}
                flexWrap="wrap"
                sx={{
                  color: "text.disabled",
                  "& > *:not(:last-child)": { mr: 2.5 },
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1.5} sx={{ mt: { xs: 1, md: 0 } }}>
                  <ResourceCost
                    cost={resourceModel.cost}
                    originalCost={resourceModel.originalCost}
                    paddingTop={false}
                  />
                  <AddResourceToBasket resourceModel={resourceModel} />
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </Card>
    </Link>
  );
}
