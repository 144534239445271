import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";

import { RHFTextField } from "src/components/hook-form/RHFTextField";
import { Iconify } from "src/components/Iconify";

interface PasswordFieldProps {
  name: string;
  label: string;
  hasFocus: boolean;
  tabIndex1: number;
  tabIndex2: number;
}

const PasswordField = ({ name, label, hasFocus, tabIndex1, tabIndex2 }: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <RHFTextField
      name={name}
      label={label}
      hasFocus={hasFocus}
      type={showPassword ? "text" : "password"}
      tabIndex={tabIndex1}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword} edge="end" tabIndex={tabIndex2}>
              <Iconify icon={showPassword ? "carbon:view" : "carbon:view-off"} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
