const PrivacyPolicyContent = () => {
  return (
    <>
      <div>
        <ol>
          <Section
            heading="Introduction:"
            points={[
              "This privacy policy outlines how we collect, use, disclose, and protect your personal information when you use our website and e-learning platforms",
              "By accessing or using our platform, you consent to the collection and processing of your personal information as described in this policy.",
            ]}
          />

          <Section
            heading="Information We Collect:"
            points={[
              "Personal Information: We may collect personal information such as your name, email address, contact information, and payment details when you create an account or make a purchase on our platform.",
              "Usage Information: We may collect information about your interactions with the platform, including your course progress, quiz results, and browsing activity.",
              "Device Information: We may collect information about the device you use to access the platform, such as your IP address, browser type, and operating system.",
            ]}
          />

          <Section
            heading="Use of Information:"
            points={[
              "We use the collected information to provide and improve our e-learning services, personalize your learning experience, and communicate with you regarding your account and course updates.",
              "We may use your email address to send you promotional and marketing communications, but you can opt out of receiving such communications at any time.",
              "We may use aggregated and anonymised data for statistical analysis, research, and platform enhancement purposes.",
            ]}
          />

          <Section
            heading="Disclosure of Information:"
            points={[
              "We may share your personal information with trusted third-party service providers who assist us in delivering our services, such as payment processors and hosting providers.",
              "We may disclose your information if required by law or in response to a valid legal request, such as a court order or government investigation.",
              "In the event of a merger, acquisition, or sale of our business, your personal information may be transferred to the acquiring entity.",
            ]}
          />

          <Section
            heading="Data Security:"
            points={[
              "We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.",
              "However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.",
            ]}
          />

          <Section
            heading="Data Retention:"
            points={[
              "We retain your personal information for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law.",
              "You may request the deletion of your account and personal information, subject to any legal obligations or legitimate business interests.",
            ]}
          />

          <Section
            heading="Children's Privacy:"
            points={[
              "Our e-learning platform is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected such information, please contact us to have it removed.",
            ]}
          />
          <Section
            heading="Third-Party Links:"
            points={[
              "Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of those third parties. We encourage you to review the privacy policies of any third-party sites you visit.",
            ]}
          />
          <Section
            heading="Changes to the Privacy Policy:"
            points={[
              "We may update this privacy policy from time to time. Any changes will be posted on our website or emailed, and the revised policy will be effective immediately upon posting.",
            ]}
          />
          <Section
            heading="Your Choices:"
            points={[
              "You have the right to access, update, correct, or delete your personal information. You can do this by logging into your account or contacting us at info@preregshortcuts.com. You can also opt out of receiving marketing communications from us by following the unsubscribe instructions provided in our emails.",
            ]}
          />
          <Section
            heading="Contact Us:"
            points={[
              "If you have any questions or concerns about our privacy policy or the handling of your personal information, please contact us using the provided contact information: info@preregshortcuts.com",
            ]}
          />
        </ol>
        <br />
        <b>
          By using our website and e-learning platforms, you acknowledge that you have read, understood, and agreed to
          the terms of this privacy policy.
        </b>
        <br />
        <br />
      </div>
      <h2>Cookies Policy</h2>
      <p>
        At Pre-Reg Shortcuts, we use cookies to enhance your browsing experience and improve our services. Below, we
        outline the types of cookies we employ and why:
      </p>
      <p>Necessary Cookies:</p>
      <ul>
        <li>These cookies are essential for the functioning of our website.</li>
        <li>They include settings and preferences such as the dark mode toggle.</li>
        <li>Necessary cookies cannot be disabled through our cookies banner and are always required.</li>
      </ul>
      <p>Analytics Cookies (Optional):</p>
      <ul>
        <li>We use Google Analytics to gather insights into how users navigate our site.</li>

        <li>The purpose is to enhance performance and understand user behavior.</li>
        <li>Users have the option to disable analytics cookies through our cookies banner.</li>
      </ul>
      <p>
        Rest assured, we do not collect or process any personal data through our cookies. Your privacy and user
        experience are important to us. If you have any questions or concerns about our cookie policy, please don't
        hesitate to contact us.
      </p>{" "}
    </>
  );
};

export default PrivacyPolicyContent;

const Section = ({ heading, points }: { heading: string; points: string[] }) => {
  return (
    <li>
      <h4>{heading}</h4>
      <ol style={{ listStyle: "lower-alpha" }}>
        {points.map((point, idx) => {
          return <li key={idx}>{point}</li>;
        })}
      </ol>
    </li>
  );
};
