import Box from "@mui/material/Box";
import React, { memo } from "react";

import { StyledRootScrollbar, StyledScrollbar } from "./styles";

function Scrollbar({ children }: React.PropsWithChildren) {
  const userAgent = typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return <Box sx={{ overflowX: "auto" }}>{children}</Box>;
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar clickOnTrack={false}>{children}</StyledScrollbar>
    </StyledRootScrollbar>
  );
}

export default memo(Scrollbar);
