import Link from "@mui/material/Link";
import ListItemText from "@mui/material/ListItemText";
import { Link as RouterLink } from "react-router-dom";

import { Route } from "src/utils/routes";

import { StyledNavItem } from "./styles";

export default function NavItem({ item, active }: { item: Route; active: boolean }) {
  const { title, path } = item;

  const renderContent = (
    <StyledNavItem active={active}>
      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );

  return (
    <Link component={RouterLink} to={path} underline="none">
      {renderContent}
    </Link>
  );
}
