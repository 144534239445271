import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { errorToast, successToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import { confirmPasswordResetEndpoint } from "src/urls";

import WelcomeBackgroundView from "./components/WelcomeBackgroundView";

const WelcomePage = ({ isSetNewPasswordPage }: { isSetNewPasswordPage: boolean }) => {
  const isLightMode = useIsLightMode();

  const search = window.location.href.split("?")[1];
  const query = new URLSearchParams(search);
  const uid = query.get("uid");
  const token = query.get("token");

  const navigate = useNavigate();

  const confirmPasswordReset = (password: string) => {
    axios
      .post(confirmPasswordResetEndpoint, {
        new_password1: password,
        new_password2: password,
        uid: uid,
        token: token,
      })
      .then(() => {
        successToast("Password set successfully.", isLightMode);
        navigate("/login/");
      })
      .catch((error) => {
        const data = error.response.data;

        const passwordErrors = data.new_password2;
        if (passwordErrors === undefined) {
          errorToast("Password reset link has expired. Please request a new one.", isLightMode);
          return;
        }
        const errorMessage = createPasswordChangeErrorMessage(passwordErrors);

        errorToast(errorMessage, isLightMode);
      });
  };

  const canonicalUrl = isSetNewPasswordPage ? "/reset-password/" : "/welcome/";
  const pageTitle = isSetNewPasswordPage ? "Set New Password" : "Welcome";

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: canonicalUrl }]}>
        <title>{pageTitle} | Pre-Reg Shortcuts</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <WelcomeBackgroundView confirmPasswordReset={confirmPasswordReset} isSetNewPasswordPage={isSetNewPasswordPage} />
    </>
  );
};

export default WelcomePage;

export const createPasswordChangeErrorMessage = (passwordErrors: string) => {
  let errorMessage = "Error! This password is: \n";
  if (passwordErrors.includes("This password is entirely numeric.")) {
    errorMessage += "- Entirely numeric\n";
    return "Password is entirely numeric. Enter a stronger password.";
  }
  if (passwordErrors.includes("This password is too common.")) {
    errorMessage += "- Too common\n";
    return "Password is too common. Enter a stronger password.";
  }
  if (passwordErrors.includes("The password is too similar to the email address.")) {
    errorMessage += "- Too similar to your email address\n";
    return "Password is too similar to your email address. Enter a stronger password.";
  }
  errorMessage += "\nCorrect your password and try again.";
  return errorMessage;
};
