import { useLocalStorage } from "@uidotdev/usehooks";
import React, { useMemo, useContext, useCallback, createContext } from "react";

import palette from "../../theme/palette";

const themePalette = palette("light");

const preset = {
  name: "default",
  primary: themePalette.primary,
  secondary: themePalette.secondary,
};

interface SettingsContextValueProps {
  themeMode: "light" | "dark";
}

interface SettingsContextActionsProps {
  themeDirection: "ltr" | "rtl";
  themeColorPresets: string;
  presetsColor: typeof preset;
  onToggleMode: () => void;
}

const initialState: SettingsContextValueProps & SettingsContextActionsProps = {
  themeMode: "light",
  themeDirection: "ltr",
  themeColorPresets: "default",
  presetsColor: preset,
  onToggleMode: () => {},
};

const SettingsContext = createContext(initialState);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) throw new Error("useSettingsContext must be use inside SettingsProvider");
  return context;
};

export function SettingsProvider({ children }: React.PropsWithChildren) {
  const [themeMode, setThemeMode] = useLocalStorage<"light" | "dark">("themeMode", "light");

  const onToggleMode = useCallback(() => {
    const newThemeMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newThemeMode);
  }, [setThemeMode, themeMode]);

  const memoizedValue = useMemo(() => {
    const value: SettingsContextValueProps & SettingsContextActionsProps = {
      themeMode,
      themeDirection: "ltr",
      themeColorPresets: "default",
      presetsColor: preset,
      onToggleMode,
    };
    return value;
  }, [themeMode, onToggleMode]);

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
