import { init, BrowserTracing } from "@sentry/react";

const environment = import.meta.env.VITE_ENV.toLowerCase();

// only enable Sentry in staging and production
if (environment !== "development") {
  init({
    environment: environment,
    dsn: "https://50c5b1d25f5a355154746cf769f3c2e1@o4505957485510657.ingest.sentry.io/4506434368110592",
    ignoreErrors: [
      "null is not an object (evaluating 'this.el.querySelector')", // known issue with VidstackPlayer
    ],
    integrations: [
      new BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
