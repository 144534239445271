import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet-async";

import AuthLoginForm from "./components/AuthLoginForm";
import { BackgroundGradient } from "./components/BackgroundGradient";
import { CentralCard } from "./components/CentralCard";

const canonicalUrl = "/login/";
const title = "Login | Pre-Reg Shortcuts";
const metaDescription = "Securely access your Pre-Reg Shortcuts account for pharmacy training resources and materials.";
const metaKeywords =
  "Create Account, Access Foundation Year Plan, Member Login, Access revision courses, Enter email & password";

const LoginPage = () => {
  return (
    <>
      <Helmet link={[{ rel: "canonical", href: canonicalUrl }]}>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>

      <BackgroundGradient>
        <CentralCard>
          <Typography variant="h3">Login</Typography>
          <AuthLoginForm />
        </CentralCard>
      </BackgroundGradient>
    </>
  );
};

export { LoginPage };
