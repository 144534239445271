const TopSection = () => {
  return (
    <div>
      <p>Welcome to our complete all in one programme to support your Foundation Training Year. </p>
      <p>
        With a blend of resources to access immediately and live training to support your GPhC exam revision and beyond.
      </p>
      <p>
        This programme is designed to enrol anytime, with 365 days access, suitable for June and November exam trainees.
      </p>
      <p>In addition, there's optional add on training as we're working with ECG & NPA.</p>
      <p>Trainee Pharmacists: Enrol now or through your training provider.</p>
      <p>
        Pharmacy Contractor/Organisation: Enrol your Trainee/s now and access your FREE designated supervisor support
        programme.
      </p>
    </div>
  );
};

export { TopSection };
