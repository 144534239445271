import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import { useEffect, useState } from "react";

import useResponsive from "src/hooks/useResponsive";

const marks = [0, 10, 20, 30, 40, 50].map((value) => ({
  value,
  label: `${value}%`,
}));

interface SelectDurationProps {
  initialHours: number;
  initialMinutes: number;
  onChange: (hours: number, minutes: number) => void;
}

const SelectDuration = ({ initialHours, initialMinutes, onChange }: SelectDurationProps) => {
  const isMdUp = useResponsive("up", "md");

  const [needMoreTime, setNeedMoreTime] = useState(false);
  const [additionalTime, setAdditionalTime] = useState<number>(0);
  const [newHours, newMinutes] = calculateNewDuration(additionalTime, initialHours, initialMinutes);

  useEffect(() => {
    onChange(newHours, newMinutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalTime]);

  if (needMoreTime) {
    return (
      <div>
        <p style={{ marginBottom: "0.5rem" }}>
          Duration: {newHours} {pluralize("hour", newHours)}{" "}
          {newMinutes > 0 && (
            <>
              {newMinutes} {pluralize("minute", newMinutes)}
            </>
          )}
        </p>

        {additionalTime === 0 ? (
          <p style={{ margin: 0 }}>with no additional time</p>
        ) : (
          <p style={{ margin: 0 }}>including {additionalTime}% additional time</p>
        )}

        <p>Drag the slider to add additional time</p>
        <div
          style={{
            width: isMdUp ? "275px" : "90%",
            marginLeft: isMdUp ? "0.5rem" : "1rem",
          }}
        >
          <Slider
            aria-label="Additional Time"
            value={additionalTime}
            onChange={(_, newValue) => setAdditionalTime(newValue as number)}
            step={10}
            min={0}
            max={50}
            marks={marks}
            getAriaValueText={(value) => `${value}%`}
            color="primary"
            size="medium"
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <p style={{ marginBottom: "0.5rem" }}>
        Duration: {initialHours} {pluralize("hour", initialHours)}{" "}
        {initialMinutes > 0 && (
          <>
            {initialMinutes} {pluralize("minute", initialMinutes)}
          </>
        )}
      </p>
      <Button variant="outlined" color="inherit" onClick={() => setNeedMoreTime(true)} size="small">
        Need more time?
      </Button>
    </div>
  );
};

export default SelectDuration;

const calculateNewDuration = (additionalTime: number | undefined, initialHours: number, initialMinutes: number) => {
  const currentTimeInMinutes = initialHours * 60 + initialMinutes;
  if (additionalTime === undefined) {
    return [initialHours, initialMinutes];
  }
  const newTimeInMinutes = currentTimeInMinutes * (1 + additionalTime / 100);
  const newHours = Math.floor(newTimeInMinutes / 60);
  const newMinutes = Math.floor(newTimeInMinutes % 60);
  return [newHours, newMinutes];
};

const pluralize = (word: string, num: number) => {
  return num === 1 ? word : word + "s";
};
