import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext } from "react";

import imagePlaceholder from "src/assets/placeholder.jpeg";
import { Iconify } from "src/components/Iconify";
import { OurCoursel } from "src/components/OurCarousel";
import { RenderMarkdown } from "src/components_with_stories/qb_question/RenderMarkdown";
import { AccessContext } from "src/context/AccessContext";
import { useIsMobile } from "src/hooks/useIsMobile";
import CourseModel from "src/models/CourseModel";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import ResourceCost from "src/newComponents/ResourceCost";
import { resourceCanBePurchased } from "src/repository/resourceRepository";
import DateService from "src/utils/dateService";

export default function ElearningCourseDetailsHero({ course }: { course: CourseModel }) {
  const { getResourceModelByResourceId } = useContext(AccessContext);
  const resourceModel = course.resourceId ? getResourceModelByResourceId(course.resourceId) : null;

  const isMobile = useIsMobile();

  const totalHours = course.hours;
  const accessEnds = resourceModel?.access?.accessEnds;

  const hasAccessEnded = accessEnds ? accessEnds.isBefore(DateService.newDate()) : false;
  const doesAccessExpireSoon = accessEnds
    ? accessEnds.isBefore(DateService.newDate().add(4, "week")) && !hasAccessEnded
    : false;

  const size = isMobile ? "240px" : "300px";

  return (
    <Box sx={{ bgcolor: "background.neutral", mt: 10, pb: { xs: 4, md: 8 } }}>
      <Container sx={{ overflow: "hidden" }}>
        {doesAccessExpireSoon && accessEnds && (
          <Alert sx={{ my: 2 }} icon={<AccessTimeIcon fontSize="inherit" />} severity="warning">
            Your access to this course expires on {DateService.formatEight(accessEnds)}.
          </Alert>
        )}
        {hasAccessEnded && accessEnds && (
          <Alert sx={{ my: 2 }} icon={<AccessTimeIcon fontSize="inherit" />} severity="error">
            Your access to this course ended on {DateService.formatEight(accessEnds)}.
          </Alert>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: isMobile ? "320px" : "420px" }}>
            <OurCoursel
              autoPlay={true}
              size={isMobile ? "standard" : "large"}
              elements={[
                <img
                  key={course.title}
                  alt={course.title}
                  src={resourceModel?.image ?? imagePlaceholder}
                  width={size}
                  height={size}
                />,
              ]}
            />
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3" component="h1" sx={{ mt: 2 }}>
            {course.title}
          </Typography>
          <RenderMarkdown text={course.description} />
        </div>

        <Divider sx={{ borderStyle: "dashed", mb: 2 }} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: isMobile ? "1.25rem" : "2rem",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Avatar src={course.tutor.profile_image ?? undefined} />
            <Typography variant="body2" color="text.secondary">
              {course.tutor.name}
            </Typography>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Iconify icon="carbon:time" />
            <Typography variant="body2" color="text.secondary">{`${totalHours} hours`}</Typography>
          </div>

          {resourceModel && resourceCanBePurchased(resourceModel) && (
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <ResourceCost cost={resourceModel.cost} originalCost={resourceModel.originalCost} paddingTop={false} />
              <AddResourceToBasket resourceModel={resourceModel} />
            </Stack>
          )}
        </div>
      </Container>
    </Box>
  );
}
