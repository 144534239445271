import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet-async";

import LiveChatIcon from "src/assets/liveChatIcon.jpg";
import { Iconify } from "src/components/Iconify";
import useResponsive from "src/hooks/useResponsive";
import PageTemplate from "src/pages/PageTemplate";

const ContactUsPage = () => {
  const isMdUp = useResponsive("up", "md");

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/contact/" }]}>
        <title>Contact Us | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Connect with us. Have questions or need assistance? Reach out to our team for prompt and helpful support."
        />
        <meta
          name="keywords"
          content="Contact us for more support, Check our FAQ, Email us, Questions and Assistance"
        />
      </Helmet>
      <PageTemplate>
        <Typography variant="h1" style={{ textAlign: "center" }}>
          Do You Have Questions?
        </Typography>
        <p style={{ textAlign: "center" }}>
          Find answers to most of your questions via our{" "}
          <a href="/faq/" className="faqPageLink" target="_blank">
            FAQ page
          </a>
          .
        </p>

        <Typography variant="h1" sx={{ mt: 5, mb: 2, textAlign: "center" }}>
          Speak With Our Team
        </Typography>
        <p style={{ textAlign: "center" }}>
          Our team is available to chat with you via Live Chat. Look for the Live Chat option at the bottom right of
          your screen.
        </p>

        <ContactViaLiveChat />

        <Typography variant="h1" sx={{ mt: 5, mb: 2, textAlign: "center" }}>
          Other Contacts
        </Typography>

        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <p style={{ marginBottom: "0" }}>Our opening hours are Mon-Fri 9am-6pm.</p>{" "}
          <p style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }}>
            If you prefer, you can also reach us through the following methods:
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: isMdUp ? "row" : "column",
            justifyContent: "center",
            gap: "2rem",
            alignItems: "center",
          }}
        >
          <ContactViaEmail />
          <ContactViaPhone />
        </div>
      </PageTemplate>
    </>
  );
};

export default ContactUsPage;

const ContactViaLiveChat = () => {
  return (
    <div style={{ textAlign: "center", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      <Typography variant="body1" style={{ fontWeight: "600" }}>
        Live Chat (Recommended)
      </Typography>
      <img
        alt="Live Chat with Pre-Reg Shortcuts"
        title="Live Chat with Pre-Reg Shortcuts"
        src={LiveChatIcon}
        width={50}
        height={50}
        loading="eager"
        style={{ margin: "auto" }}
      />
    </div>
  );
};

const ContactViaEmail = () => {
  return (
    <div style={{ textAlign: "center", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      <div style={{ display: "flex", gap: "0.75rem", justifyContent: "center", marginRight: "0.5rem" }}>
        <Iconify icon="carbon:email" width={24} />
        <span style={{ marginTop: "2px" }}>Email</span>
      </div>
      <Link color="inherit" variant="body1" href="mailto:info@preregshortcuts.com" className="faqPageLink">
        info@preregshortcuts.com
      </Link>
    </div>
  );
};

const ContactViaPhone = () => {
  return (
    <div style={{ textAlign: "center", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      <div style={{ display: "flex", gap: "0.75rem", justifyContent: "center", marginRight: "0.5rem" }}>
        <Iconify icon="carbon:phone" width={24} />
        <span style={{ marginTop: "2px" }}>Phone</span>
      </div>
      <Link color="inherit" variant="body1" href="tel:07822010164" className="faqPageLink">
        07822010164
      </Link>
    </div>
  );
};
