import Stack from "@mui/material/Stack";

import { Route } from "src/utils/routes";

import NavListDesktop from "./NavListDesktop";

export function NavDesktop({ leftRoutes, rightRoutes }: { leftRoutes: Route[]; rightRoutes: Route[] }) {
  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
      <Stack component="nav" direction="row" spacing={{ xs: 4, lg: 5.5 }} sx={{ ml: 6, height: 1 }}>
        {leftRoutes.map((link) => (
          <NavListDesktop key={link.title} item={link} />
        ))}
      </Stack>

      <Stack component="nav" direction="row" spacing={{ xs: 4, lg: 5.5 }} sx={{ mr: 3, height: 1 }}>
        {rightRoutes.map((link) => (
          <NavListDesktop key={link.title} item={link} />
        ))}
      </Stack>
    </div>
  );
}
