import Alert from "@mui/material/Alert";

import useIsLightMode from "src/hooks/useIsLightMode";

import { RenderMarkdown } from "../RenderMarkdown";

export const AnswerFeedback = ({
  isCorrect,
  formattedActualAnswer,
  actualAnswerReason,
}: {
  isCorrect: boolean;
  formattedActualAnswer: string;
  actualAnswerReason?: string | null;
}) => {
  const isLightMode = useIsLightMode();
  const severity = isCorrect ? "success" : "error";
  const darkSuccessColor = "#388e3c";
  const darkErrorColor = "#ef243b";
  const color = isCorrect ? darkSuccessColor : darkErrorColor;
  return (
    <Alert
      severity={severity}
      sx={{
        marginTop: 2,
        fontSize: "1rem",
        backgroundColor: isLightMode ? undefined : color,
      }}
    >
      {isCorrect ? <>Correct!</> : <>Incorrect. The correct answer is {formattedActualAnswer}. </>}

      {actualAnswerReason && (
        <div style={{ marginBottom: "-8px" }}>
          <RenderMarkdown text={actualAnswerReason} />
        </div>
      )}
    </Alert>
  );
};
