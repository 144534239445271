import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CourseLessonProgressModel from "src/models/CourseLessonProgressModel";
import CourseModel from "src/models/CourseModel";
import RenderTutor from "src/newComponents/RenderTutor";

import Course from "./Course";

export default function ElearningCourseDetailsSummary({
  course,
  courseLessonProgress,
  updateLessonProgress,
}: {
  course: CourseModel;
  courseLessonProgress: CourseLessonProgressModel[] | undefined;
  updateLessonProgress: (lessonProgress: CourseLessonProgressModel) => void;
}) {
  return (
    <Grid container direction="row-reverse" spacing={3}>
      <Grid item xs={12} sm={4}>
        <RenderTutor tutor={course.tutor} />
      </Grid>

      <Grid item xs={12} sm={8}>
        <Typography variant="h3" sx={{ mb: 2.5 }}>
          Course
        </Typography>

        <Course
          course={course}
          simplifiedView={false}
          courseLessonProgress={courseLessonProgress}
          updateLessonProgress={updateLessonProgress}
        />
      </Grid>
    </Grid>
  );
}
