import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import useResponsive from "src/hooks/useResponsive";
import ResourceModel from "src/models/ResourceModel";
import LearnMoreButtonWithModal from "src/newComponents/buttons/LearnMoreButtonWithModal";
import PreviewLinkButton from "src/newComponents/buttons/PreviewLinkButton";

import KlarnaPayment from "./KlarnaPayment";
import ProductItem from "./ProductItem";

interface ProductItemsProps {
  title: string;
  link: string;
  content: React.ReactNode;
  resourceModels: ResourceModel[] | null;
  klarnaCost?: number;
}

export default function ProductItems({ title, link, content, resourceModels, klarnaCost }: ProductItemsProps) {
  const isMdUp = useResponsive("up", "md");

  if (!resourceModels) return null;
  if (resourceModels.length === 0) return null;

  return (
    <Link component={RouterLink} to={link} color="inherit" sx={{ "&:hover": { textDecoration: "none" } }}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2 }}
        alignItems={{ xs: "flex-start", sm: "center" }}
        sx={{ mt: { xs: 3, sm: 2 }, mb: { xs: 2, sm: 2 } }}
      >
        <Typography variant="h4">{title}</Typography>
        <Stack direction="row" spacing={2} sx={{ mb: { xs: 1 }, alignItems: "center" }}>
          <LearnMoreButtonWithModal title={title} content={content} />
          <PreviewLinkButton link={link} />
        </Stack>
        {isMdUp && klarnaCost && (
          <div>
            <KlarnaPayment cost={klarnaCost} />
          </div>
        )}
      </Stack>

      {!isMdUp && klarnaCost && (
        <div style={{ marginBottom: "1rem" }}>
          <KlarnaPayment cost={klarnaCost} />
        </div>
      )}

      {resourceModels.map((resourceModel) => {
        if (!resourceModel) return null;
        return <ProductItem key={resourceModel.id} resourceModel={resourceModel} />;
      })}
      <Divider
        sx={{
          mt: {
            xs: 3,
            sm: 2,
          },
        }}
      />
    </Link>
  );
}
