import * as prismic from "@prismicio/client";
import { PrismicProvider } from "@prismicio/react";

const repositoryName = "preregshortcuts";

const prismicAccessToken = import.meta.env.VITE_PRISMIC_ACCESS_TOKEN;
const prismicClient = prismic.createClient(repositoryName, {
  accessToken: prismicAccessToken,
});

export { prismicClient, PrismicProvider };
