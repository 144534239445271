import Container from "@mui/material/Container";
import React, { JSX, useRef, Suspense } from "react";
import Carousel from "react-slick";

import { CarouselArrows } from "src/pages/landing/components/reviews/CarouselArrows";

const Slider = React.lazy(() => import("react-slick"));

const CAROUSEL_SETTINGS = {
  dots: false,
  arrows: false,
  slidesToShow: 1,
  draggable: false,
  slidesToScroll: 1,
  adaptiveHeight: true,
  infinite: true,
  cssEase: "linear",
};

interface OurCarouselProps {
  elements: JSX.Element[];
  autoPlay?: boolean;
  size?: "standard" | "large";
}

export function OurCoursel({ elements, autoPlay = false, size = "standard" }: OurCarouselProps) {
  const carouselRef = useRef<Carousel>(null);

  const handlePrev = () => carouselRef.current?.slickPrev();
  const handleNext = () => carouselRef.current?.slickNext();

  const arrowButtonSx = { display: "inline-flex" };

  const iconSize = size === "standard" ? 24 : 42;
  const paddingSize = size === "standard" ? "40px" : "60px";

  return (
    <Container
      maxWidth="md"
      sx={{ position: "relative" }}
      // add padding to make space for the arrows
      style={{ paddingLeft: paddingSize, paddingRight: paddingSize }}
    >
      <CarouselArrows
        onNext={handleNext}
        onPrev={handlePrev}
        leftButtonProps={{ sx: { ...arrowButtonSx, left: 0 } }}
        rightButtonProps={{ sx: { ...arrowButtonSx, right: 0 } }}
        iconSize={iconSize}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Slider {...CAROUSEL_SETTINGS} autoplaySpeed={6000} autoplay={autoPlay} ref={carouselRef}>
            {elements}
          </Slider>
        </Suspense>
      </CarouselArrows>
    </Container>
  );
}
