import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useState, useContext, JSX } from "react";
import { Link as RouterLink } from "react-router-dom";

import { RenderMarkdown } from "src/components_with_stories/qb_question/RenderMarkdown";
import { AppContext } from "src/context/AppContext";
import { BasketContext } from "src/context/BasketContext";
import useResponsive from "src/hooks/useResponsive";
import ResourceModel from "src/models/ResourceModel";
import { resourceModelTypeToRouteMap } from "src/models/ResourceModelType";

import CustomModal from "./CustomModal";

interface AddResourceToBasketProps {
  resourceModel: ResourceModel;
  // renderAccess, a function that renders a component to access the resource, if the user has access, e.g. zoom link
  renderAccess?: () => JSX.Element;
  showAccessButton?: boolean;
}

const AddResourceToBasket = ({ renderAccess, resourceModel, showAccessButton }: AddResourceToBasketProps) => {
  const { isAuthenticated } = useContext(AppContext);
  const { addItemToBasket, removeItemFromBasketOnConfirm, isResourceInBasket } = useContext(BasketContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  // keep until all consumers are in TypeScript
  if (resourceModel === null) return null;

  const isInBasket = isResourceInBasket(resourceModel);

  const showAccessLink = isAuthenticated && resourceModel.access?.userHasValidAccess;
  const itemDescription = resourceModel.checkoutDescription ?? "...";

  if (showAccessLink) {
    if (renderAccess) return renderAccess();
    if (showAccessButton) {
      const link = `/${resourceModelTypeToRouteMap[resourceModel.type]}/`;

      return (
        <Button component={RouterLink} to={link} variant="contained" color="info" size="medium">
          Access
        </Button>
      );
    }
    return null;
  }

  const addToBasket = async () => {
    setIsLoading(true);
    setLoadingText("Adding to basket...");

    const success = await addItemToBasket(resourceModel.title, itemDescription, resourceModel.image, resourceModel);

    if (success) {
      setIsLoading(false);
      setLoadingText("");
      setIsModalVisible(true);
    }
  };

  const removeFromBasket = () => {
    setIsLoading(true);
    setLoadingText("Removing...");

    const onConfirmAndCancel = () => {
      setIsLoading(false);
      setLoadingText("");
    };

    removeItemFromBasketOnConfirm(resourceModel.stripePriceId, resourceModel.id, onConfirmAndCancel);
  };

  const clickAddToBasket = () => {
    if (isInBasket) {
      removeFromBasket();
    } else {
      addToBasket();
    }
  };

  if (resourceModel.type === "questionSet") {
    // cannot allow users to add a question set individually, they must purchase the full mock paper
    return <>Your access has expired. You can no longer review this paper.</>;
  }

  return (
    <>
      <AddToBasketButton
        isInBasket={isInBasket}
        isLoading={isLoading}
        onClick={clickAddToBasket}
        loadingText={loadingText}
      />
      <CustomModal
        title={`${resourceModel.title} added to basket!`}
        isOpen={isModalVisible}
        handleClose={() => setIsModalVisible(false)}
        showCloseIcon={false}
        maxWidth="xs"
      >
        <ItemDescription
          image={resourceModel.image}
          itemDescription={itemDescription}
          itemTitle={resourceModel.title}
        />
        <ContinueBrowsingOrProceedToCheckout setIsModalVisible={setIsModalVisible} />
      </CustomModal>
    </>
  );
};

export default AddResourceToBasket;

function ContinueBrowsingOrProceedToCheckout({ setIsModalVisible }: { setIsModalVisible: (value: boolean) => void }) {
  return (
    <Stack
      spacing={2}
      direction={{
        xs: "column",
        sm: "row",
      }}
      style={{
        marginTop: "1rem",
      }}
    >
      <Button
        type="submit"
        variant="contained"
        color="inherit"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsModalVisible(false);
        }}
      >
        Continue Browsing
      </Button>
      <Button
        component={RouterLink}
        to={"/checkout/"}
        type="submit"
        variant="contained"
        color="inherit"
        onClick={() => setIsModalVisible(false)}
      >
        Proceed to Checkout
      </Button>
    </Stack>
  );
}

function ItemDescription({
  image,
  itemDescription,
  itemTitle,
}: {
  image: string;
  itemDescription: string;
  itemTitle: string;
}) {
  const isMobile = useResponsive("down", "sm");

  return (
    <Stack spacing={2} direction="row" gap={{ xs: 1, sm: 2, md: 2 }} style={{ alignItems: "center" }}>
      <img
        src={image}
        alt={itemTitle}
        style={{
          height: "80px",
          width: "auto",
          objectFit: "cover",
          borderRadius: "0.5rem",
          margin: isMobile ? "0 auto" : "0",
        }}
      />
      <RenderMarkdown text={itemDescription} asSecondaryBody2={true} compact={true} />
    </Stack>
  );
}

function AddToBasketButton({
  isInBasket,
  isLoading,
  onClick,
  loadingText,
}: {
  isInBasket: boolean;
  isLoading: boolean;
  onClick: () => void;
  loadingText: string;
}) {
  const buttonText = isInBasket ? "Remove" : "Add to Basket";

  return (
    <Button
      type="submit"
      variant={isInBasket ? "outlined" : "contained"}
      color="inherit"
      disabled={isLoading}
      onClick={(e) => {
        // if the button is in a clickable element, prevent the click from propagating
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      style={{
        minWidth: isInBasket ? "85px" : "130px",
      }}
    >
      {isLoading ? loadingText : buttonText}
    </Button>
  );
}
