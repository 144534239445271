import { ReactNode } from "react";

interface AnswerLinkProps {
  href: string;
  children: ReactNode;
}

const NewTabLink = ({ href, children }: AnswerLinkProps) => {
  return (
    <a
      href={href}
      className="faqPageLink"
      target="_blank"
      rel="noreferrer"
      onClick={(e) => {
        // Prevent the click from propagating to the parent element but allow the link to open
        e.stopPropagation();
      }}
    >
      {children}
    </a>
  );
};

export { NewTabLink };
