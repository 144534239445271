import Link from "@mui/material/Link";
import ListItemText from "@mui/material/ListItemText";
import { Link as RouterLink } from "react-router-dom";

import Label from "src/components/labels/Label";
import { ChildRoute } from "src/utils/routes";

import { StyledItem, StyledIcon, StyledDotIcon } from "./styles";

export default function NavItemMobile({ item, active }: { item: ChildRoute; active: boolean }) {
  const { title, path } = item;

  const isComingSoon = item.isComingSoon === true;
  const isNew = item.isNew === true;

  const renderContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <StyledItem depth={1} active={active}>
        <StyledIcon>
          <StyledDotIcon active={active} />
        </StyledIcon>

        <ListItemText
          primary={title}
          primaryTypographyProps={{
            component: "span",
            variant: active ? "subtitle2" : "body2",
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: "caption",
          }}
        />
      </StyledItem>
      {isComingSoon && (
        <Label
          color="info"
          style={{
            marginTop: "4px",
            marginLeft: "3.35rem",
            width: "100px",
            marginBottom: "0.5rem",
          }}
        >
          Coming Soon
        </Label>
      )}
      {isNew && (
        <Label
          color="success"
          style={{
            marginTop: "-4px",
            marginLeft: "3.35rem",
            width: "100px",
            marginBottom: "0.5rem",
          }}
        >
          New
        </Label>
      )}
    </div>
  );

  return (
    <Link component={RouterLink} to={path} underline="none">
      {renderContent}
    </Link>
  );
}
