import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useTimer } from "react-timer-hook";

import { DARK_MODE_TOAST_STYLE, LIGHT_MODE_TOAST_STYLE } from "src/App";
import { SIX_SECONDS } from "src/utils/duration";

import { palePurple } from "./qb_question/QBQuestion";

const SHOW_ALERT_WHEN_REMAINING_PERCENTAGE = 0.2;

interface QBTimerProps {
  expiryTimestamp: Date;
  totalDurationInMinutes: number;
  onExpire: () => void;
  isLightMode: boolean;
  timerHidden: boolean;
  toggleHideTimer: () => void;
}

const QBTimer = ({
  expiryTimestamp,
  totalDurationInMinutes,
  onExpire,
  isLightMode,
  timerHidden,
  toggleHideTimer,
}: QBTimerProps) => {
  const [alertSent, setAlertSent] = useState(false);

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire,
  });

  const almostOutOfTime = areWeAlmostOutOfTime(totalDurationInMinutes, expiryTimestamp);

  useEffect(() => {
    if (alertSent === false && almostOutOfTime === true) {
      setAlertSent(true);
      toast(
        <div data-cy="TimeRemainingToast">
          <TimeRemainingToast hours={hours} minutes={minutes} seconds={seconds} almostOutOfTime={almostOutOfTime} />
        </div>,
        {
          icon: "⏳",
          duration: SIX_SECONDS,
          style: isLightMode ? LIGHT_MODE_TOAST_STYLE : DARK_MODE_TOAST_STYLE,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [almostOutOfTime]);

  return (
    <div
      style={{
        backgroundColor: palePurple,
        padding: "0.25rem 1rem",
        borderRadius: "0.5rem",
      }}
    >
      {!timerHidden && (
        <TimeRemaining hours={hours} minutes={minutes} seconds={seconds} almostOutOfTime={almostOutOfTime} />
      )}

      <Tooltip title={timerHidden ? "Show Timer" : "Hide Timer"}>
        <IconButton
          aria-label={timerHidden ? "Show Timer" : "Hide Timer"}
          onClick={toggleHideTimer}
          style={timerHidden ? undefined : { marginLeft: "0.5rem" }}
        >
          {timerHidden ? <VisibilityIcon style={{ width: "24px" }} /> : <VisibilityOffIcon style={{ width: "22px" }} />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default QBTimer;

const TimeRemainingToast = ({
  hours,
  minutes,
  seconds,
  almostOutOfTime,
}: {
  hours: number;
  minutes: number;
  seconds: number;
  almostOutOfTime: boolean;
}) => {
  return (
    <>
      <span style={{ marginRight: "0.2rem" }}>Time Remaining:</span>
      <TimeRemaining hours={hours} minutes={minutes} seconds={seconds} almostOutOfTime={almostOutOfTime} />
    </>
  );
};

const TimeRemaining = ({
  hours,
  minutes,
  seconds,
  almostOutOfTime,
}: {
  hours: number;
  minutes: number;
  seconds: number;
  almostOutOfTime: boolean;
}) => {
  const hoursFormatted = formatTime(hours);
  const minutesFormatted = formatTime(minutes);
  const secondsFormatted = formatTime(seconds);
  return (
    <span style={almostOutOfTime ? almostOutOfTimeStyle : { fontSize: "18px" }}>
      <span>{hoursFormatted}</span>:<span>{minutesFormatted}</span>:<span>{secondsFormatted}</span>
    </span>
  );
};

const formatTime = (time: number) => {
  return time.toString().length === 1 ? "0" + time : time;
};

const areWeAlmostOutOfTime = (totalDurationInMinutes: number, expiryTimestamp: Date) => {
  const totalDurationInSeconds = totalDurationInMinutes * 60; // expected quiz duration in seconds
  const secondsUntilExpiry = (expiryTimestamp.getTime() - Date.now()) / 1000; // seconds left until expiry
  const percentageTimeRemaining = secondsUntilExpiry / totalDurationInSeconds;
  return percentageTimeRemaining <= SHOW_ALERT_WHEN_REMAINING_PERCENTAGE;
};

const almostOutOfTimeStyle = {
  fontWeight: "bold",
  fontSize: "18px",
};
