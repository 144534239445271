import americanExpressIcon from "./paymentIcons/american-express.svg";
import applePayIcon from "./paymentIcons/apple-pay.svg";
import googlePayIcon from "./paymentIcons/google-pay.svg";
import klarnaIcon from "./paymentIcons/klarna.png";
import mastercardIcon from "./paymentIcons/mastercard.svg";
import paypalIcon from "./paymentIcons/paypal.svg";
import visaIcon from "./paymentIcons/visa.svg";

const PaymentIcons = () => {
  const iconWidth = "30px";

  return (
    <div style={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "center", marginTop: "8px" }}>
      <img src={applePayIcon} alt="Apple Pay" width={iconWidth} style={{ width: iconWidth }} />
      <img src={googlePayIcon} alt="Google Pay" width={iconWidth} style={{ width: iconWidth }} />
      <img src={klarnaIcon} alt="Klarna" width={iconWidth} height="18px" style={{ width: iconWidth, height: "18px" }} />
      <img src={mastercardIcon} alt="Mastercard" width={iconWidth} style={{ width: iconWidth }} />
      <img src={visaIcon} alt="Visa" width={iconWidth} style={{ width: iconWidth }} />
      <img src={paypalIcon} alt="Paypal" width="28px" style={{ width: "20px", height: "20px" }} />
      <img src={americanExpressIcon} alt="American Express" width={iconWidth} style={{ width: iconWidth }} />
    </div>
  );
};

export { PaymentIcons };
