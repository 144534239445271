import { Elements, PaymentMethodMessagingElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const key = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const stripe = loadStripe(key);

const KlarnaPayment = ({ cost }: { cost: number }) => {
  return (
    <Elements stripe={stripe}>
      <PaymentMethodMessagingElement
        options={{
          amount: amountInGBP(cost),
          currency: "GBP",
          paymentMethodTypes: ["klarna"],
          countryCode: "GB",
        }}
      />
    </Elements>
  );
};

export default KlarnaPayment;

const amountInGBP = (amount: number) => amount * 100;
