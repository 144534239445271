import Stack from "@mui/material/Stack";
import React from "react";

const CentralCard = ({ children }: React.PropsWithChildren) => {
  return (
    <Stack
      spacing={4}
      sx={{
        p: 4,
        width: 1,
        mx: "auto",
        flexShrink: 0,
        maxWidth: 400,
        borderRadius: 2,
        bgcolor: "background.default",
        textAlign: { xs: "center", md: "left" },
        boxShadow: (theme) => theme.customShadows.z24,
      }}
    >
      {children}
    </Stack>
  );
};

export { CentralCard };
