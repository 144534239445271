import { useLocalStorage } from "@uidotdev/usehooks";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import MPQuiz from "src/components_with_stories/MPQuiz";
import { errorToast } from "src/components_with_stories/toast";
import { AccessContext } from "src/context/AccessContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import QuestionSetModel from "src/models/QuestionSetModel";
import PageTemplate from "src/pages/PageTemplate";
import QuestionSetRepostiory, { QuestionDto } from "src/repository/questionSetRepository";
import DateService from "src/utils/dateService";

const AttemptMockPaperPage = () => {
  const { refreshResources } = useContext(AccessContext);
  const isLightMode = useIsLightMode();
  const navigate = useNavigate();

  const questionSet = useLocalStorage<QuestionSetModel | null | undefined>("mp_questionSet", null)[0];
  if (!questionSet) {
    return null;
  }

  if (questionSet.accessEnds !== null) {
    const hasAccessEnded = DateService.newDate().isAfter(DateService.parseServerDate(questionSet.accessEnds));
    if (hasAccessEnded) {
      errorToast("Your access has ended.", isLightMode);
      navigate("/mock-paper");
    }
  }

  const getQuestions = async (attemptDuration: number): Promise<QuestionDto[] | null> => {
    const questionSetRepostiory = new QuestionSetRepostiory();
    try {
      const mockPaperQuestions = await questionSetRepostiory.startQuestionSet(
        questionSet.questionSetId,
        attemptDuration
      );
      refreshResources();
      return Promise.resolve(mockPaperQuestions);
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const errorMessage = error?.response?.data?.error;
        errorToast(errorMessage, isLightMode);
      } else {
        errorToast("Error loading questions. Please try again later.", isLightMode);
      }

      return Promise.resolve(null);
    }
  };

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/attempt-mock-paper/" }]}>
        <title>Mock Paper | Pre-Reg Shortcuts</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <PageTemplate>
        <MPQuiz getQuestions={getQuestions} questionSet={questionSet} />
      </PageTemplate>
    </>
  );
};

export default AttemptMockPaperPage;
