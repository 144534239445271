import Grid from "@mui/material/Grid";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";

import additionalTrainingOptions from "src/assets/foundationTrainingProgrammeFeatures/additional_training_options.webp";
import additionalTrainingOptions2 from "src/assets/foundationTrainingProgrammeFeatures/additional_training_options_2.webp";
import examRevisionCourses1 from "src/assets/foundationTrainingProgrammeFeatures/exam_revision_courses.webp";
import examRevisionCourses2 from "src/assets/foundationTrainingProgrammeFeatures/exam_revision_courses_2.webp";
import examRevisionCourses3 from "src/assets/foundationTrainingProgrammeFeatures/exam_revision_courses_3.webp";
import examRevisionCourses4 from "src/assets/foundationTrainingProgrammeFeatures/exam_revision_courses_4.webp";
import lifetimeTelegramAccess from "src/assets/foundationTrainingProgrammeFeatures/lifetime_telegram_access.webp";
import lifetimeTelegramAccess2 from "src/assets/foundationTrainingProgrammeFeatures/lifetime_telegram_access_2.webp";
import liveOnlineStudySessions1 from "src/assets/foundationTrainingProgrammeFeatures/live_online_study_sessions.webp";
import liveOnlineStudySessions2 from "src/assets/foundationTrainingProgrammeFeatures/live_online_study_sessions_2.webp";
import liveOnlineStudySessions3 from "src/assets/foundationTrainingProgrammeFeatures/live_online_study_sessions_3.webp";
import mockPaper1 from "src/assets/foundationTrainingProgrammeFeatures/mock_paper.webp";
import mockPaper2 from "src/assets/foundationTrainingProgrammeFeatures/mock_paper_2.webp";
import mockPaper3 from "src/assets/foundationTrainingProgrammeFeatures/mock_paper_3.webp";
import programmeResources1 from "src/assets/foundationTrainingProgrammeFeatures/programme_resources.webp";
import programmeResources2 from "src/assets/foundationTrainingProgrammeFeatures/programme_resources_2.webp";
import programmeResources3 from "src/assets/foundationTrainingProgrammeFeatures/programme_resources_3.webp";
import programmeResources4 from "src/assets/foundationTrainingProgrammeFeatures/programme_resources_4.webp";
import programmeResources5 from "src/assets/foundationTrainingProgrammeFeatures/programme_resources_5.webp";
import questionBank1 from "src/assets/foundationTrainingProgrammeFeatures/question_bank.webp";
import questionBank2 from "src/assets/foundationTrainingProgrammeFeatures/question_bank_2.webp";
import questionBank3 from "src/assets/foundationTrainingProgrammeFeatures/question_bank_3.webp";
import questionBank4 from "src/assets/foundationTrainingProgrammeFeatures/question_bank_4.webp";
import questionBank5 from "src/assets/foundationTrainingProgrammeFeatures/question_bank_5.webp";
import { AccessContext } from "src/context/AccessContext";
import useResponsive from "src/hooks/useResponsive";
import ResourceModelType from "src/models/ResourceModelType";
import EnrolNowButton from "src/newComponents/buttons/EnrolNowButton";

import PageTemplate from "../PageTemplate";
import { Tab } from "./components/Tab";
import { TopSection } from "./components/TopSection";

export interface ProductSection {
  images: string[];
  title: string;
  button?: {
    label: string;
    link: string;
  };
}

const FoundationTrainingProgrammePage = () => {
  const { getResourceModelByResourceType } = useContext(AccessContext);

  const resourceModel = getResourceModelByResourceType(ResourceModelType.foundationTrainingProgramme);
  const userHasAccess = resourceModel?.access?.userHasValidAccess ?? false;
  const isTabletOrSmaller = useResponsive("down", "md");

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/programme/" }]}>
        <title>Foundation Pharmacist Training Programme | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Browse our comprehensive Foundation Pharmacist Training Programme. Featuring a GPhC-approved training plan, GPhC style question bank, mock assessment and more!"
        />
        <meta
          name="keywords"
          content="Foundation pharmacist training programme, Preregistration training, GPhC mock assessment, GPhC questions, Foundation training"
        />
      </Helmet>
      <PageTemplate title="Foundation Pharmacist Training Programme">
        {!userHasAccess && (
          <div style={{ marginTop: "1rem" }}>
            <TopSection />
          </div>
        )}
        {!userHasAccess && isTabletOrSmaller && (
          <div style={{ marginBottom: "1rem" }}>
            <EnrolNowButton />
          </div>
        )}

        <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ mb: 1 }}>
          {sections.map((section, idx) => {
            return (
              <Grid item key={idx} xs={12} sm={6} md={4}>
                <Tab section={section} />
              </Grid>
            );
          })}
        </Grid>
      </PageTemplate>
    </>
  );
};

export { FoundationTrainingProgrammePage };

const sections: ProductSection[] = [
  {
    title: "Exam Revision Courses",
    images: [examRevisionCourses1, examRevisionCourses2, examRevisionCourses3, examRevisionCourses4],
    button: {
      label: "View Exam Revision Courses",
      link: "/courses/",
    },
  },
  {
    title: "Live Online Study Sessions",
    images: [liveOnlineStudySessions1, liveOnlineStudySessions2, liveOnlineStudySessions3],
    button: {
      label: "View Live Online Study Sessions",
      link: "/live-online-study-sessions/",
    },
  },
  {
    title: "Programme Resources",
    images: [programmeResources1, programmeResources2, programmeResources3, programmeResources4, programmeResources5],
    button: {
      label: "View Programme Resources",
      link: "/programme-resources/",
    },
  },
  {
    title: "Question Bank",
    images: [questionBank1, questionBank2, questionBank3, questionBank4, questionBank5],
    button: {
      label: "View Question Bank",
      link: "/question-bank/",
    },
  },
  {
    title: "Mock Paper",
    images: [mockPaper1, mockPaper2, mockPaper3],
    button: {
      label: "View Mock Paper",
      link: "/mock-paper/",
    },
  },
  {
    title: "Telegram Support Group",
    images: [lifetimeTelegramAccess, lifetimeTelegramAccess2],
  },
  {
    title: "Additional Training Options",
    images: [additionalTrainingOptions, additionalTrainingOptions2],
    button: {
      label: "View Additional Training Options",
      link: "/additional-training-options/",
    },
  },
];
