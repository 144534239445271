import Grid from "@mui/material/Grid";
import React from "react";

import ChapterResource from "src/custom_components/courses/ChapterResource";
import ProgrammeResourceModel from "src/models/ProgrammeResourceModel";

interface WorkbooksSectionProps {
  title: string;
  description: React.ReactNode;
  programmeResources?: ProgrammeResourceModel[];
}
const WorkbooksSection = ({ title, description, programmeResources }: WorkbooksSectionProps) => {
  return (
    <div>
      <h2>{title}</h2>
      {description}
      {programmeResources && (
        <Grid container spacing={2}>
          {programmeResources.map((programme) => {
            return (
              <Grid item xs={12} sm={6} key={programme.id} style={{ paddingTop: "6px", paddingBottom: "6px" }}>
                <ChapterResource
                  resourceDescription={programme.title}
                  accessibleResourceUrl={programme.accessibleUrl}
                  isProgrammeResource={true}
                />
                <p style={{ marginTop: "0.5rem", marginBottom: 0 }}>{programme.description}</p>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default WorkbooksSection;
