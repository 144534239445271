import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import freePreviewOfPremium from "src/assets/free_preview_course.png";
import { Iconify } from "src/components/Iconify";
import { Image } from "src/components/Image";
import TextMaxLine from "src/components/text-max-line/TextMaxLine";
import { RenderMarkdown } from "src/components_with_stories/qb_question/RenderMarkdown";
import { AccessContext } from "src/context/AccessContext";
import CourseModel from "src/models/CourseModel";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import ResourceCost from "src/newComponents/ResourceCost";
import { resourceCanBePurchased } from "src/repository/resourceRepository";

export default function ElearningCourseItem({ course }: { course: CourseModel }) {
  const { getResourceModelByResourceId } = useContext(AccessContext);
  const resourceModel = course.resourceId ? getResourceModelByResourceId(course.resourceId) : null;

  const courseTitle = course.title;
  const courseSlug = course.slug;

  const totalHours = course.hours;

  return (
    <Link component={RouterLink} to={courseSlug} color="inherit" sx={{ "&:hover": { textDecoration: "none" } }}>
      <Card
        sx={{
          display: { sm: "flex" },
          "&:hover": {
            boxShadow: (theme) => theme.customShadows.z24,
          },
        }}
      >
        <Box sx={{ flexShrink: { sm: 0 } }}>
          <Image
            alt={courseTitle}
            // we assume all courses have an image except free preview of premium
            src={resourceModel?.image ?? freePreviewOfPremium}
            sx={{
              height: 1,
              objectFit: "cover",
              width: { sm: 240 },
            }}
            loading="eager"
          />
        </Box>

        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack
            spacing={{
              xs: 3,
              sm: 1,
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <TextMaxLine variant="h6" line={1}>
                {courseTitle}
              </TextMaxLine>
            </Stack>

            <RenderMarkdown text={course.description} lines={{ mobile: 4, other: 2 }} asSecondaryBody2={true} />
          </Stack>

          <Divider sx={{ borderStyle: "dashed", display: { sm: "none" } }} />

          <Stack
            direction={{ xs: "column", md: "row" }}
            flexWrap="wrap"
            sx={{
              color: "text.disabled",
              "& > *:not(:last-child)": { mr: 2.5 },
            }}
          >
            <Stack direction="row" spacing={1.5}>
              <Stack direction="row" alignItems="center" spacing={1}>
                {course.tutor.profile_image && <Avatar src={course.tutor.profile_image} />}

                <Typography variant="body2">{course.tutor.name}</Typography>
              </Stack>

              <Stack direction="row" alignItems="center" sx={{ typography: "body2" }}>
                <Iconify icon="carbon:time" sx={{ mr: 1 }} /> {`${totalHours} hours`}
              </Stack>
            </Stack>

            {resourceModel && resourceCanBePurchased(resourceModel) && (
              <Stack direction="row" alignItems="center" spacing={1.5} sx={{ mt: { xs: 1, md: 0 } }}>
                <ResourceCost cost={resourceModel.cost} originalCost={resourceModel.originalCost} paddingTop={false} />
                <AddResourceToBasket resourceModel={resourceModel} />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Card>
    </Link>
  );
}
