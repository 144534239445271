import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { Iconify } from "src/components/Iconify";

type ColorSchema = "primary" | "secondary" | "info" | "success" | "warning" | "error";

type StyledIconProps = {
  color: ColorSchema;
};

const StyledIcon = styled("div", {
  shouldForwardProp: (prop) => prop !== "color",
})<StyledIconProps>(({ color, theme }) => ({
  width: 120,
  height: 120,
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  position: "relative",
  justifyContent: "center",
  color: theme.palette[color].darker,
  border: `dashed 2px ${alpha(theme.palette[color].main, 0.24)}`,
  "&:before": {
    zIndex: 8,
    content: '""',
    borderRadius: "50%",
    position: "absolute",
    width: "calc(100% - 48px)",
    height: "calc(100% - 48px)",
    background: `conic-gradient(from 0deg at 50% 50%, ${theme.palette[color].main} 0deg, ${theme.palette[color].light} 360deg)`,
  },
  "& svg": {
    zIndex: 9,
  },
}));

interface Summary {
  title: string;
  total: number;
  icon: string;
  unit?: string;
  color: ColorSchema;
}

const SUMMARY: Summary[] = [
  {
    title: "Pass Rate",
    total: 95,
    icon: "mdi:graduation-cap",
    unit: "%",
    color: "warning",
  },
  {
    title: "Trainee Pharmacists",
    total: 5500,
    icon: "carbon:user-certification",
    unit: "+",
    color: "success",
  },
  {
    title: "Courses",
    total: 10,
    icon: "ph:video",
    unit: "+",
    color: "secondary",
  },
  {
    title: "Combined Pharmacy Experience",
    total: 20,
    icon: "carbon:increase-level",
    unit: " years",
    color: "primary",
  },
];

const NumbersSPC = ({ isLandingPage }: { isLandingPage: boolean }) => {
  if (isLandingPage) {
    return (
      <Stack direction="row" spacing={{ xs: 3, sm: 10 }} justifyContent="center">
        {SUMMARY.slice(0, 3).map((item) => (
          <Stack key={item.title} spacing={0.5} sx={{ position: "relative" }}>
            <Typography variant="h3" sx={{ color: `#FA541C`, textAlign: "center" }}>
              {item.total}
              {item.unit}
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {item.title}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        m: "auto",
        textAlign: "center",
        display: "grid",
        gap: { xs: 5, md: 8 },
        gridTemplateColumns: {
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        },
      }}
    >
      {SUMMARY.map((value) => (
        <div key={value.title}>
          <StyledIcon color={value.color}>
            <Iconify icon={value.icon} width={40} />
          </StyledIcon>

          <Typography variant="h2" sx={{ mt: 2, mb: 1 }}>
            {value.total}
            {value.unit ?? value.unit}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>{value.title}</Typography>
        </div>
      ))}
    </Box>
  );
};

export default NumbersSPC;
