import { useContext } from "react";
import { Helmet } from "react-helmet-async";

import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { FAQContext } from "src/context/FAQContext";
import PageTemplate from "src/pages/PageTemplate";

import SupportView from "./components/SupportView";

const FAQPage = () => {
  const { faqQuestionGroups } = useContext(FAQContext);

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/faq/" }]}>
        <title>FAQ | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="We're here to support Trainee Pharmacists. Find answers to your queries about our foundation year program and more."
        />
        <meta
          name="keywords"
          content="Common Questions, Support for Trainees, Troubleshooting, GPhC Exam Preparation, Support before enrolling on our programme"
        />
      </Helmet>
      {faqQuestionGroups === undefined && <LoadingScreen />}
      <PageTemplate title="Frequently Asked Questions">
        {faqQuestionGroups && <SupportView faqPageTopics={faqQuestionGroups} />}
      </PageTemplate>
    </>
  );
};

export default FAQPage;
