import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, JSX } from "react";
import { Helmet } from "react-helmet-async";

import firstAidClinicalTrainingImage from "src/assets/addOns/firstAidAndClinicalAssessmentTraining.webp";
import leadershipTrainingImage from "src/assets/addOns/leadershipTraining.webp";
import vaccinationTrainingImage from "src/assets/addOns/vaccinationTraining.webp";
import certificateImage from "src/assets/foundationTrainingProgrammeFeatures/certificate.webp";
import oneToOneCheckInCallsImage from "src/assets/foundationTrainingProgrammeFeatures/check_in_calls.webp";
import examRevisionCoursesImage from "src/assets/foundationTrainingProgrammeFeatures/exam_revision_courses.webp";
import lifetimeTelegramAccessImage from "src/assets/foundationTrainingProgrammeFeatures/lifetime_telegram_access.webp";
import liveOnlineStudySessionsImage from "src/assets/foundationTrainingProgrammeFeatures/live_online_study_sessions.webp";
import mockPaperImage from "src/assets/foundationTrainingProgrammeFeatures/mock_paper.webp";
import programmeResourcesImage from "src/assets/foundationTrainingProgrammeFeatures/programme_resources.webp";
import progressTrackerImage from "src/assets/foundationTrainingProgrammeFeatures/progress_tracker.webp";
import questionBankImage from "src/assets/foundationTrainingProgrammeFeatures/question_bank.webp";
import klarnaLogo from "src/assets/klarna.png";
import { NewTabLink } from "src/components/NewTabLink";
import { AccessContext } from "src/context/AccessContext";
import PageTitle from "src/custom_components/PageTitle";
import useResponsive from "src/hooks/useResponsive";
import ResourceModelType from "src/models/ResourceModelType";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import LearnMoreButtonWithModal from "src/newComponents/buttons/LearnMoreButtonWithModal";
import PreviewLinkButton from "src/newComponents/buttons/PreviewLinkButton";
import RenderCost from "src/newComponents/RenderCost";
import PageTemplate from "src/pages/PageTemplate";
import { resourceCanBePurchased } from "src/repository/resourceRepository";

import AddOnItem from "./components/AddOnItem";
import KlarnaPayment from "./components/KlarnaPayment";
import MoneyBackGuaranteeButton from "./components/MoneyBackGuaranteeButton";
import MyCard from "./MyCard";

const ProgrammeDetailsPage = () => {
  const { getResourceModelByResourceType } = useContext(AccessContext);

  const resourceModel = getResourceModelByResourceType(ResourceModelType.foundationTrainingProgramme);

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/foundation-training-programme/" }]}>
        <title>Foundation Pharmacist Training Programme | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Join our comprehensive Foundation Pharmacist Training Programme. Featuring a GPhC-approved training plan, GPhC style question bank and mock assessment. 365 days access. Start your foundation training with us today!"
        />
        <meta
          name="keywords"
          content="Foundation pharmacist training programme, Preregistration training, GPhC mock assessment, GPhC questions, Foundation training"
        />
      </Helmet>
      <PageTemplate>
        <MyCard customStyle={{ marginTop: 0 }}>
          <PageTitle title="Foundation Pharmacist Training Programme" />

          <p>
            Our programme supports your journey, with 365 days access to everything you need to succeed in your
            registration assessment and beyond!
          </p>

          <p>
            Working with leading training providers ECG & NPA providing Vaccination, First Aid, Clinical Assessment &
            Leadership Training (optional add ons).
          </p>

          <p>Trainee Pharmacists: You can enrol now or through your training provider.</p>
          <p>
            Pharmacy Contractor/Organisation: Enrol your Trainee now to access your FREE designated supervisor support
            programme.
          </p>
          <p>
            Please contact our support team{" "}
            <a href="mailto:info@preregshortcuts.com" className="faqPageLink" target="_blank" rel="noreferrer">
              info@preregshortcuts.com
            </a>{" "}
            to book a call, discuss your training requirements and invoices for 2024/25.
          </p>

          <h3>What's Included?</h3>

          <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ mb: 1 }}>
            {sections.map((section, idx) => {
              return (
                <Grid item key={idx} xs={12} sm={6} md={4}>
                  <Section section={section} />
                </Grid>
              );
            })}
          </Grid>

          {resourceModel && (
            <>
              {resourceCanBePurchased(resourceModel) && (
                <Box sx={{ position: "relative", width: "150px", ml: 2, mb: 5 }}>
                  <RenderCost resourceModel={resourceModel} />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  gap: { xs: "0.25rem", sm: "0.5rem" },
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "flex-start",
                }}
              >
                <AddResourceToBasket resourceModel={resourceModel} />
                <MoneyBackGuaranteeButton />
              </Box>
            </>
          )}
          {resourceModel && resourceCanBePurchased(resourceModel) && (
            <div style={{ marginTop: "1rem" }}>
              <KlarnaPayment cost={resourceModel.cost} />
              <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <img
                  src={klarnaLogo}
                  alt="Klarna"
                  title="Klarna"
                  style={{ width: "70px", height: "39px" }}
                  loading="eager"
                />
                <p>
                  Now available with no deposit, interest free instalments split over 12 months, with full access to
                  your programme straight away. <br />
                  <NewTabLink href="https://www.klarna.com/uk/terms-and-conditions/">Klarna T&C's</NewTabLink> apply.
                  For more information contact our support team.
                </p>
              </div>
            </div>
          )}
        </MyCard>

        <MyCard customStyle={{ marginTop: "2rem" }}>
          <PageTitle title="Optional Additional Training" />

          <p>
            To include these additional training options you must purchase the Foundation Pharmacist Training Programme
            above then contact our support team{" "}
            <a href="mailto:info@preregshortcuts.com" className="faqPageLink" target="_blank" rel="noreferrer">
              info@preregshortcuts.com
            </a>{" "}
            for further information, they are not included as standard in our Programme.
          </p>

          <p>Working with leading Training Providers including ECG & NPA here you'll find even more value.</p>

          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            spacing={{
              xs: 1,
              sm: 2,
            }}
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            sx={{
              mt: {
                xs: 3,
                sm: 2,
              },
              mb: {
                xs: 2,
                sm: 2,
              },
            }}
          >
            <Typography variant="h4">Add-ons</Typography>

            <Stack direction="row" spacing={1} sx={{ mb: { xs: 1 } }}>
              <LearnMoreButtonWithModal
                title="Add-ons"
                content={
                  <>
                    <p style={{ marginTop: 0 }}>
                      Here you'll find additional training options provided by our collaborations with external training
                      providers ECG & NPA, you'll receive instructions within 10 days of enrolling.
                    </p>

                    <ul style={{ marginBottom: 0 }}>
                      <li>
                        For First Aid & Clinical Assessment Training & Leadership Training, you will receive login
                        details via email within 10 working days.
                      </li>
                      <li>
                        Enrolled on the Foundation Pharmacist Training Programme? You're eligible for a 30% discount
                        code from Vaccination Training with our collaboration with ECG. To access your unique discount
                        code and book your training, email{" "}
                        <NewTabLink href="mailto:info@preregshortcuts.com">info@preregshortcuts.com</NewTabLink> and
                        request your code today.
                      </li>
                    </ul>
                  </>
                }
              />
              <PreviewLinkButton link={"/additional-training-options/"} />
            </Stack>
          </Stack>

          <AddOnItem
            addOn={{
              title: "First Aid & Clinical Assessment Training",
              cost: 49,
              originalCost: 49,
              checkoutDescription:
                "This is 3 hour online training module created specifically for Trainees enrolled in our Foundation Pharmacist Training Programme.",
              image: firstAidClinicalTrainingImage,
            }}
          />
          <AddOnItem
            addOn={{
              title: "Vaccination Training",
              cost: 99,
              originalCost: 99,
              checkoutDescription:
                "Giving Trainee Pharmacists the opportunity to vaccinate during the Foundation Training Year will boost their confidence, there's more value booking the training through us.",
              image: vaccinationTrainingImage,
            }}
          />
          <AddOnItem
            addOn={{
              title: "Leadership Training",
              cost: 49,
              originalCost: 49,
              checkoutDescription:
                "We work with NPA to provide a 2 hour leadership training module, this is available through the NPA website but you'll have better value enrolling through us.",
              image: leadershipTrainingImage,
            }}
          />
        </MyCard>
      </PageTemplate>
    </>
  );
};

export { ProgrammeDetailsPage };

const Section = ({ section }: { section: Section }) => {
  const { title, subtitle, content, icon, link } = section;
  const isMdUp = useResponsive("up", "md");
  const hasLink = link !== undefined;

  const iconWidth = isMdUp ? "80%" : "auto";
  return (
    <MyCard customStyle={{ padding: "0" }}>
      <img
        src={icon}
        alt={title}
        title={title}
        style={{ width: iconWidth, height: "auto", margin: "auto" }}
        loading="eager"
      />

      <div style={{ padding: "1rem" }}>
        <h3 style={{ textAlign: "center", marginTop: "6px", marginBottom: "6px" }}>{title}</h3>
        <p style={{ textAlign: "center" }}>{subtitle}</p>

        <p style={{ textAlign: "center" }}>
          <LearnMoreButtonWithModal title={title} content={content} rightMargin={hasLink} />
          <PreviewLinkButton link={link} />
        </p>
      </div>
    </MyCard>
  );
};

interface Section {
  title: string;
  subtitle: string;
  icon: string;
  link?: string;
  content: JSX.Element;
}

const sections: Section[] = [
  {
    title: "Exam Revision Courses",
    subtitle: "Over 100 hours of interactive recorded lessons for BNF, Calculations, OTC & MEP",
    icon: examRevisionCoursesImage,
    link: "/courses/",
    content: (
      <>
        Instant access to over 100 hours of recorded interactive video lessons covering all our courses for BNF,
        Calculations, MEP & OTC, all our exam revision courses are aligned to the GPhC framework and you'll receive
        access straight away after your purchase.
      </>
    ),
  },
  {
    title: "Live Online Study Sessions",
    subtitle: "50+ interactive online live study sessions with experienced tutors and guest speakers",
    icon: liveOnlineStudySessionsImage,
    link: "/live-online-study-sessions/",
    content: (
      <>
        Join our Programme and access more than 50 live study sessions which take place throughout the foundation
        training year, engage directly with tutors and guest speakers, Can't attend live? No problem! You'll have access
        to all recorded sessions to watch at your convenience.
      </>
    ),
  },
  {
    title: "Programme Resources",
    subtitle: "Workbooks, revision checklists mapped to the GPhC learning outcomes",
    icon: programmeResourcesImage,
    link: "/programme-resources/",
    content: (
      <>
        Brand new this year: Our programme resources includes:
        <ul>
          <li>Workbooks: Designed to complement our GPhC exam revision courses.</li>
          <li>
            Study Guides: Mapped to the GPhC framework and learning outcomes to reinforce and apply your knowledge.
          </li>
          <li>Revision Checklists: To ensure complete confidence throughout your foundation training journey.</li>
        </ul>
      </>
    ),
  },
  {
    title: "Access 1000+ GPhC Style Questions",
    subtitle: "1000+ GPhC style practice questions mapped to the GPhC framework",
    icon: questionBankImage,
    link: "/question-bank/",
    content: (
      <>
        It's important to practice questions that are up to date. Our GPhC style question bank contains over 1000+
        Questions, separated into different categories and weightings. Joining our foundation training programme will
        give you 365 days access to our Question Bank.
      </>
    ),
  },
  {
    title: "Mock Paper",
    subtitle: "Full online GPhC assessment mock paper (Calculations & Clinical)",
    icon: mockPaperImage,
    link: "/mock-paper/",
    content: (
      <>
        Experience the full GPhC exam day simulation with our comprehensive online mock paper, which covers Part 1
        Calculations and Part 2 Clinical papers. Boost your confidence, refine your exam strategy, and excel on exam day
        with our popular mock paper.
      </>
    ),
  },
  {
    title: "Lifetime Support Group Access",
    subtitle: "Revise and network with Trainee Pharmacists on our online community ",
    icon: lifetimeTelegramAccessImage,
    content: (
      <>
        Once you enrol, we'll add you to our Telegram Messenger Group with over 2000 Trainees & Pharmacists. Join our
        Pre-Reg Shortcuts community, benefit from our support network which also includes mindmaps, diagrams & summary
        sheets.
      </>
    ),
  },
  {
    title: "One to One Check in Calls",
    subtitle: "Monthly phone/video call to check in with you",
    icon: oneToOneCheckInCallsImage,
    content: (
      <>
        We'll check in with you once a month for a one on one phone / video call, personalised support for your training
        year.
        <br />
        <br />
        This will also be a good opportunity to discuss your three formal progress reports at week 13, 26, 39 in your
        Foundation Training Year, and to monitor your learning throughout your training.
      </>
    ),
  },
  {
    title: "Progress Tracker",
    subtitle: "Track and monitor your progress",
    icon: progressTrackerImage,
    content: (
      <>
        Monitor your progress, set personal milestones, and track how much of the programme you've completed. Stay
        motivated and focused on your study goals and succeed through out your foundation training year
      </>
    ),
  },
  {
    title: "Certificate Of Achievement",
    subtitle: "Certificates for CPD",
    icon: certificateImage,
    content: (
      <>
        You'll receive a certificate at the end of the year, use this certificate as a way to celebrate completing the
        programme and for your CPD.
      </>
    ),
  },
];
