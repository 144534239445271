import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";

const PreviewLinkButton = ({
  link,
  label,
  openInSameTab = false,
}: {
  link: string | undefined | null;
  label?: string;
  openInSameTab?: boolean;
}) => {
  if (!link) return null;

  const target = openInSameTab ? "_self" : "_blank";

  return (
    <Button variant="outlined" startIcon={<VisibilityIcon />} href={link} target={target} color="primary" size="small">
      {label ?? "Preview"}
    </Button>
  );
};

export default PreviewLinkButton;
