export const NAV = {
  W_DRAWER: 280,
  H_ITEM: 48,
};

export const ICON = {
  NAV_ITEM: 24,
};

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 94,
};

export const toastOptions = {
  error: { duration: 6000 },
  success: { duration: 4000 },
  style: { padding: "0.75rem" },
};
