import Button from "@mui/material/Button";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { errorToast } from "src/components_with_stories/toast";
import { AccessContext } from "src/context/AccessContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import QuestionSetAttemptModel from "src/models/QuestionSetAttemptModel";
import QuestionSetModel from "src/models/QuestionSetModel";
import ResourceModel from "src/models/ResourceModel";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import QuestionSetRepostiory from "src/repository/questionSetRepository";
import DateService, { DateType } from "src/utils/dateService";

const QuestionSet = ({ questionSet }: { questionSet: QuestionSetModel }) => {
  const { getResourceModelByResourceId } = useContext(AccessContext);
  const resourceModel = getResourceModelByResourceId(questionSet.resourceId);

  // for a question set, if they are both not null, the user has not started
  const userHasStarted = resourceModel?.access?.accessBegins !== null && resourceModel?.access?.accessEnds !== null;

  return (
    <>
      <h3>{questionSet.title}</h3>

      {resourceModel && (
        <AddResourceToBasket
          resourceModel={resourceModel}
          renderAccess={() => {
            if (!userHasStarted) {
              return <AccessMockPaper inputQuestionSet={questionSet} inputResourceModel={resourceModel} />;
            }

            if (resourceModel.access?.accessEnded) {
              return <p>Your access has ended. You can no longer review this paper.</p>;
            }

            return (
              <>
                {resourceModel.access?.accessEnds && (
                  <p>
                    You have <TimeRemaining accessEnds={resourceModel.access.accessEnds} /> to review feedback.
                  </p>
                )}

                <ReviewMockPaper inputQuestionSet={questionSet} inputResourceModel={resourceModel} />
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default QuestionSet;

const AccessMockPaper = ({
  inputQuestionSet,
  inputResourceModel,
}: {
  inputQuestionSet: QuestionSetModel;
  inputResourceModel: ResourceModel;
}) => {
  const navigate = useNavigate();

  const setQuestionSet = useLocalStorage<QuestionSetModel>("mp_questionSet")[1];

  const openMockPaper = () => {
    const accessEnds = inputResourceModel.access?.accessEnds?.toISOString();
    const qs: QuestionSetModel = { ...inputQuestionSet, accessEnds };
    setQuestionSet(qs);
    navigate(`/attempt-mock-paper/`);
  };

  return (
    <>
      <p>You have not started this paper yet. </p>
      <Button color="secondary" variant="contained" onClick={openMockPaper}>
        Access
      </Button>
    </>
  );
};

const ReviewMockPaper = ({
  inputQuestionSet,
  inputResourceModel,
}: {
  inputQuestionSet: QuestionSetModel;
  inputResourceModel: ResourceModel;
}) => {
  const questionSetRepositry = new QuestionSetRepostiory();
  const navigate = useNavigate();
  const isLightMode = useIsLightMode();

  const setQuestionSet = useLocalStorage<QuestionSetModel>("mp_questionSet")[1];
  const setQuestionSetAttempt = useLocalStorage<QuestionSetAttemptModel>("mp_questionSetAttempt")[1];

  const openMockPaper = () => {
    const accessEnds = inputResourceModel.access?.accessEnds?.toISOString();
    const qs: QuestionSetModel = { ...inputQuestionSet, accessEnds };

    questionSetRepositry
      .getAttempt(qs.questionSetId, qs.duration)
      .then((attempt: QuestionSetAttemptModel) => {
        setQuestionSet(qs);
        setQuestionSetAttempt(attempt);

        navigate(`/attempt-mock-paper/feedback`);
      })
      .catch((error) => {
        console.error("Error getting attempt", error);
        errorToast("Unexpected error retrieving attempt. Please contact support.", isLightMode);
      });
  };

  return (
    <Button color="primary" variant="contained" onClick={openMockPaper}>
      Review
    </Button>
  );
};

const TimeRemaining = ({ accessEnds }: { accessEnds: DateType }) => {
  const numHoursLeft = accessEnds.diff(DateService.newDate(), "hour");
  const numDaysLeft = accessEnds.diff(DateService.newDate(), "day");

  if (numHoursLeft < 1) {
    const numMinutes = accessEnds.diff(DateService.newDate(), "minute");
    return (
      <>
        {numMinutes} {pluralize("minute", numMinutes)}
      </>
    );
  } else if (numDaysLeft < 1) {
    const numHours = accessEnds.diff(DateService.newDate(), "hour");
    return (
      <>
        {numHours} {pluralize("hour", numHours)}
      </>
    );
  }
  const numHours = accessEnds.diff(DateService.newDate(), "hour") - numDaysLeft * 24;
  return (
    <>
      {numDaysLeft} {pluralize("day", numDaysLeft)}, {numHours} {pluralize("hour", numHours)}
    </>
  );
};

const pluralize = (word: string, num: number) => (num === 1 ? word : word + "s");
