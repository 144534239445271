import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";

import ecgLogoImage from "src/assets/addOns/ecgLogo.webp";
import firstAidClinicalTrainingImage from "src/assets/addOns/firstAidAndClinicalAssessmentTraining.webp";
import leadershipTrainingImage from "src/assets/addOns/leadershipTraining.webp";
import npaLogoImage from "src/assets/addOns/npaLogo.webp";
import vaccinationTrainingImage from "src/assets/addOns/vaccinationTraining.webp";
import { NewTabLink } from "src/components/NewTabLink";
import { FAQContext } from "src/context/FAQContext";
import MiniFAQsSPC from "src/newComponents/MiniFAQsSPC";

import AddOnCourse from "../courses/components/AddOnCourse";
import PageTemplate from "../PageTemplate";

const AdditionalTrainingOptionsPage = () => {
  const { getContentForPage } = useContext(FAQContext);
  const miniFaqContent = getContentForPage("Additional Training Options");

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/additional-training-options/" }]}>
        <title>Additional Training Options | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Enhance your foundation pharmacist training programme with additional value-added options, We offer vaccination training, first aid and clinical assessment training, and leadership training thanks to collaborations with NPA and ECG."
        />
        <meta
          name="keywords"
          content="pharmacy training options, vaccination training, first aid training, clinical assessment training, leadership training, additional pharmacy courses, foundation pharmacist training, pharmacy skills development"
        />
      </Helmet>
      <PageTemplate title="Additional Training Options">
        <Typography sx={{ margin: "1rem 0" }}>
          Pre-Reg Shortcuts supports you during the Foundation Training Year and beyond! Working with leading training
          providers in Pharmacy including ECG & NPA to bring you lots of value. Our additional training options are only
          available on anyone enrolled on the full Foundation Pharmacist Training Programme. Vaccination Training
          includes a 30% discount.
        </Typography>
        <Typography sx={{ margin: "1rem 0" }}>
          Contact our team <NewTabLink href="mailto:info@preregshortcuts.com">info@preregshortcuts.com</NewTabLink> for
          more info on how to enrol and access these training options.
        </Typography>
        <Divider sx={{ mt: 3 }} style={{ borderColor: "transparent" }} />

        <Stack spacing={4}>
          {addOns.map((addOn, idx) => (
            <AddOnCourse key={idx} {...addOn} />
          ))}
        </Stack>
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <MiniFAQsSPC topics={miniFaqContent} />
      </PageTemplate>
    </>
  );
};

export default AdditionalTrainingOptionsPage;

const addOns = [
  {
    title: "First Aid & Clinical Assessment Training",
    description:
      "This 3 hour online course equips Trainee Pharmacists with essential skills. Gain expertise in gathering medical histories and mastering consultation skills. Handle real life scenarios with confidence with our first aid course covering CPR and managing common injuries, you'll receive a certificate after completion.",
    image: firstAidClinicalTrainingImage,
    cost: 49,
    originalCost: 49,
    provider: {
      name: "ECG",
      image: ecgLogoImage,
    },
  },
  {
    title: "Vaccination Training",
    description:
      "Face to Face Training at a training venue of your choice! This training is necessary to administer vaccinations in your Pharmacy, we're working with a leading Vaccination Training provider so you have the skills and confidence to administer vaccines from day 1, there's a 30% discount available for you.",
    image: vaccinationTrainingImage,
    cost: 99,
    originalCost: 99,
    provider: {
      name: "ECG",
      image: ecgLogoImage,
    },
  },
  {
    title: "Leadership Training",
    description:
      "This 2 hour online course is designed to build essential leadership skills, crucial for setting yourself apart in the competitive field of Pharmacy. The course covers leadership responsibilities, team member profiling, effective communication, and more, culminating in an MCQ quiz with a certificate.",
    image: leadershipTrainingImage,
    cost: 49,
    originalCost: 49,
    provider: {
      name: "NPA",
      image: npaLogoImage,
    },
  },
];
