import { CookieConsentBanner, triggerCookieConsentBanner } from "@porscheofficial/cookie-consent-banner-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";

import ScrollToTop from "src/newComponents/ScrollToTop";

import AuthWrapper from "./AuthWrapper";
import Footer from "./components/main/footer/Footer";
import { Header } from "./components/main/header/Header";
import { SettingsProvider } from "./components/settings/SettingsContext";
import { ThemeSettings } from "./components/settings/ThemeSettings";
import { toastOptions } from "./config-global";
import { AccessContextProvider } from "./context/AccessContext";
import { AppContextProvider } from "./context/AppContext";
import { BasketContextProvider } from "./context/BasketContext";
import { CoursesContextProvider } from "./context/CoursesContext";
import { FAQContextProvider } from "./context/FAQContext";
import useResponsive from "./hooks/useResponsive";
import ItemsInCartBottomBar from "./pages/products/components/ItemsInCartBottomBar";
import ThemeProvider from "./theme/index";
import { footer_routes, routes } from "./utils/routes";

export const LIGHT_MODE_TOAST_STYLE = {
  borderRadius: "10px",
  background: "#333",
  color: "#fff",
};

export const DARK_MODE_TOAST_STYLE = {};

function App() {
  const [acceptedCategories, setAcceptedCategories] = useState<string[]>([]);
  const isMobile = useResponsive("down", "sm");

  const initConsent = ({ acceptedCategories }: { acceptedCategories: string[] }) => {
    setAcceptedCategories(acceptedCategories);
  };

  useEffect(() => {
    const banners = document.getElementsByTagName("cookie-consent-banner");
    if (banners && banners.length > 0) {
      const banner = banners[0];
      const isMdUp = window.innerWidth >= 768;

      const additionalMdUpStyle = document.createElement("style");
      additionalMdUpStyle.innerHTML =
        "form .cc_text { padding-bottom: 0 !important; } form .cc_settings { padding-top: 1rem !important; padding-bottom: 0rem !important; }";

      const style = document.createElement("style");
      style.innerHTML =
        "button { padding: 0.4rem 0.75rem !important; background-color: #00853C !important; border-color: #00853C !important; }";

      banner?.shadowRoot?.appendChild(style);
      if (isMdUp) {
        banner?.shadowRoot?.appendChild(additionalMdUpStyle);
      }
    }
  }, []);

  const userHasNotRespondedToCookie = document.cookie.indexOf("cookies_accepted_categories=") === -1;

  const userHasAcceptedCookie = acceptedCategories.includes("analytics");
  const validCookieResponse = userHasNotRespondedToCookie || userHasAcceptedCookie;

  const isDevUrl = window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1");
  const isStaging = import.meta.env.VITE_ENV.toLowerCase() === "staging";
  const showScript = validCookieResponse && !isDevUrl;

  return (
    <div className="container">
      <Helmet>
        {showScript && <script async src="https://www.googletagmanager.com/gtag/js?id=G-CFNLBHW3ZE" />}
        {isStaging && <meta name="robots" content="noindex" />}
      </Helmet>
      <CookieConsentBanner
        handlePreferencesUpdated={initConsent}
        handlePreferencesRestored={initConsent}
        btnLabelAcceptAndContinue="I Agree"
        btnLabelSelectAllAndContinue="Select all and continue"
        btnLabelPersistSelectionAndContinue="Save and continue"
        contentSettingsDescription="You can decide which cookies are used by selecting the respective options below. Please note that your selection may impair in the functionality of the service."
        availableCategories={allCategories}
      >
        <span style={{ fontSize: "16px" }}>
          This website uses cookies. Let us know if you'd like to opt out by clicking on
        </span>{" "}
        <button
          onClick={() => {
            triggerCookieConsentBanner({ showDetails: true });
          }}
          type="button"
          style={{
            background: "none",
            border: "none",
            color: "inherit",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "16px",
            paddingRight: 0,
            paddingLeft: 0,
          }}
        >
          privacy preferences
        </button>
        .{" "}
      </CookieConsentBanner>
      <Toaster position="top-center" containerStyle={isMobile ? { top: 70 } : undefined} toastOptions={toastOptions} />
      <SettingsProvider>
        <ThemeProvider>
          <ThemeSettings>
            <AppContextProvider>
              <AccessContextProvider>
                <BasketContextProvider>
                  <CoursesContextProvider>
                    <FAQContextProvider>
                      <AuthWrapper>
                        <ItemsInCartBottomBar />
                        <ScrollToTop />
                        <Header isStaging={isStaging} />
                        <Routes>
                          {routes.map((route) => {
                            return <Route key={route.path} path={route.path} element={route.element} />;
                          })}
                          {footer_routes.map((route) => {
                            return <Route key={route.path} path={route.path} element={route.element} />;
                          })}
                        </Routes>
                        <Footer />
                      </AuthWrapper>
                    </FAQContextProvider>
                  </CoursesContextProvider>
                </BasketContextProvider>
              </AccessContextProvider>
            </AppContextProvider>
          </ThemeSettings>
        </ThemeProvider>
      </SettingsProvider>
    </div>
  );
}

export default App;

const allCategories = [
  {
    description: "Enable you to navigate and use the basic functions and to store preferences.",
    key: "technically_required",
    label: "Technically necessary cookies",
    isMandatory: true,
  },
  {
    description:
      "Enable us to determine how visitors interact with our service in order to improve the user experience.",
    key: "analytics",
    label: "Analytics cookies",
  },
];
