import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import React, { Suspense } from "react";
import { lazily } from "react-lazily";

import { Iconify } from "src/components/Iconify";
import useResponsive from "src/hooks/useResponsive";
import { useWindowResize } from "src/hooks/useWindowResize";

const { TransformWrapper, TransformComponent } = lazily(() => import("react-zoom-pan-pinch"));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuestionResource = ({ resourceImageUrl }: { resourceImageUrl: string | undefined | null }) => {
  const [open, setOpen] = React.useState(false);
  const windowHeight = useWindowResize();

  const isSmUp = useResponsive("up", "sm");
  const largerImage = isSmUp;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (resourceImageUrl === undefined) return null;
  if (resourceImageUrl === null) return null;
  if (resourceImageUrl.length === 0) return null;

  const imageMaxHeightSmall = windowHeight - 150 + "px";
  const imageMaxHeightLarge = windowHeight * 2 + "px";
  const imageMaxHeight = largerImage ? imageMaxHeightLarge : imageMaxHeightSmall;

  return (
    <div>
      <Button size="small" variant="outlined" color="inherit" onClick={handleClickOpen} sx={{ mt: 0, mb: 2 }}>
        <Iconify icon="ion:open-outline" sx={{ mr: 0.8 }} style={{ width: "20px", height: "auto" }} />
        <Typography variant="body2">View Resource</Typography>
      </Button>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }} color="default">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Question Resource
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <TransformWrapper
              initialScale={1}
              // panning={{ disabled: true }} -- laptop, move around
              // wheel={{ disabled: true }} -- laptop zoom using wheel
              // pinch={{ disabled: true }} -- assumed to be pinch on phone
              doubleClick={{ disabled: true }}
              // alignmentAnimation={{ disabled: true }}
              velocityAnimation={{ disabled: true }}
            >
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "1rem",
                      margin: "1rem",
                    }}
                  >
                    <IconButton color="inherit" onClick={() => zoomOut(0.25)} aria-label="Zoom out">
                      <ZoomOutIcon />
                    </IconButton>

                    <IconButton color="inherit" onClick={() => zoomIn(0.25)} aria-label="Zoom in">
                      <ZoomInIcon />
                    </IconButton>

                    <Button
                      variant="outlined"
                      color="inherit"
                      onClick={() => resetTransform()}
                      size="small"
                      sx={{ mt: 0.6 }}
                    >
                      Reset
                    </Button>
                  </div>

                  <TransformComponent
                    wrapperStyle={{
                      display: "block",
                      margin: "auto",
                      maxWidth: "85vw",
                      border: "1px solid grey",
                    }}
                  >
                    <img
                      src={resourceImageUrl}
                      alt="Question Resource"
                      loading="eager"
                      title="Question Resource"
                      style={{
                        maxHeight: imageMaxHeight,
                        width: "auto",
                      }}
                    />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </Suspense>
        </div>
      </Dialog>
    </div>
  );
};

export default QuestionResource;
