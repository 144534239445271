import Alert from "@mui/material/Alert";

import { Iconify } from "src/components/Iconify";

const NoAccessToFTP = ({ accessToText }: { accessToText: string }) => {
  return (
    <Alert sx={{ mt: 2 }} icon={<Iconify width={24} icon={"carbon:locked"} />} severity="warning">
      Access to {accessToText} is only available to Foundation Training Programme members. <br />
      For more details, check out our{" "}
      <a className="faqPageLink" target="_blank" href={"/foundation-training-programme/"} rel="noreferrer">
        Foundation Pharmacist Training Programme
      </a>
      .
    </Alert>
  );
};

export default NoAccessToFTP;
