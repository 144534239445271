import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext } from "react";

import { CoursesContext } from "src/context/CoursesContext";
import RoutedButton from "src/newComponents/buttons/RoutedButton";

const LinkToCourses = () => {
  const { courses } = useContext(CoursesContext);
  const applicableCourses = courses.filter((course) => course.includedInPremium);

  return (
    <div>
      <Typography sx={{ margin: "1rem 0" }}>
        Our Live Online Study Sessions are designed to compliment the exam revision courses:
      </Typography>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={2}
      >
        {applicableCourses.map((course) => {
          return (
            <RoutedButton key={course.id} path={`/courses/${course.slug}`} buttonColor="secondary" size="small">
              {course.title}
            </RoutedButton>
          );
        })}
      </Stack>
    </div>
  );
};

export default LinkToCourses;
