import { alpha, ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import { merge } from "lodash-es";
import React, { useMemo } from "react";

import { useSettingsContext } from "./SettingsContext";

export function ThemeSettings({ children }: React.PropsWithChildren) {
  const outerTheme = useTheme();

  const { presetsColor } = useSettingsContext();

  const themeOptions = useMemo(
    () => ({
      palette: {
        primary: presetsColor.primary,
        secondary: presetsColor.secondary,
      },
      customShadows: {
        primary: `0 8px 16px 0 ${alpha(presetsColor.primary.main, 0.24)}`,
        secondary: `0 8px 16px 0 ${alpha(presetsColor.secondary.main, 0.24)}`,
      },
    }),
    [presetsColor]
  );

  const theme = createTheme(merge(outerTheme, themeOptions));

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
