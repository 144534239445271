import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function MoreInformationModal({
  title,
  content,
  handleClose,
}: {
  title: string;
  content: React.ReactNode;
  handleClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  return (
    <div
      style={{ display: "inline-block" }}
      onClick={(e) => {
        // Prevent the event from bubbling up to the parent element
        e.stopPropagation();
      }}
    >
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ paddingRight: "2.75rem" }}>
          <span>{title}</span>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }}>
          <Typography>{content}</Typography>
        </DialogContent>
        <DialogActions style={{ paddingTop: "0.5rem" }}>
          <Button color="inherit" variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
