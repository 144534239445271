import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { BasketContext } from "src/context/BasketContext";
import useResponsive from "src/hooks/useResponsive";
import RoutedButton from "src/newComponents/buttons/RoutedButton";

export default function ItemsInCartBottomBar() {
  const { items } = useContext(BasketContext);
  const isSmUp = useResponsive("up", "sm");
  const numItemsInBasket = items.length;

  const location = useLocation();

  // Hide the bottom bar on the maintenance page and checkout page
  const pagesToHideBottomBar = ["/maintenance/", "/checkout/"];
  if (pagesToHideBottomBar.includes(location.pathname)) return null;

  if (numItemsInBasket === 0) return null;

  const toolbarStyle = isSmUp
    ? {
        marginRight: 0,
        marginLeft: "auto",
      }
    : {
        margin: "auto",
      };

  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        top: "auto",
        bottom: 0,
      }}
    >
      <Toolbar style={toolbarStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: isSmUp ? "1.5rem" : "0.5rem",
            alignItems: "center",
            flexDirection: isSmUp ? "row" : "column",
          }}
        >
          <Typography variant="body1" style={{ marginTop: isSmUp ? 0 : "1rem", fontWeight: "bold" }}>
            You have {numItemsInBasket} item{numItemsInBasket !== 1 && "s"} in your basket!
          </Typography>

          <RoutedButton
            path="/checkout/"
            variant="contained"
            buttonColor="inherit"
            buttonStyle={{
              backgroundColor: "#009d47",
              marginBottom: isSmUp ? 0 : "1rem",
            }}
          >
            Proceed To Checkout
          </RoutedButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}
