import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export default function StepLabel({ step, title, body }: { step: string; title: string; body: string }) {
  return (
    <Stack direction="column">
      <Stack direction="row" alignItems="center" sx={{ mb: 1, typography: "h6" }}>
        <Box
          sx={{
            mr: 1.5,
            width: 28,
            height: 28,
            flexShrink: 0,
            display: "flex",
            typography: "h6",
            borderRadius: "50%",
            alignItems: "center",
            bgcolor: "primary.main",
            justifyContent: "center",
            color: "primary.contrastText",
          }}
        >
          {step}
        </Box>
        {title}
      </Stack>
      <p style={{ marginLeft: "calc(28px + 12px)", marginTop: 0 }}>{body}</p>
    </Stack>
  );
}
