import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMemo, useState } from "react";

import { Iconify } from "src/components/Iconify";
import { FAQSection } from "src/context/FAQContext";
import SupportContent from "src/newComponents/SupportContent";

interface SupportViewProps {
  faqPageTopics: FAQSection[];
}

export default function SupportView({ faqPageTopics }: SupportViewProps) {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredTopics = useMemo(() => {
    if (searchTerm === "") return faqPageTopics;
    const filteredTopics = faqPageTopics.filter((topic) => {
      const topicQuestions = topic.content.map((content) => content.question);
      const topicQuestionIncludes = topicQuestions.filter((question) => {
        return question.toLowerCase().includes(searchTerm.toLowerCase());
      });
      const answerIncludes = topic.content.filter((content) => {
        return content.rawAnswer.toLowerCase().includes(searchTerm.toLowerCase());
      });
      return topicQuestionIncludes.length > 0 || answerIncludes.length > 0;
    });
    return filteredTopics;
  }, [searchTerm]);

  return (
    <>
      <Typography sx={{ margin: "1rem 0" }}>
        Find the answers to most of your questions via our FAQ page, however, if you still need to contact us the
        quickest way to reach us is through Live Chat at the bottom right hand corner of your screen.
      </Typography>
      <Typography sx={{ margin: "1rem 0" }}>
        Waiting on a response? One of our team will be in touch as soon as possible to provide you with an update.
      </Typography>

      <TextField
        fullWidth
        hiddenLabel
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="carbon:search" width={24} sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
        sx={{ mt: 3, mb: { xs: 3, sm: 6 } }}
        onChange={(event) => {
          setSearchTerm(event.target.value);
        }}
      />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          },
        }}
      >
        {filteredTopics.length === 0 && <Typography>No questions found</Typography>}
        {filteredTopics.map((topic) => {
          return (
            <Card
              key={topic.title}
              sx={{
                height: "fit-content",
                margin: {
                  xs: "2rem 0rem",
                  sm: "0rem 2.5rem 2.5rem 0rem",
                },
                padding: {
                  xs: "1rem 1rem 0 1rem",
                },
              }}
            >
              <Typography variant="h4" style={{ marginBottom: 2, color: "#FA541C" }}>
                {topic.title}
              </Typography>

              <Stack direction="row">
                <SupportContent contents={topic.content} searchTerm={searchTerm} />
              </Stack>
            </Card>
          );
        })}
      </Box>
    </>
  );
}
