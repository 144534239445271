import axios from "axios";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import { QUIZ_PREVIEW_MODE } from "src/components_with_stories/QuizStates";
import { errorToast } from "src/components_with_stories/toast";
import { AppContext } from "src/context/AppContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import { AttemptedQuestionDto } from "src/repository/questionSetRepository";
import { TEN_MINUTES } from "src/utils/duration";

import QBQuestion from "../components_with_stories/qb_question/QBQuestion";
import { mapQuestionDtoToQuestionProps } from "../components_with_stories/QBQuiz";
import { adminGetQuestionEndpoint } from "../urls";

const PreviewQuestionPage = () => {
  const { questionId } = useParams();
  const { isAuthenticated, isSuperuser } = useContext(AppContext);
  const navigate = useNavigate();
  const [question, setQuestion] = useState<AttemptedQuestionDto | null>(null);
  const isLightMode = useIsLightMode();

  useEffect(() => {
    if (questionId !== undefined) {
      axios
        .get(adminGetQuestionEndpoint, {
          params: { question_id: parseInt(questionId) },
        })
        .then((response) => {
          const questionDto = response.data;
          const question = mapQuestionDtoToQuestionProps(questionDto, 1, 10);
          setQuestion(question);
        })
        .catch((error) => {
          const response = error.response;
          if (response && response?.status === 400) {
            errorToast("Question does not exist. ", isLightMode, TEN_MINUTES);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  if (!isAuthenticated) {
    const sessionCookie = Cookies.get("csrftoken");
    const sessionCookieExists = sessionCookie ? sessionCookie.length > 0 : false;
    if (sessionCookieExists) {
      console.log("Preview page - session cookie exists, waiting for global auth...");
      return <>Authenticating...</>;
    }
    navigate(`/login/?next=preview-question/${questionId}/`, { replace: true });
    return null;
  }
  if (!isSuperuser) navigate("/");

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/preview-question/" }]}>
        <title>Preview Question | Pre-Reg Shortcuts</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div style={{ padding: "2rem" }}>
        {question && (
          <QBQuestion
            obj={question}
            quizState={QUIZ_PREVIEW_MODE}
            nextQuestion={() => {}}
            backQuestion={() => {}}
            setAnswerId={() => {}}
            toggleFlag={() => {}}
            enterReviewMode={() => {}}
            returnToFeedback={() => {}}
          />
        )}
      </div>
    </>
  );
};

export default PreviewQuestionPage;
