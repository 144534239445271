import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";

import { Iconify } from "src/components/Iconify";
import { RenderMarkdown } from "src/components_with_stories/qb_question/RenderMarkdown";
import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import LessonModel from "src/models/LessonModel";
import { getVideoLockDetails, mapLockRequirementToToastMessage } from "src/utils/requirement/requirement";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&.Mui-expanded": {
    overflow: "hidden",
    borderRadius: "8px !important",
    marginBottom: theme.spacing(2.5),
    boxShadow: theme.customShadows.z16,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  "&.Mui-expanded": {
    minHeight: "auto",
    backgroundColor: theme.palette.action.selected,
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(2, 0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    "&.Mui-expanded": { margin: theme.spacing(2, 0) },
  },
}));

interface LessonItemProps {
  lesson: LessonModel;
  selected: boolean;
  expanded: boolean;
  onExpanded: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  onOpen: () => void;
  simplifiedView: boolean;
}

export default function LessonItem({
  lesson,
  selected,
  expanded,
  onExpanded,
  onOpen,
  simplifiedView,
}: LessonItemProps) {
  const isLightMode = useIsLightMode();

  const { isLocked, lockReason, lockRequirement } = getVideoLockDetails(lesson.accessible_video_url);
  const tooltipTitle = isLocked ? lockReason : "Play";

  const handleOpen = () => {
    if (isLocked) {
      const message = mapLockRequirementToToastMessage(lockRequirement);
      errorToast(message, isLightMode);
    } else {
      onOpen();
    }
  };

  if (simplifiedView) {
    return (
      <div style={{ display: "flex", marginTop: "1rem", gap: "0.5rem" }}>
        <Icon
          tooltipTitle={tooltipTitle}
          isLocked={isLocked}
          handleOpen={handleOpen}
          selected={selected}
          position="relative"
        />
        <div>
          <AccordionTitle
            isLocked={isLocked}
            title={lesson.title}
            selected={selected}
            simplifiedView={simplifiedView}
          />
          <RenderMarkdown text={lesson.description} />
        </div>
      </div>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Icon
        tooltipTitle={tooltipTitle}
        isLocked={isLocked}
        handleOpen={handleOpen}
        selected={selected}
        position="absolute"
      />
      <StyledAccordion expanded={expanded} onChange={onExpanded}>
        <StyledAccordionSummary>
          <AccordionTitle
            isLocked={isLocked}
            title={lesson.title}
            selected={selected}
            simplifiedView={simplifiedView}
          />
          <AccordionExpandCollapseIcon expanded={expanded} />
        </StyledAccordionSummary>

        <AccordionDetails style={{ padding: "0 1rem 1rem 1rem" }}>
          <RenderMarkdown text={lesson.description} />
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
}

interface IconProps {
  tooltipTitle: string | null;
  isLocked: boolean;
  handleOpen: () => void;
  selected: boolean;
  position: "absolute" | "relative";
}

const Icon = ({ tooltipTitle, isLocked, handleOpen, selected, position }: IconProps) => {
  const icon = selected ? "carbon:pause-outline" : "carbon:play";

  return (
    <Tooltip title={tooltipTitle}>
      <Iconify
        width={24}
        icon={isLocked ? "carbon:locked" : icon}
        onClick={handleOpen}
        sx={{
          mr: position === "absolute" ? 2 : 1,
          top: position === "absolute" ? 15 : 0,
          left: position === "absolute" ? 8 : 0,
          zIndex: 9,
          cursor: "pointer",
          position: position,
          ...(selected && {
            color: "primary.main",
          }),
        }}
      />
    </Tooltip>
  );
};

interface AccordionTitleProps {
  isLocked: boolean;
  title: string;
  selected: boolean;
  simplifiedView: boolean;
}

function AccordionTitle({ isLocked, title, selected, simplifiedView }: AccordionTitleProps) {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        flexGrow: 1,
        pl: simplifiedView ? 0 : 3,
        ...(selected &&
          !isLocked && {
            color: "primary.main",
          }),
      }}
    >
      {title}
    </Typography>
  );
}

function AccordionExpandCollapseIcon({ expanded }: { expanded: boolean }) {
  return (
    <Iconify
      icon={expanded ? "carbon:chevron-down" : "carbon:chevron-right"}
      sx={{
        color: "text.secondary",
      }}
    />
  );
}
