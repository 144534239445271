import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { errorToast } from "src/components_with_stories/toast";
import { AccessContext } from "src/context/AccessContext";
import { AppContext } from "src/context/AppContext";
import { FAQContext } from "src/context/FAQContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import DesignatedSupervisorProgrammeModel from "src/models/DesignatedSupervisorProgrammeModel";
import LiveStudySessionModel from "src/models/LiveStudySessionModel";
import ResourceModelType from "src/models/ResourceModelType";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import MiniFAQsSPC from "src/newComponents/MiniFAQsSPC";
import MoreInformationModal from "src/newComponents/MoreInformationModal";
import ResourceCost from "src/newComponents/ResourceCost";
import DesignatedSupervisorProgrammeRepository from "src/repository/designatedSupervisorProgrammeRepository";
import LiveStudySessionRepository from "src/repository/liveStudySessionRepository";
import { resourceCanBePurchased } from "src/repository/resourceRepository";

import Course from "../course/components/Course";
import { FancyPageSubtitle, FancyPageTitle } from "../landing/components/QuestionBankTopSPRC";
import LiveSessionsSchedule from "../live_online_study_sessions/components/LiveSessionsSchedule";
import PageTemplate from "../PageTemplate";
import DSPHowItWorks from "./components/DSPHowItWorks";
import DSPLiveSessionsTopSection from "./components/DSPLiveSessionsTopSection";
import DSPSupportGroup from "./components/DSPSupportGroup";
import DSPWhatDoesItInclude from "./components/DSPWhatDoesItInclude";

const DesignatedSupervisorProgramme = () => {
  const { getContentForPage } = useContext(FAQContext);
  const miniFaqContent = getContentForPage("Designated Supervisor Programme");

  const { configurations, isAuthenticated } = useContext(AppContext);

  const { getResourceModelByResourceType } = useContext(AccessContext);
  const resourceModel = getResourceModelByResourceType(ResourceModelType.designatedSupervisorProgramme);
  const userHasAccess = resourceModel?.access?.userHasValidAccess ?? false;

  const [designatedSupervisorProgramme, setDesignatedSupervisorProgramme] =
    useState<DesignatedSupervisorProgrammeModel>();
  const [liveStudySessions, setLiveStudySessions] = useState<LiveStudySessionModel[]>();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isLightMode = useIsLightMode();

  useEffect(() => {
    const dspRepo = new DesignatedSupervisorProgrammeRepository();
    dspRepo
      .getDesignatedSupervisorProgramme()
      .then((dsp: DesignatedSupervisorProgrammeModel) => setDesignatedSupervisorProgramme(dsp))
      .catch(() => errorToast("Failed to fetch designated supervisor programme.", isLightMode))
      .finally(() => setIsLoading(false));

    const liveSessionRepo = new LiveStudySessionRepository();
    liveSessionRepo
      .getDSPLiveStudySessions()
      .then((sessions: LiveStudySessionModel[]) => setLiveStudySessions(sessions))
      .catch(() => errorToast("Failed to fetch live study sessions.", isLightMode))
      .finally(() => setIsLoading2(false));
  }, [isAuthenticated]);

  const showLoadingSpinner = isLoading || isLoading2 || !configurations;

  return (
    <>
      {showLoadingSpinner && <LoadingScreen />}
      <Helmet link={[{ rel: "canonical", href: "/designated-supervisor-programme/" }]}>
        <title>Designated Supervisor Programme | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Develop your skills and guide your Trainee Pharmacist with our interactive support programme."
        />
        <meta
          name="keywords"
          content="Designated Supervisor Programme, Designated Supervisor Support, Pre-Reg Tutor, Pre Registration Support, Foundation Training Programme, GPhC exam revision, E-portfolio pharmacy, Foundation Pharmacist Training Support, GPhC learning outcomes, Structured training plan GPhC, GPhC training year key dates"
        />
      </Helmet>
      <PageTemplate>
        <Stack sx={{ alignItems: "center", mt: { md: 4, lg: 2 } }}>
          <FancyPageTitle
            titles={[`Develop your`, ` Supervision Skills `, `with our `, ` Designated Supervisor Programme`]}
          />
          <FancyPageSubtitle
            subtitle={
              <>
                An interactive programme and support network for Designated Supervisors.
                <br />
                <br />
                The GPhC requirements for designated supervisors stipulates that you must have relevant training for
                your role. This Programme is designed to guide supervisors during the foundation training year.
              </>
            }
          />

          {resourceModel && resourceCanBePurchased(resourceModel) && (
            <Stack direction="row" spacing={2} alignItems="center">
              <ResourceCost cost={resourceModel.cost} originalCost={resourceModel.originalCost} paddingTop={false} />
              <AddResourceToBasket resourceModel={resourceModel} />

              <Button variant="contained" color="inherit" onClick={() => setIsModalVisible(true)}>
                Access FREE
              </Button>
              {isModalVisible && (
                <MoreInformationModal
                  title="Access FREE"
                  content="If you enrol your Trainee on our Foundation Pharmacist Training Programme, we will include our Designated Supervisor Programme for free."
                  handleClose={() => setIsModalVisible(false)}
                />
              )}
            </Stack>
          )}
        </Stack>

        <Divider sx={{ mt: { md: 4 } }} style={{ borderColor: "transparent" }} />

        <Grid container spacing={{ xs: 0, md: 10 }}>
          <DSPHowItWorks />
          <DSPWhatDoesItInclude />
        </Grid>

        <Divider sx={{ mt: { md: 4 } }} style={{ borderColor: "transparent" }} />

        <h2 style={{ marginBottom: "2.5rem" }}>Designated Supervisor Programme Modules</h2>

        {designatedSupervisorProgramme && <Course course={designatedSupervisorProgramme} simplifiedView={true} />}
        <Divider sx={{ mt: { md: 4 } }} style={{ borderColor: "transparent" }} />

        <h2>Live Drop In Sessions</h2>
        {configurations && <DSPLiveSessionsTopSection userHasAccess={userHasAccess} configurations={configurations} />}
        {liveStudySessions && <LiveSessionsSchedule liveStudySessions={liveStudySessions} />}
        <Divider sx={{ mt: { md: 4 } }} style={{ borderColor: "transparent" }} />

        <h2>Support Group (Telegram)</h2>
        {configurations && <DSPSupportGroup configurations={configurations} userHasAccess={userHasAccess} />}
        <Divider sx={{ mt: 4 }} style={{ borderColor: "transparent" }} />

        <MiniFAQsSPC topics={miniFaqContent} />
      </PageTemplate>
    </>
  );
};

export default DesignatedSupervisorProgramme;
