import { GridColDef } from "@mui/x-data-grid";
import React, { Suspense } from "react";

import { QBQuizAttemptDto } from "src/pages/QuizPage";
import DateService, { DateType } from "src/utils/dateService";
import { getBankName, getWeightingName } from "src/utils/format_bank";

import { paleGreen } from "./qb_question/QBQuestion";
import { getAttemptTime } from "./utils/formatAttemptTime";

const QuizAttemptsDataGrid = React.lazy(() => import("./QuizAttemptsDataGrid"));

interface Setup {
  numQuestions: number;
  weighting: 1 | 2 | 3 | null;
  duration: number;
  bank: "calculations" | "clinicalMixed" | "clinicalOtc" | "clinicalMep" | "clinicalTherapeutics";
}

interface Result {
  numQuestions: number;
  numQuestionsCorrect: number;
  timeTaken: number;
  score: number;
}

export interface QuizAttemptRow {
  id: number;
  when: DateType;
  setup: Setup;
  result: Result;
}

const QuizAttempts = ({ attempts }: { attempts: QBQuizAttemptDto[] }) => {
  if (attempts.length === 0) return null;

  const formattedAttempts: QuizAttemptRow[] = attempts.map((attempt) => {
    const when = DateService.parseServerDate(attempt.started_quiz);
    const setup: Setup = {
      numQuestions: attempt.num_questions,
      weighting: attempt.weighting,
      duration: attempt.duration_in_minutes,
      bank: attempt.bank,
    };
    const result: Result = {
      numQuestions: attempt.num_questions,
      numQuestionsCorrect: attempt.num_questions_correct,
      timeTaken: attempt.time_taken_in_seconds,
      score: attempt.percentage,
    };

    return {
      id: attempt.id,
      when: when,
      setup: setup,
      result: result,
    };
  });

  const columns: GridColDef<QuizAttemptRow>[] = [
    {
      field: "when",
      headerName: "Date",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 75,
      flex: 1,
      renderCell: (params) => (
        <div>
          <span>{DateService.formatFour(params.value)}</span>
          <br />
          <span>{DateService.formatThree(params.value)}</span>
        </div>
      ),
    },
    {
      field: "setup",
      headerName: "Setup",
      minWidth: 150,
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const { numQuestions, weighting, duration, bank } = params.value;

        return (
          <div>
            <span>{getBankName(bank)}</span>
            {weighting && (
              <>
                <br />
                <span>{getWeightingName(weighting)} weighted</span>
              </>
            )}
            <br />
            <span>{numQuestions} questions</span>
            <br />
            <span>{duration} minutes</span>
          </div>
        );
      },
    },
    {
      field: "result",
      headerName: "Result",
      minWidth: 110,
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const { numQuestions, numQuestionsCorrect, timeTaken, score } = params.value;

        const { hours, minutes, seconds } = getAttemptTime(timeTaken);
        const times = [hours, minutes, seconds];

        const scoreColor = score >= 70 ? paleGreen : "transparent";

        return (
          <div>
            <span style={{ backgroundColor: scoreColor }}>Scored {score}%</span>
            <br />
            <span>
              {numQuestionsCorrect}/{numQuestions} correct
            </span>
            <br />
            {times.map((time, idx) => {
              if (time?.length === 0) return null;
              return (
                <span key={idx}>
                  {time}
                  {idx !== times.length - 1 && <br />}
                </span>
              );
            })}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Suspense fallback={<div>Loading quiz attempts...</div>}>
        <QuizAttemptsDataGrid rows={formattedAttempts} columns={columns} />
      </Suspense>
    </div>
  );
};

export default QuizAttempts;
