import Box from "@mui/material/Box";
import { styled, SxProps, Theme } from "@mui/material/styles";

import { bgBlur } from "src/utils/cssStyles";

import Logo from "../logo/Logo";

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgBlur({
    blur: 2,
    opacity: 0.24,
    color: theme.palette.background.default,
  }),
  top: 0,
  zIndex: 9999,
  position: "fixed",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledLogo = styled("div")(() => ({
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "25%": {
      transform: "scale(0.96)",
      opacity: 0.48,
    },
    "50%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "75%": {
      transform: "scale(0.96)",
      opacity: 0.48,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
  animation: "pulse 3s ease-in-out infinite",
}));

export default function LoadingScreen({ sx }: { sx?: SxProps<Theme> }) {
  return (
    <>
      <StyledRoot sx={sx}>
        <StyledLogo>
          <Logo single sx={{ width: "150px" }} />
        </StyledLogo>
      </StyledRoot>

      <Box sx={{ width: 1, height: "100vh" }} />
    </>
  );
}
