import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import { Iconify } from "src/components/Iconify";
import { Image } from "src/components/Image";
import { RenderMarkdown } from "src/components_with_stories/qb_question/RenderMarkdown";
import BasketItemModel from "src/models/BasketItemModel";
import ResourceCost from "src/newComponents/ResourceCost";

export default function CartItemsDesktop({
  items,
  removeItemFromBasketOnConfirm,
}: {
  items: BasketItemModel[];
  removeItemFromBasketOnConfirm: (stripePriceId: string, resourceId: number, onConfirmAndCancel: () => void) => void;
}) {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          py: 2,
          minWidth: 520,
          typography: "subtitle2",
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Stack flexGrow={1}>Item</Stack>
        <Stack
          sx={{
            width: 114,
          }}
        >
          Price
        </Stack>
      </Stack>

      {items.map((item) => (
        <CartItem key={item.resourceId} item={item} removeItemFromBasketOnConfirm={removeItemFromBasketOnConfirm} />
      ))}
    </>
  );
}

function CartItem({
  item,
  removeItemFromBasketOnConfirm,
}: {
  item: BasketItemModel;
  removeItemFromBasketOnConfirm: (stripePriceId: string, resourceId: number, onConfirmAndCancel: () => void) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const removeFromBasket = (item: BasketItemModel) => {
    setIsLoading(true);
    const onConfirmAndCancel = () => setIsLoading(false);
    removeItemFromBasketOnConfirm(item.stripePriceId, item.resourceId, onConfirmAndCancel);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        py: 3,
        minWidth: 500,
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
    >
      <Stack direction="row" alignItems="center" flexGrow={1}>
        <Image
          src={item.image}
          sx={{
            width: 80,
            height: 80,
            flexShrink: 0,
            borderRadius: 1.5,
            bgcolor: "background.neutral",
          }}
        />

        <Stack spacing={0.5} sx={{ p: 2 }}>
          <Typography variant="subtitle2">{item.name}</Typography>

          <RenderMarkdown text={item.description} asSecondaryBody2={true} compact={true} />
        </Stack>
      </Stack>

      <Stack direction="row" spacing={1.5} justifyContent="end" style={{ marginBottom: "1rem" }}>
        <ResourceCost cost={item.cost} originalCost={item.originalCost} paddingTop={true} />

        <IconButton disabled={isLoading} onClick={() => removeFromBasket(item)}>
          <Iconify icon="carbon:trash-can" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
