import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { Iconify } from "src/components/Iconify";

const QBQuestionFlag = ({ isFlagged, toggleFlag }: { isFlagged: boolean; toggleFlag: () => void }) => {
  return (
    <Tooltip title={isFlagged ? "Unflag question" : "Flag question"}>
      <IconButton
        sx={{
          color: isFlagged ? "rgba(250, 0, 0, 0.9)" : "grey",
        }}
        onClick={toggleFlag}
      >
        <Iconify icon={"majesticons:flag"} style={{ width: "1.75rem", height: "auto" }} />
      </IconButton>
    </Tooltip>
  );
};

export default QBQuestionFlag;
