import Button from "@mui/material/Button";
import { useContext } from "react";

import { infoToast } from "src/components_with_stories/toast/toast";
import { AppContext } from "src/context/AppContext";
import { BasketContext } from "src/context/BasketContext";
import useIsLightMode from "src/hooks/useIsLightMode";
import BasketItemModel from "src/models/BasketItemModel";

import { getConflictingItems } from "../utils/getConflictingItems";
import { AuthenticatedProceedToPayment } from "./proceed_to_payment/AuthenticatedProceedToPayment";
import { UnauthenticatedProceedToPayment } from "./proceed_to_payment/UnauthenticatedProceedToPayment";

export default function ProceedToPayment({ items, isMobile }: { items: BasketItemModel[]; isMobile: boolean }) {
  const { isAuthenticated, emailAddress, name, phone } = useContext(AppContext);
  const { basketId, forceRemoveItemsFromBasket } = useContext(BasketContext);
  const isLightMode = useIsLightMode();
  const resourceIds = items.map((item) => item.resourceId);
  const resourcesStr = resourceIds.join(",");

  const basketContainsConflictingItems = getConflictingItems(items).length > 0;

  if (basketContainsConflictingItems) {
    return (
      <Button
        sx={{
          mt: 1.5,
        }}
        size="large"
        variant="contained"
        color="inherit"
        type="submit"
        onClick={() => {
          forceRemoveItemsFromBasket(getConflictingItems(items));
          infoToast(
            "Your basket has been updated to remove items that are included in the Foundation Training Programme. Please proceed to payment.",
            isLightMode
          );
        }}
        style={{
          backgroundColor: "#009d47",
        }}
      >
        Proceed to Payment
      </Button>
    );
  }

  if (isAuthenticated && basketId) {
    return (
      <AuthenticatedProceedToPayment
        isMobile={isMobile}
        emailAddress={emailAddress}
        name={name}
        phone={phone}
        resourcesStr={resourcesStr}
        basketId={basketId}
      />
    );
  }

  if (basketId) {
    return <UnauthenticatedProceedToPayment isMobile={isMobile} resourcesStr={resourcesStr} basketId={basketId} />;
  }

  return null;
}
